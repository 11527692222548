import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CalendarRepository } from '../repositories/calendar.repository';
import { useCalendarStore } from '../store/calendar.store';

export const useCalendarEvents = (customStartDate?: Date, customEndDate?: Date, customUserId?: string) => {
  const { currentWeek } = useCalendarStore();
  const { auth } = useFirebase();
  const { user } = useUser(auth?.currentUser?.uid);
  const { firestore } = useFirebase();
  const calendarRepository = useMemo(() => new CalendarRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { events, setEvents, addEvent, updateEvent, removeEvent, setIsLoading } = useCalendarStore();

  // Calculate start and end dates based on currentWeek if not provided
  const startDate = customStartDate || currentWeek.startOf('week').toDate();
  const endDate = customEndDate || currentWeek.endOf('week').toDate();
  const userId = customUserId || user?.uid || user?.id;

  const { data, isLoading, error } = useQuery({
    queryKey: ['calendarEvents', { startDate, endDate, userId }],
    queryFn: () => calendarRepository.getFirestoreEvents(startDate, endDate, userId as string),
    enabled: !!userId,
    gcTime: 30 * 60 * 1000, // 30 minutes
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setEvents(data);
    }
  }, [data, setEvents]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const createEventMutation = useMutation({
    mutationFn: (newEvent: Omit<CalendarEvent, 'id'>) => calendarRepository.upsertEvent('', newEvent),
    onSuccess: (event) => {
      addEvent(event);
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    },
  });

  const updateEventMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<CalendarEvent> }) =>
      calendarRepository.upsertEvent(id, data),
    onSuccess: (event, variables) => {
      updateEvent(variables.id, event);
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    },
  });

  const deleteEventMutation = useMutation({
    mutationFn: (id: string) => calendarRepository.delete(id),
    onSuccess: (_, id) => {
      removeEvent(id);
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    },
  });

  return {
    events,
    isLoading,
    error,
    createEvent: createEventMutation.mutate,
    updateEvent: updateEventMutation.mutate,
    deleteEvent: deleteEventMutation.mutate,
  };
};
