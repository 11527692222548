import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useUser } from '@bluebird-monorepo/users';
import { useCallback } from 'react';
import { IntroductionRepository } from '../repositories/introduction.repository';

export const useIntroduction = (candidateId: string, jobId: string) => {
  const { firestore } = useFirebase();
  const { user } = useUser();

  const createIntroduction = useCallback(
    async (content: string) => {
      if (!user || !content.trim() || !firestore) return;

      const introductionRepo = new IntroductionRepository(firestore);

      // Create the introduction
      return introductionRepo.createIntroduction({
        content,
        candidateId,
        jobId,
        author: {
          id: user.uid,
          name: user.displayName || user.email || 'Unknown User',
          avatar: user.photoURL || undefined,
        },
      });
    },
    [firestore, user, candidateId, jobId],
  );

  return {
    createIntroduction,
  };
};
