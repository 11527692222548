import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Job } from '@bluebird-monorepo/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { JobsRepository } from '../store/job.repository';

export const useJobSearch = (
  searchTerm: string,
  sortField?: string,
  sortDirection: 'asc' | 'desc' = 'asc',
  prefixSearchOnly = false,
) => {
  const { firestore } = useFirebase();
  if (!firestore) throw new Error('Firestore not initialized');

  const jobsRepository = new JobsRepository(firestore);

  const { data: jobs, isLoading } = useQuery({
    queryKey: ['jobs', 'search', searchTerm, sortField, sortDirection],
    queryFn: async () => {
      if (!searchTerm) return [];
      return await jobsRepository.getAll();
    },
    enabled: prefixSearchOnly ? !!searchTerm : true,
  });

  const loadedJobs = jobs || [];

  const results = useMemo(() => {
    if (!searchTerm) return loadedJobs;

    const searchTermLower = searchTerm.toLowerCase();
    const results = new Map<string, { job: Job; matchType: number }>();

    const getMatchType = (job: Job): number => {
      const jobTitleLower = (job.jobTitle || '').toLowerCase();
      const companyNameLower = (job.companyName || '').toLowerCase();

      // Exact match in title
      if (jobTitleLower === searchTermLower) return 0;

      // Starts with search term in title
      if (jobTitleLower.startsWith(searchTermLower)) return 1;

      // Contains search term as a whole word in title
      const titleRegex = new RegExp(`\\b${searchTermLower}\\b`, 'i');
      if (titleRegex.test(jobTitleLower)) return 2;

      // Contains search term anywhere in title
      if (jobTitleLower.includes(searchTermLower)) return 3;

      // Match in company name
      if (companyNameLower.includes(searchTermLower)) return 4;

      return 5;
    };

    loadedJobs.forEach((job) => {
      const matchType = getMatchType(job);
      if (matchType < 5) {
        results.set(job.id, { job, matchType });
      }
    });

    return Array.from(results.values())
      .sort((a, b) => {
        if (a.matchType !== b.matchType) {
          return a.matchType - b.matchType;
        }
        return (a.job.jobTitle || '').localeCompare(b.job.jobTitle || '');
      })
      .map((item) => item.job);
  }, [loadedJobs, searchTerm]);

  return {
    results: results.slice(0, 20), // Limit to 20 results
    isLoading,
  };
};
