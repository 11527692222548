import { TaskboardItem } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface TaskStore {
  tasks: TaskboardItem[];
  isLoading: boolean;
  setTasks: (tasks: TaskboardItem[]) => void;
  addTask: (task: TaskboardItem) => void;
  updateTask: (id: string, task: Partial<TaskboardItem>) => void;
  removeTask: (id: string) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const useTaskStore = create<TaskStore>((set) => ({
  tasks: [],
  isLoading: false,
  setTasks: (tasks) => set({ tasks }),
  addTask: (task) =>
    set((state) => ({
      tasks: [...state.tasks, task],
    })),
  updateTask: (id, updatedTask) =>
    set((state) => ({
      tasks: state.tasks.map((task) => (task.id === id ? { ...task, ...updatedTask } : task)),
    })),
  removeTask: (id) =>
    set((state) => ({
      tasks: state.tasks.filter((task) => task.id !== id),
    })),
  setIsLoading: (isLoading) => set({ isLoading }),
}));
