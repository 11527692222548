import { toMillis } from '@bluebird-monorepo/calendar';
import { Job } from '@bluebird-monorepo/types';

export type PriorityLevel = 1 | 2 | 3 | 4;

export const PRIORITY_LABELS: Record<PriorityLevel, string> = {
  1: 'Urgent',
  2: 'High',
  3: 'Medium',
  4: 'Low',
};

export const PRIORITY_COLORS: Record<PriorityLevel, string> = {
  1: 'var(--joy-palette-success-500)', // Green
  2: 'var(--joy-palette-primary-500)', // Blue
  3: 'var(--joy-palette-warning-500)', // Orange
  4: 'var(--joy-palette-neutral-500)', // Gray
};

function convertToLevel(score: number): PriorityLevel {
  if (score >= 75) return 1;
  if (score >= 50) return 2;
  if (score >= 25) return 3;
  return 4;
}

export type PriorityScore = {
  total: number; // 0-100
  level: PriorityLevel;
  source: 'auto' | 'manual';
  breakdown: {
    timeOpenScore: number; // 0-40
    pipelineScore: number; // 0-40
    activityScore: number; // 0-20
  };
  dataIntegrity: {
    missingOpenSinceDate: boolean;
    missingCandidatesData: boolean;
    missingEventStats: boolean;
    missingTimeline: boolean;
  };
  flags: {
    noActivity: boolean;
    noCandidates: boolean;
  };
};

export function calculatePriorityScore(job: Job): PriorityScore {
  // Check data integrity
  const dataIntegrity = {
    missingOpenSinceDate: !job.openSinceDate,
    missingCandidatesData: job.candidates === undefined,
    missingEventStats: !job.eventStats,
    missingTimeline: !job.timeline?.entries?.length,
  };

  const timeOpenScore = calculateTimeOpenScore(job);
  const pipelineScore = calculatePipelineScore(job);
  const activityScore = calculateActivityScore(job);

  const total = Math.round(timeOpenScore + pipelineScore + activityScore);

  return {
    total,
    level: job.priorityOverride?.level ?? convertToLevel(total),
    source: job.priorityOverride ? 'manual' : 'auto',
    breakdown: {
      timeOpenScore,
      pipelineScore,
      activityScore,
    },
    dataIntegrity,
    flags: {
      noActivity: getDaysSinceLastActivity(job) > 14,
      noCandidates:
        !job.candidates || (Array.isArray(job.candidates) ? job.candidates.length === 0 : job.candidates === 0),
    },
  };
}

function calculateTimeOpenScore(job: Job): number {
  if (!job.openSinceDate) {
    return 0; // Missing data
  }

  const daysOpen = Math.floor((Date.now() - toMillis(job.openSinceDate)) / (1000 * 60 * 60 * 24));

  // Score increases with days open, max at 40 points
  // 0.8 points per day, maxing out at 50 days (40 points)
  return Math.min(40, daysOpen * 0.8);
}

function calculatePipelineScore(job: Job): number {
  if (job.candidates === undefined && !job.eventStats) {
    return 0; // Missing all pipeline data
  }

  const candidateCount = Array.isArray(job.candidates) ? job.candidates.length : job.candidates || 0;
  const scheduledEvents = job.eventStats?.scheduledEvents ?? 0;

  // More candidates and scheduled events reduce urgency (inverse scoring)
  // Base score of 40, reduce by:
  // - 8 points per candidate (max 5 candidates = 40 points)
  // - 10 points if there are scheduled events
  return Math.max(0, 40 - candidateCount * 8 - (scheduledEvents > 0 ? 10 : 0));
}

function calculateActivityScore(job: Job): number {
  if (!job.timeline?.entries?.length) {
    return 20; // Missing timeline data = high urgency
  }

  const daysSinceActivity = getDaysSinceLastActivity(job);

  // Score increases with inactivity
  // 1 point per day of inactivity, maxing at 20 points after 20 days
  return Math.min(20, daysSinceActivity);
}

function getDaysSinceLastActivity(job: Job): number {
  const lastActivity = job.timeline?.entries[0]?.timestamp;
  if (!lastActivity) return Infinity;

  return Math.floor((Date.now() - toMillis(lastActivity)) / (1000 * 60 * 60 * 24));
}
