import { CSSProperties, FC } from 'react';

interface RawHTMLProps {
  children?: string | string[] | null;
  className?: string;
  style?: CSSProperties;
}

export const RawHTML: FC<RawHTMLProps> = ({ children, className = '', style }) => {
  const content = Array.isArray(children) ? children.join('\n') : children || '';

  return (
    <span className={className} dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }} style={style} />
  );
};
