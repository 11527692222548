import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Job, TimelineEntry } from '@bluebird-monorepo/types';
import { Firestore, QueryConstraint, where } from 'firebase/firestore';

interface ReferenceNumberResponse {
  referenceNumber: string;
}

export class JobsRepository extends BaseRepository<Job> {
  constructor(db: Firestore) {
    if (!db) throw new Error('Firestore instance is required');
    super(db, 'jobs');
  }

  async getJobsByCompany(companyId: string): Promise<Job[]> {
    try {
      const constraints: QueryConstraint[] = [where('companyId', '==', companyId)];
      return this.getAll(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching jobs by company');
    }
  }

  async getActiveJobs(): Promise<Job[]> {
    try {
      const constraints: QueryConstraint[] = [where('status', '==', 'active')];
      return this.getAll(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching active jobs');
    }
  }

  async addTimelineEntry(jobId: string, entry: Omit<TimelineEntry, 'id'>): Promise<void> {
    try {
      const job = await this.getById(jobId);
      if (!job) throw new Error('Job not found');

      const newEntry: TimelineEntry = {
        ...entry,
        id: crypto.randomUUID(),
      } as any;

      console.log('newEntry', newEntry);

      const timeline = job.timeline || { entityId: jobId, entityType: 'job', entries: [] };
      const updatedTimeline = {
        ...timeline,
        entries: [newEntry, ...timeline.entries],
      };
      console.log('updatedTimeline', updatedTimeline);

      await this.update(jobId, { timeline: updatedTimeline });
    } catch (error) {
      console.log(JSON.stringify(error));
      throw this.handleError(error, 'Error adding timeline entry');
    }
  }
}
