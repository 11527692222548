import { FirebaseProvider } from '@bluebird-monorepo/bluebird-firebase';
import { StyledEngineProvider } from '@mui/joy';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/App';
import './index.css';

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    collapseGroups: true,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 30, // 30 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: true,
    },
  },
  queryCache: new QueryCache({
    onSuccess: (data, query) => {
      console.log('🔄 Query Success:', {
        queryKey: query.queryKey,
        timestamp: new Date().toISOString(),
        cacheTime: query.state.dataUpdatedAt,
      });
    },
    onError: (error, query) => {
      console.error('❌ Query Error:', {
        queryKey: query.queryKey,
        query: JSON.stringify(query),
        error: JSON.stringify(error),
        timestamp: new Date().toISOString(),
      });
    },
  }),
});

// Add a global query observer
queryClient.getQueryCache().subscribe((event) => {
  if (event?.type === 'updated') {
    console.group(`🔍 Query Update: ${event.query.queryHash}`);
    console.log('Query Key:', event.query.queryKey);
    console.log('Status:', event.query.state.status);
    console.log('Fetch Count:', event.query.state.dataUpdateCount);
    console.log('Last Updated:', new Date(event.query.state.dataUpdatedAt).toLocaleString());
    console.log('Is Fetching:', event.query.state.fetchStatus === 'fetching');
    console.groupEnd();
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GCLIENT_ID || ''}>
      <StyledEngineProvider injectFirst>
        <FirebaseProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </FirebaseProvider>
      </StyledEngineProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);
