import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Candidate } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CandidatesRepository } from '../repositories/candidate.repository';
import { PotentialRolesRepository } from '../repositories/potential-roles.repository';
import { TechnologiesRepository } from '../repositories/technologies.repository';
import { useCandidateStore } from '../store/candidates.store';

export const useCandidateSearch = (searchTerm: string) => {
  const { firestore } = useFirebase();
  if (!firestore) throw new Error('Firestore not initialized');
  
  const candidatesRepository = new CandidatesRepository(firestore);
  const queryClient = useQueryClient();

  const { data: candidates, isLoading } = useQuery({
    queryKey: ['candidates', 'initial'],
    queryFn: async () => {
      const result = await candidatesRepository.getNextBatch(null);
      return result;
    },
  });

  const loadedCandidates = candidates || [];
  
  const hasMatchingPrefix = useMemo(() => {
    if (!searchTerm) return true;
    const searchTermLower = searchTerm.toLowerCase();
    
    return loadedCandidates.some(candidate => 
      (candidate.firstName || '').toLowerCase().startsWith(searchTermLower) ||
      (candidate.lastName || '').toLowerCase().startsWith(searchTermLower)
    );
  }, [loadedCandidates, searchTerm]);

  const prefixSearchQuery = useQuery({
    queryKey: ['candidates', 'prefix-search', searchTerm],
    queryFn: () => candidatesRepository.getByNamePrefix(searchTerm),
    enabled: !!searchTerm,
  });

  const results = useMemo(() => {
    if (!searchTerm) return loadedCandidates;

    const searchTermLower = searchTerm.toLowerCase();
    const results = new Map<string, { candidate: Candidate; matchType: number }>();
    
    const getMatchType = (candidate: Candidate): number => {
      const firstNameLower = (candidate.firstName || '').toLowerCase();
      const lastNameLower = (candidate.lastName || '').toLowerCase();
      const fullNameLower = `${firstNameLower} ${lastNameLower}`;
      
      // Exact full name match
      if (fullNameLower === searchTermLower) return 0;
      
      // Exact match of first or last name
      if (firstNameLower === searchTermLower || lastNameLower === searchTermLower) return 1;
  
      // Starts with search term in full name
      if (fullNameLower.startsWith(searchTermLower)) return 2;
      
      // Starts with search term in first or last name
      if (firstNameLower.startsWith(searchTermLower) || lastNameLower.startsWith(searchTermLower)) return 3;
      
      // Contains search term as a whole word
      const regex = new RegExp(`\\b${searchTermLower}\\b`, 'i');
      if (regex.test(fullNameLower)) return 4;
      
      // Contains search term anywhere in the name
      if (fullNameLower.includes(searchTermLower)) return 5;
      
      // Contains in other fields
      const currentPositionLower = (candidate.currentPosition || '').toLowerCase();
      const currentOrganizationLower = (candidate.currentOrganization || '').toLowerCase();
      const cityLower = (candidate.city || '').toLowerCase();

      if (currentPositionLower.includes(searchTermLower) || 
          currentOrganizationLower.includes(searchTermLower) || 
          cityLower.includes(searchTermLower)) return 6;
      
      return 7;
    };
    
    loadedCandidates.forEach(candidate => {
      const matchType = getMatchType(candidate);
      if (matchType < 7) {
        results.set(candidate.id, { candidate, matchType });
      }
    });

    if (prefixSearchQuery.data) {
      prefixSearchQuery.data.forEach(candidate => {
        if (!results.has(candidate.id)) {
          const matchType = getMatchType(candidate);
          if (matchType < 7) {
            results.set(candidate.id, { candidate, matchType });
          }
        }
      });
    }

    const sortedResults = Array.from(results.values())
      .sort((a, b) => {
        if (a.matchType !== b.matchType) {
          return a.matchType - b.matchType;
        }
        const nameCompare = (a.candidate.firstName || '').localeCompare(b.candidate.firstName || '');
        if (nameCompare !== 0) return nameCompare;
        return (a.candidate.lastName || '').localeCompare(b.candidate.lastName || '');
      })
      .map(item => item.candidate);

    // Log the sorted results
    console.log('Search term:', searchTerm);
    console.log('Sorted results:', sortedResults.map(candidate => ({
      name: `${candidate.firstName} ${candidate.lastName}`,
      matchType: getMatchType(candidate)
    })));

    return sortedResults;
  }, [loadedCandidates, prefixSearchQuery.data, searchTerm]);

  return {
    results,
    isLoading: isLoading || prefixSearchQuery.isLoading,
    error: prefixSearchQuery.error
  };
};

interface SortConfig {
  field: string;
  direction: 'asc' | 'desc';
}

export const useCandidates = (
  candidateId?: string,
  sortField = 'firstName',
  sortDirection: 'asc' | 'desc' = 'asc'
) => {
  const { firestore } = useFirebase();
  if (!firestore) throw new Error('Firestore not initialized');

  const repositories = useMemo(
    () => ({
      candidatesRepository: new CandidatesRepository(firestore),
      potentialRolesRepository: new PotentialRolesRepository(firestore),
      technologiesRepository: new TechnologiesRepository(firestore),
    }),
    [firestore],
  );

  const queryClient = useQueryClient();
  const { setCandidates, setCurrentCandidate } = useCandidateStore();

  const getSortedCandidates = async (
    field = 'firstName',
    direction: 'asc' | 'desc' = 'asc',
    lastCandidate: Candidate | null = null
  ) => {
    return repositories.candidatesRepository.getBySorting(field, direction, lastCandidate);
  };

  const { 
    data: candidates,
    isLoading,
    error 
  } = useQuery({
    queryKey: ['candidates', candidateId ? 'single' : 'sorted', candidateId, sortField, sortDirection],
    queryFn: async () => {
      console.log('🔄 useCandidates: Fetching candidates with:', {
        candidateId,
        sortField,
        sortDirection
      });
      
      if (candidateId) {
        const candidate = await repositories.candidatesRepository.get(candidateId);
        return candidate ? [candidate] : [];
      }
      const sortedCandidates = await getSortedCandidates(sortField, sortDirection);
      console.log('📥 useCandidates: Received sorted candidates:', {
        count: sortedCandidates.length,
        sortField,
        sortDirection,
        names: sortedCandidates.map(c => `${c.firstName} ${c.lastName}`).join(', ')
      });
      return sortedCandidates;
    },
  });

  useEffect(() => {
    if (candidates) {
      console.log('🔄 useCandidates effect: Updating store with candidates:', {
        count: candidates.length,
        sortField,
        sortDirection,
        names: candidates.map(c => `${c.firstName} ${c.lastName}`).join(', ')
      });
      
      queueMicrotask(() => {
        if (candidateId) {
          const candidate = candidates.find(c => c.id === candidateId);
          if (candidate) {
            setCurrentCandidate(candidate);
          }
        } else {
          setCandidates(candidates);
        }
      });
    }
  }, [candidates, candidateId, setCurrentCandidate, setCandidates, sortField, sortDirection]);

  const createCandidateMutation = useMutation({
    mutationFn: (newCandidate: Omit<Candidate, 'id'>) => repositories.candidatesRepository.create(newCandidate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['candidates'] });
    },
  });

  const updateCandidateMutation = useMutation({
    mutationFn: ({ candidateId, candidate }: { candidateId: string; candidate: Partial<Candidate> }) =>
      repositories.candidatesRepository.update(candidateId, candidate),
  });

  return {
    candidate: candidateId ? candidates?.find(c => c.id === candidateId) : undefined,
    candidates: !candidateId ? candidates || [] : [],
    isLoading,
    error,
    createCandidate: createCandidateMutation.mutate,
    createCandidateMutation,
    updateCandidateMutation,
  };
};
