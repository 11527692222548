import { HoverCard } from '@bluebird-monorepo/bluebird-ui';
import { TimelineEntry as ITimelineEntry, Timeline } from '@bluebird-monorepo/types';
import { AccessTime, Assignment, Call, Description, Email, Event, Note, Settings } from '@mui/icons-material';
import { Box, Button, Stack, Typography, styled } from '@mui/joy';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const TimelineDot = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.vars.palette.primary[500],
  position: 'absolute',
  left: -4,
  top: 8,
}));

const TimelineLine = styled('div')(({ theme }) => ({
  width: 1,
  backgroundColor: theme.vars.palette.divider,
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
}));

const getIconByType = (type: ITimelineEntry['type']) => {
  switch (type) {
    case 'note':
      return <Note sx={{ fontSize: 14 }} />;
    case 'status_change':
      return <Settings sx={{ fontSize: 14 }} />;
    case 'event':
      return <Event sx={{ fontSize: 14 }} />;
    case 'call':
      return <Call sx={{ fontSize: 14 }} />;
    case 'email':
      return <Email sx={{ fontSize: 14 }} />;
    case 'document':
      return <Description sx={{ fontSize: 14 }} />;
    case 'system_change':
      return <Settings sx={{ fontSize: 14 }} />;
    case 'assignment':
      return <Assignment sx={{ fontSize: 14 }} />;
    default:
      return <AccessTime sx={{ fontSize: 14 }} />;
  }
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};

const getCompactContent = (entry: ITimelineEntry): string => {
  const content = (() => {
    switch (entry.type) {
      case 'note':
        return entry.content;
      case 'status_change':
        return `Changed to ${entry.newStatus}`;
      case 'event':
        return entry.title;
      case 'call':
        return entry.summary || `${entry.callType} call`;
      case 'email':
        return entry.subject;
      case 'document':
        return entry.documentName;
      case 'system_change':
        return `Updated ${entry.changes.length} field${entry.changes.length > 1 ? 's' : ''}`;
      case 'assignment':
        return entry.jobTitle || entry.candidateName || 'Assignment updated';
      default:
        return 'Activity recorded';
    }
  })();

  return truncateText(content, 100);
};

export interface TimelineCompactProps {
  timeline?: Timeline;
  maxItems?: number;
  onViewAll?: () => void;
  entityType: string;
}

export const TimelineCompact: React.FC<TimelineCompactProps> = ({ timeline, maxItems = 3, onViewAll, entityType }) => {
  const [showAll, setShowAll] = useState(false);

  if (!timeline?.entries?.length) {
    return (
      <Typography level="body-sm" sx={{ color: 'neutral.500' }}>
        No recent activity
      </Typography>
    );
  }
  const sortedEntries = [...(timeline?.entries || [])].sort((a, b) => {
    // Convert string timestamps to Date objects before comparing
    const timestampA = a.timestamp?.toDate?.() ? a.timestamp.toDate().getTime() : 0;
    const timestampB = b.timestamp?.toDate?.() ? b.timestamp.toDate().getTime() : 0;
    return timestampB - timestampA;
  });
  const displayedEntries = showAll ? sortedEntries : sortedEntries.slice(0, maxItems || 3);
  const hasMore = sortedEntries.length > (maxItems || 3);

  return (
    <Stack spacing={1}>
      <Box sx={{ position: 'relative' }}>
        <TimelineLine />
        {displayedEntries.map((entry, index) => {
          const timeAgo = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(
            Math.floor((entry.timestamp?.toDate?.()?.getTime() || 0 - new Date().getTime()) / (1000 * 60 * 60 * 24)),
            'day',
          );

          const content = getCompactContent(entry);
          const hasMoreContent = entry.type === 'note' && entry.content.length > 100;

          return (
            <Box
              key={entry.id}
              sx={{
                position: 'relative',
                pl: 3,
                pb: index === displayedEntries.length - 1 && !hasMore ? 0 : 2,
              }}
            >
              <TimelineDot />
              <Stack spacing={0.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box sx={{ color: 'neutral.500', display: 'flex', alignItems: 'center' }}>
                    {getIconByType(entry.type)}
                  </Box>
                  <HoverCard
                    content={
                      <Box sx={{ p: 1, minWidth: 400 }}>
                        <Stack spacing={1}>
                          <Typography>{content}</Typography>
                          {hasMoreContent && (
                            <Button
                              component={RouterLink}
                              to={`/${entityType}/${timeline.entityId}/timeline`}
                              size="sm"
                              variant="plain"
                              sx={{ alignSelf: 'flex-start' }}
                            >
                              Show more
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    }
                    side="right"
                    align="start"
                    sideOffset={16}
                    maxWidth={450}
                  >
                    <Typography
                      level="body-xs"
                      noWrap
                      sx={{
                        flex: 1,
                        color: 'neutral.800',
                      }}
                    >
                      {content.includes('<')
                        ? 'HTML Message'
                        : content.length > 50
                          ? `${content.slice(0, 50)}...`
                          : content}
                    </Typography>
                  </HoverCard>
                </Stack>
                <Typography
                  level="body-xs"
                  sx={{
                    color: 'neutral.500',
                    pl: 3,
                    fontSize: '11px',
                  }}
                >
                  {timeAgo} by {entry.createdBy.name}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Box>

      {hasMore && (
        <Button
          component={RouterLink}
          to={`/${entityType}/${timeline.entityId}/timeline`}
          size="sm"
          variant="plain"
          color="neutral"
          onClick={() => {
            setShowAll(!showAll);
            if (!showAll) {
              onViewAll?.();
            }
          }}
          sx={{ alignSelf: 'center', mt: 1 }}
        >
          View all ({sortedEntries.length})
        </Button>
      )}
    </Stack>
  );
};
