import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore, where } from 'firebase/firestore';

interface Introduction {
  id: string;
  content: string;
  candidateId: string;
  jobId: string;
  createdAt: string;
  author: {
    id: string;
    name: string;
    avatar?: string;
  };
}

export class IntroductionRepository extends BaseRepository<Introduction> {
  constructor(db: Firestore) {
    if (!db) throw new Error('Firestore instance is required');
    super(db, 'introductions');
  }

  async createIntroduction(params: Omit<Introduction, 'id' | 'createdAt'>) {
    return this.create({
      ...params,
      createdAt: new Date().toISOString(),
    });
  }

  async getIntroductionsByCandidateId(candidateId: string): Promise<Introduction[]> {
    return this.getAll([where('candidateId', '==', candidateId)]);
  }

  async getIntroductionsByJobId(jobId: string): Promise<Introduction[]> {
    return this.getAll([where('jobId', '==', jobId)]);
  }
}
