import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { CollectionSizesRepository } from '../repositories/collection-sizes/collection-sizes.repository';
import { useFirebase } from '../utils/use-firebase';

export const useCollectionSizes = () => {
  const { firestore } = useFirebase();
  const repository = useMemo(() => new CollectionSizesRepository(firestore), [firestore]);

  const query = useQuery({
    queryKey: ['collectionSizes'],
    queryFn: () => repository.getAll(),
    // Refresh every 100 minutes to keep sizes up to date
    refetchInterval: 60 * 1000 * 100,
  });

  return {
    ...query,
    collectionSizes: query.data || [],
  };
};
