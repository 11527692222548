import { Stage } from '@bluebird-monorepo/kanban';
import { CandidateJobAssignmentInPipeline } from '@bluebird-monorepo/types';

const DEFAULT_COLORS = {
  hard: '#fffA30',
  soft: '#FF5630',
} as const;

export function mapToPipeline(data: any[]): CandidateJobAssignmentInPipeline[] {
  // Pre-allocate array for better performance
  const result = new Array(data.length);

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const stageId = item.status_id;
    const stageName = item.status || 'Unknown Stage';

    result[i] = {
      avatar: item.avatar || '',
      candidateFirstName: item.candidateFirstName || '',
      candidateId: item.candidateId,
      candidateLastName: item.candidateLastName || '',
      companyName: item.companyName || '',
      content: `Candidate ${item.candidateFirstName || 'Unknown'} is currently in the stage: ${stageName}`,
      currentOrganization: item.currentOrganization || '',
      currentPosition: item.currentPosition || '',
      id: item.id || item.candidateId || 'Unknown',
      jobId: item.jobId,
      jobTitle: item.jobTitle || '',
      link: item.link || 'https://example.com',
      logoUrl: item.logoUrl || '',
      owner: item.owner,
      stage: {
        colors: DEFAULT_COLORS,
        id: stageId,
        name: stageName,
        color: DEFAULT_COLORS.hard,
        order: item.order || 0,
      },
    };
  }

  return result;
}

export const createStagePipelineMap = (
  stages: Stage[],
  assignments: CandidateJobAssignmentInPipeline[],
): Record<string, CandidateJobAssignmentInPipeline[]> => {
  // Pre-create the map with empty arrays
  const result: Record<string, CandidateJobAssignmentInPipeline[]> = {};

  // Sort stages once
  stages.sort((a, b) => (a.order || 0) - (b.order || 0));

  // Initialize arrays for all stages
  for (const stage of stages) {
    result[stage.id] = [];
  }

  // Single pass through assignments
  for (const assignment of assignments) {
    const stageId = assignment.stage.id;
    if (result[stageId]) {
      result[stageId].push(assignment);
    }
  }

  return result;
};
