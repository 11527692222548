import { useCollectionSizes } from '@bluebird-monorepo/bluebird-firebase';
import { CreateJobWizard, JobsTable } from '@bluebird-monorepo/jobs';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { memo, useCallback, useState } from 'react';
import { Layout } from '../../../layout/Layout';

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

// Memoize the CreateJobWizard to prevent re-renders
const MemoizedCreateJobWizard = memo(CreateJobWizard);

const JobsPage: React.FC = () => {
  const [isCreatingJob, setIsCreatingJob] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0);
  const { collectionSizes } = useCollectionSizes();

  // Find the jobs collection size
  const jobsSize = collectionSizes.find((size) => size.id === 'jobs')?.size || 0;

  // Memoize handlers to prevent unnecessary re-renders
  const handlers = {
    handleStartCreateJob: useCallback(() => {
      setIsCreatingJob(true);
    }, []),

    handleCompleteCreateJob: useCallback(() => {
      setIsCreatingJob(false);
    }, []),

    handleCancelCreateJob: useCallback(() => {
      setIsCreatingJob(false);
    }, []),

    handleFilteredCountChange: useCallback((count: number) => {
      setFilteredCount(count);
    }, []),
  };

  // Memoize action button configuration
  const actionButton = React.useMemo(
    () =>
      !isCreatingJob
        ? {
            label: 'Create new job',
            onClick: handlers.handleStartCreateJob,
            variant: 'create' as const,
          }
        : undefined,
    [isCreatingJob, handlers.handleStartCreateJob],
  );

  return (
    <Layout title="Jobs" subtitle="Manage your jobs" actionButton={actionButton} count={filteredCount}>
      {isCreatingJob ? (
        <MemoizedCreateJobWizard
          onComplete={handlers.handleCompleteCreateJob}
          onCancel={handlers.handleCancelCreateJob}
        />
      ) : (
        <JobsTable onFilteredCountChange={handlers.handleFilteredCountChange} />
      )}
    </Layout>
  );
};

// Enable why-did-you-render for this component
JobsPage.whyDidYouRender = true;

export default memo(JobsPage);
