import { useJobSearch } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  FormLabel,
  ListItemContent,
  Typography,
  autocompleteClasses,
} from '@mui/joy';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';

interface JobSearchAutocompleteProps {
  label?: string;
  initialJob?: Job | null;
  onChange: (job: Job | null) => void;
  disabled?: boolean;
}

export function JobSearchAutocomplete({
  label = 'Search for a job',
  initialJob = null,
  onChange,
  disabled = false,
}: JobSearchAutocompleteProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { results: jobs, isLoading } = useJobSearch(searchTerm, undefined, 'asc', true);

  // Limit results to 20 jobs
  const limitedResults = useMemo(() => jobs.slice(0, 20), [jobs]);

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    [],
  );

  const handleInputChange: AutocompleteProps<Job, false, false, false>['onInputChange'] = (event, value) => {
    if (event) {
      debouncedSearch(value);
    }
  };

  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        defaultValue={initialJob}
        loading={isLoading && !!searchTerm}
        options={searchTerm ? limitedResults : initialJob ? [initialJob] : []}
        disabled={disabled}
        onChange={(_, value) => onChange(value)}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => `${option.jobTitle} at ${option.companyName}`}
        placeholder="Start typing to search..."
        noOptionsText={searchTerm ? 'No jobs found' : 'Start typing to search...'}
        renderOption={(props, option) => (
          <li {...props}>
            <ListItemContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography level="title-sm">{option.jobTitle}</Typography>
              <Typography level="body-xs" sx={{ color: 'text.tertiary' }}>
                {option.companyName}
              </Typography>
            </ListItemContent>
          </li>
        )}
        slotProps={{
          listbox: {
            sx: {
              [`& .${autocompleteClasses.option}`]: {
                padding: '8px 12px',
              },
            },
          },
          input: {
            sx: {
              padding: '8px 12px',
            },
          },
        }}
        sx={{
          minWidth: 240,
          [`& .${autocompleteClasses.listbox}`]: {
            '--ListItem-minHeight': '48px',
            '--ListItem-padding': '8px 12px',
          },
        }}
      />
    </Box>
  );
}
