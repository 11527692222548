import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Team } from '@bluebird-monorepo/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Step, StepIndicator, Stepper, Typography } from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useTeams } from '../../hooks/useTeams';
import { AvatarStep } from './steps/AvatarStep';
import { ReviewStep } from './steps/ReviewStep';
import { TeamDetailsStep } from './steps/TeamDetailsStep';
import { TeamLeaderStep } from './steps/TeamLeaderStep';

const teamSchema = z.object({
  name: z.string().min(1, 'Team name is required'),
  description: z.string().optional(),
  leaderId: z.string().optional(),
  avatar: z.string().optional(),
  verticals: z.array(z.enum(['Engineering', 'Product', 'Marketing', 'Sales', 'Customer Success'])).optional(),
});

type TeamFormData = z.infer<typeof teamSchema>;

const steps = [
  {
    label: 'Details',
    description: 'Basic team information',
    component: TeamDetailsStep,
  },
  {
    label: 'Avatar',
    description: 'Team avatar image',
    component: AvatarStep,
  },
  {
    label: 'Leader',
    description: 'Assign team leader',
    component: TeamLeaderStep,
  },
  {
    label: 'Review',
    description: 'Review and create team',
    component: ReviewStep,
  },
];

const stepValidationFields = {
  0: ['name'],
  1: [], // Avatar is optional
  2: [], // Leader is optional
  3: [], // Review step doesn't have its own fields
} as const;

interface CreateTeamWizardProps {
  onComplete: () => void;
  onCancel: () => void;
  mode?: 'create' | 'edit';
  initialData?: Team;
}

export function CreateTeamWizard({ onComplete, onCancel, mode = 'create', initialData }: CreateTeamWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { firestore } = useFirebase();
  const queryClient = useQueryClient();
  const { createTeam, updateTeam } = useTeams();

  const methods = useForm<TeamFormData>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      name: initialData?.name || '',
      description: initialData?.description || '',
      leaderId: initialData?.leaderId || '',
      avatar: initialData?.avatar || '',
      verticals: initialData?.verticals || [],
    },
  });

  const handleNext = async () => {
    const fieldsToValidate = stepValidationFields[activeStep as keyof typeof stepValidationFields];
    const isValid = await methods.trigger(fieldsToValidate);

    if (!isValid) {
      console.log('Form validation failed:', methods.formState.errors);
      return;
    }

    if (activeStep === steps.length - 1) {
      // Final step - submit the form
      const formData = methods.getValues();
      try {
        if (mode === 'edit' && initialData?.id) {
          await updateTeam({
            id: initialData.id,
            data: {
              ...formData,
              updatedAt: new Date().toISOString(),
            },
          });
        } else {
          await createTeam({
            ...formData,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            members: formData.leaderId ? [formData.leaderId] : [],
          });
        }
        onComplete();
      } catch (error) {
        console.error('Failed to save team:', error);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const CurrentStepComponent = steps[activeStep].component;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: 4,
          p: 3,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary',
            },
          }}
        >
          <Close />
        </IconButton>

        {/* Left side - Stepper */}
        <Box
          sx={{
            width: '280px',
            borderRight: '1px solid',
            borderColor: 'divider',
            pr: 3,
          }}
        >
          <Stepper
            component="nav"
            orientation="vertical"
            sx={{
              '--Stepper-spacing': '1rem',
              '--StepIndicator-size': '2rem',
              '--Step-gap': '1rem',
              '--Step-connectorThickness': '2px',
              '--Step-connectorRadius': '1px',
              minHeight: 400,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={index < activeStep}
                indicator={
                  <StepIndicator
                    variant={activeStep === index ? 'solid' : 'soft'}
                    color={activeStep === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
              >
                <Box>
                  <Typography level="title-sm">{step.label}</Typography>
                  <Typography level="body-xs">{step.description}</Typography>
                </Box>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* Right side - Content */}
        <Box sx={{ flex: 1, maxWidth: '800px' }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <CurrentStepComponent />

            {/* Navigation buttons */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 4,
                pt: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Button variant="outlined" color="neutral" onClick={onCancel} sx={{ minWidth: 100 }}>
                Cancel
              </Button>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 100 }}>
                  Back
                </Button>
              )}
              <Button onClick={handleNext} sx={{ minWidth: 100 }}>
                {activeStep === steps.length - 1 ? (mode === 'edit' ? 'Save Changes' : 'Create Team') : 'Next'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
}
