import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { User } from '@bluebird-monorepo/types';
import { Firestore } from 'firebase/firestore';

export class UserRepository extends BaseRepository<User> {
  constructor(db: Firestore) {
    super(db, 'users');
  }

  async getUserById(uid: string): Promise<User | null> {
    try {
      return this.getById(uid);
    } catch (error) {
      throw this.handleError(error, 'Error fetching user');
    }
  }

  async fetchAll(): Promise<User[]> {
    try {
      console.log('Fetching all users from collection:', this.collectionName);
      const users = await this.getAll();
      console.log('Fetched users:', users);
      return users;
    } catch (error) {
      console.error('Error in fetchAll:', error);
      throw this.handleError(error, 'Error fetching users');
    }
  }

  override async create(data: Omit<User, 'id'>): Promise<User> {
    try {
      return super.create(data);
    } catch (error) {
      throw this.handleError(error, 'Error creating user');
    }
  }

  override async update(id: string, data: Partial<User>): Promise<void> {
    try {
      await super.update(id, {
        ...data,
        updatedAt: new Date().toISOString(),
      });
    } catch (error) {
      throw this.handleError(error, 'Error updating user');
    }
  }
}
