import { Timeline } from '@bluebird-monorepo/companies';
import { CreateJobWizard, JobDetails, JobHeader, useJobs } from '@bluebird-monorepo/jobs';
import { TaskBoard, useTasks } from '@bluebird-monorepo/tasks';
import { Job } from '@bluebird-monorepo/types';
import { Box, Tab, TabList, Tabs, Typography } from '@mui/joy';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, Link as RouterLink, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { Layout } from '../../../layout';

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    onlyLogs: true,
    exclude: [/^CandidateAssignmentSection/],
  });
}

type JobContext = {
  job: Job;
};

export const GeneralTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobDetails job={job} />;
};

export const CandidatesTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobDetails job={job} />;
};

export const EventsTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobDetails job={job} />;
};

export const TimelineTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <Timeline timeline={{ entityId: job.id, entityType: 'job', entries: job.timeline?.entries ?? [] }} />;
};

export const TasksTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <TaskBoard entityType="job" entityId={job.id} />;
};

const JobDetailsPage = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { job, isLoading, error, updateJobMutation } = useJobs(jobId);
  const { tasks } = useTasks({ entityType: 'job', entityId: jobId });
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();

  const handleStartEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditComplete = useCallback(
    async (updatedData: Partial<Job>) => {
      if (!job) return;

      await updateJobMutation.mutate({
        id: job.id,
        data: {
          ...job,
          ...updatedData,
          salary: typeof updatedData.salary === 'string' ? parseInt(updatedData.salary, 10) : updatedData.salary,
        },
      });

      setIsEditing(false);
    },
    [job, updateJobMutation],
  );

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const getInitialFormData = useCallback(
    (job: Job) => ({
      id: job.id,
      companyId: job.companyId,
      companyName: job.companyName,
      jobTitle: job.jobTitle,
      location: job.location || '',
      contractType: job.contractType || '',
      roleDescription: job.roleDescription || '',
      productDescription: job.productDescription || '',
      salaryRange: job.salaryRange || { min: 0, max: 0 },
      feePercentage: job.feePercentage || 0,
      preferredLanguages: job.preferredLanguages || [],
      techStack: job.techStack || '',
      mustHaves: job.mustHaves || '',
      niceToHaves: job.niceToHaves || '',
      keyResponsibilities: job.keyResponsibilities || '',
      hiringProcess: job.hiringProcess || '',
      howToIntro: job.howToIntro || '',
      openSinceDate: job.openSinceDate || undefined,
    }),
    [],
  );

  const actionButton = useMemo(
    () =>
      job
        ? {
            label: 'Edit',
            onClick: handleStartEdit,
            variant: 'warning' as const,
          }
        : undefined,
    [job, handleStartEdit],
  );

  const tabs = useMemo(() => {
    if (!jobId) return [];
    return [
      { id: 'general', label: 'General', path: 'general' },
      /* { id: 'candidates', label: `Candidates (${job?.candidates || 0})`, path: 'candidates' },
      { id: 'events', label: 'Events', path: 'events' },
      { id: 'timeline', label: 'Timeline', path: 'timeline' },
      { id: 'tasks', label: `Tasks (${tasks?.length || 0})`, path: 'tasks' }, */
    ];
  }, [jobId, job?.candidates, tasks?.length]);

  const currentPath = location.pathname;
  const activeTab = tabs.find((tab) => currentPath.endsWith(tab.id)) ?? tabs[0];

  const content = useMemo(() => {
    if (isLoading) {
      return <Typography level="h4">Loading...</Typography>;
    }

    if (error) {
      return <Typography level="h4">Error: {error.message}</Typography>;
    }

    if (!job) {
      return <Typography level="h4">Job not found</Typography>;
    }

    if (isEditing) {
      return (
        <CreateJobWizard
          initialData={getInitialFormData(job)}
          mode="edit"
          onComplete={handleEditComplete}
          onCancel={handleEditCancel}
        />
      );
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        {/* Job Header */}
        {job && <JobHeader job={job} />}

        <Tabs value={activeTab.id} sx={{ mb: 2 }}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} component={RouterLink} to={tab.path}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        {/* Use Outlet for nested routes */}
        <Outlet context={{ job }} />
      </Box>
    );
  }, [isLoading, error, job, isEditing, getInitialFormData, handleEditComplete, handleEditCancel, activeTab.id, tabs]);

  return (
    <Layout
      title={job ? job.jobTitle : 'Job Details'}
      subtitle={job?.companyName}
      displayTitle={false}
      actionButton={actionButton}
    >
      {content}
    </Layout>
  );
};

export default JobDetailsPage;
