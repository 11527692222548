import { Job, Nullable } from '@bluebird-monorepo/types';
import { Box, Stack, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabsContentComponent } from './tabs';

type JobDetailsProps = {
  job: Nullable<Job>;
  setSubTitle?: (subtitle: string) => void;
};

const JobDetails = ({ job, setSubTitle }: JobDetailsProps) => {
  const theme = useTheme();

  // Multiple breakpoint checks for granular control
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm')); // 0-600px
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600-900px
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900-1200px

  // Determine layout configuration based on screen size
  const layoutConfig = {
    direction: isXSmall || isSmall ? ('column' as const) : ('row' as const),
    headerAlignment: isXSmall ? 'start' : 'center',
    spacing: {
      xs: 1.5,
      sm: 2,
      md: 2.5,
      lg: 3,
      xl: 4,
    },
    titleSize: {
      xs: 'xl',
      sm: '1.25rem',
      md: '1.25rem',
      lg: '1.5rem',
      xl: '1.5rem',
    },
  };

  const navigate = useNavigate();
  const location = useLocation();

  // Get the active tab from URL or default to 'general'
  const activeTab = location.pathname.split('/').pop() || 'general';

  // Add this useEffect to handle default navigation
  useEffect(() => {
    if (job?.id && location.pathname === `/jobs/${job.id}`) {
      navigate(`/jobs/${job.id}/general`, { replace: true });
    }
  }, [job?.id, location.pathname, navigate]);

  // Map tab names to indices for the MUI Tabs component
  const tabNameToIndex: Record<string, number> = {
    general: 0,
    candidates: 1,
    notes: 2,
    qa: 3,
    events: 4,
    timeline: 5,
  };

  useEffect(() => {
    if (setSubTitle) {
      setSubTitle(getSubtitle());
    }
  }, [activeTab, setSubTitle]);

  // Get the subtitle based on active tab
  const getSubtitle = () => {
    switch (activeTab) {
      case 'events':
        return 'Events';
      case 'general':
        return 'General';
      case 'candidates':
        return 'Candidates';
      case 'notes':
        return 'Notes';
      case 'qa':
        return 'QA';
      case 'timeline':
        return 'Timeline';
      default:
        return '';
    }
  };

  return (
    <Stack
      direction="column"
      spacing={layoutConfig.spacing}
      sx={{
        maxWidth: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          lg: '100%',
          xl: '100%',
        },
      }}
    >
      {/* Tabs Content */}
      <Box
        sx={{
          mt: { xs: 2, sm: 3, md: 4 },
          width: '100%',
          overflow: 'auto',
        }}
      >
        {job && <TabsContentComponent editableJob={job} tabIndex={tabNameToIndex[activeTab]} />}
      </Box>
    </Stack>
  );
};

export default JobDetails;
