import { useVerticals } from '@bluebird-monorepo/shared';
import { FilterList, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  SelectOption,
  Stack,
  Typography,
} from '@mui/joy';
import { FC, memo, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import './columns/ResponsibleColumn.css';
import { PRIORITY_LABELS } from './utils/priorityCalculator';

const responsibleMapping = {
  Dennis: {
    colorClass: 'mint-green',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U05QQC9SJ1W-8fccbf482c28-512',
    name: 'Dennis',
  },
  Jacob: {
    colorClass: 'blush-pink',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U06T7S16K6W-ab5ce9b172f2-192',
    name: 'Jacob',
  },
  Mitchel: {
    colorClass: 'pale-lavender',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U07RZ07JSDP-d3370936361e-192',
    name: 'Mitchel',
  },
  Pontus: {
    colorClass: 'powder-blue',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U075S4N9M6V-dec8ff679f17-192',
    name: 'Pontus',
  },
} as const;

type ResponsibleName = keyof typeof responsibleMapping;

type PriorityFilterValue = '' | '1' | '2' | '3' | '4';

type JobsTableFiltersProps = {
  control: Control<{
    companyName: string;
    feeGt: string;
    feeLt: string;
    filterName: string;
    location: string;
    responsible: string;
    salaryGt: string;
    salaryLt: string;
    statusName: string[];
    workingOn: string[];
    vertical: string;
    priorityFilter: PriorityFilterValue;
  }>;
  onResetPage: () => void;
};

const JobsTableFilters: FC<JobsTableFiltersProps> = memo(({ control, onResetPage }) => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const { verticals, isLoading } = useVerticals();
  const [isResponsibleSelectOpen, setIsResponsibleSelectOpen] = useState(false);

  useEffect(() => {
    console.log('verticals changed:', verticals?.length);
  }, [verticals]);

  const defaultStatuses = [
    'unassigned',
    'needs_work',
    'working_on_it',
    'verbally_accepted',
    'full_pipeline',
    'incoming',
    'intros_welcome',
    'on_hold',
    'postponed',
  ];

  const renderValue = (selected: string[]) => {
    if (!selected || selected.length === 0) return 'Filter by status';
    if (selected.length === defaultStatuses.length + 2) return 'All statuses';
    return `${selected.length} statuses selected`;
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <FormControl size="sm" sx={{ minWidth: 240 }}>
          <Controller
            control={control}
            name="filterName"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Search jobs, companies, or people"
                size="sm"
                startDecorator={<Search />}
                sx={{
                  backgroundColor: 'white',
                  '--Input-focusedThickness': '1px',
                  '&:hover': {
                    borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
                  },
                }}
              />
            )}
          />
        </FormControl>

        <FormControl size="sm" sx={{ minWidth: 200 }}>
          <Controller
            control={control}
            name="responsible"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Filter by responsible"
                size="sm"
                onChange={(e, value) => {
                  field.onChange(value);
                  onResetPage();
                }}
                listboxOpen={isResponsibleSelectOpen}
                onListboxOpenChange={(isOpen) => setIsResponsibleSelectOpen(isOpen)}
                renderValue={(option: SelectOption<string> | null) => {
                  if (!option?.value) return <Box sx={{ pl: '1rem' }}>Filter by responsible</Box>;
                  const person = responsibleMapping[option.value as ResponsibleName];
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, pl: '1rem' }}>
                      <div className="avatar-stack">
                        <img alt={person?.name} src={person?.imgUrl} />
                      </div>
                    </Box>
                  );
                }}
                sx={{
                  backgroundColor: 'white',
                  '--Select-focusedThickness': '1px',
                  '&:hover': {
                    borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
                  },
                }}
              >
                <Option value="">All</Option>
                {Object.entries(responsibleMapping).map(([name, data]) => (
                  <Option
                    key={name}
                    value={name}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <img
                      alt={data.name}
                      src={data.imgUrl}
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        border: '1px solid white',
                      }}
                    />
                    {data.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <FormControl size="sm" sx={{ minWidth: 200 }}>
          <Controller
            control={control}
            name="statusName"
            defaultValue={defaultStatuses}
            render={({ field: { onChange, value } }) => (
              <Select
                multiple
                onChange={(event, value) => {
                  onChange(value);
                  onResetPage();
                }}
                renderValue={(selected) => {
                  if (!selected || selected.length === 0) return 'Filter by status';
                  if (selected.length === defaultStatuses.length + 2) return 'All statuses';
                  return `${selected.length} statuses selected`;
                }}
                placeholder="Filter by status"
                size="sm"
                value={value || defaultStatuses}
                startDecorator={<FilterList />}
                sx={{
                  backgroundColor: 'white',
                  '--Select-focusedThickness': '1px',
                  '&:hover': {
                    borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
                  },
                }}
                slotProps={{
                  listbox: {
                    sx: {
                      maxHeight: '400px',
                      overflow: 'auto',
                    },
                  },
                }}
              >
                <Option value="unassigned">Unassigned</Option>
                <Option value="needs_work">Needs work</Option>
                <Option value="working_on_it">Working on it</Option>
                <Option value="verbally_accepted">Verbally accepted</Option>
                <Option value="full_pipeline">Full pipeline</Option>
                <Option value="incoming">Incoming</Option>
                <Option value="intros_welcome">Intros welcome</Option>
                <Option value="on_hold">On hold</Option>
                <Option value="postponed">Postponed</Option>
                <Option value="placed">Placed</Option>
                <Option value="closed">Closed</Option>
              </Select>
            )}
          />
        </FormControl>

        <FormControl size="sm" sx={{ minWidth: 200 }}>
          <Controller
            control={control}
            name="vertical"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Filter by vertical"
                size="sm"
                onChange={(e, value) => {
                  field.onChange(value);
                  onResetPage();
                }}
                sx={{
                  backgroundColor: 'white',
                  '--Select-focusedThickness': '1px',
                  '&:hover': {
                    borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
                  },
                }}
              >
                <Option value="">All verticals</Option>
                {verticals?.map((vertical) => (
                  <Option key={vertical.id} value={vertical.id}>
                    {vertical.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <FormControl size="sm" sx={{ minWidth: 200 }}>
          <Controller
            control={control}
            name="priorityFilter"
            defaultValue=""
            render={({ field }) => (
              <Select<PriorityFilterValue>
                {...field}
                placeholder="Filter by priority"
                size="sm"
                onChange={(e, value) => {
                  field.onChange(value as PriorityFilterValue);
                  onResetPage();
                }}
                sx={{
                  backgroundColor: 'white',
                  '--Select-focusedThickness': '1px',
                  '&:hover': {
                    borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
                  },
                }}
              >
                <Option value="">All priorities</Option>
                <Option value="1">P1 - {PRIORITY_LABELS[1]}</Option>
                <Option value="2">P2 - {PRIORITY_LABELS[2]}</Option>
                <Option value="3">P3 - {PRIORITY_LABELS[3]}</Option>
                <Option value="4">P4 - {PRIORITY_LABELS[4]}</Option>
              </Select>
            )}
          />
        </FormControl>

        <IconButton
          variant="outlined"
          color="neutral"
          onClick={() => setIsFilterDrawerOpen(true)}
          size="sm"
          sx={{
            backgroundColor: 'white',
            borderColor: 'var(--joy-palette-neutral-outlinedBorder)',
            '&:hover': {
              borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
              backgroundColor: 'var(--joy-palette-background-level1)',
            },
          }}
        >
          <FilterList />
        </IconButton>
      </Stack>

      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        size="sm"
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--joy-palette-background-surface)',
            boxShadow: 'var(--joy-shadow-lg)',
          },
        }}
      >
        <Box sx={{ p: 3, width: 320 }}>
          <Typography level="h4" mb={3}>
            Advanced Filters
          </Typography>

          <Stack spacing={2.5}>
            <FormControl size="sm">
              <FormLabel>Location</FormLabel>
              <Controller
                control={control}
                name="location"
                render={({ field }) => <Input {...field} placeholder="Filter by location" size="sm" />}
              />
            </FormControl>

            <Divider />

            <FormControl size="sm">
              <FormLabel>Salary Range</FormLabel>
              <Stack direction="row" spacing={1}>
                <Controller
                  control={control}
                  name="salaryGt"
                  render={({ field }) => <Input {...field} placeholder="Min" size="sm" type="number" />}
                />
                <Controller
                  control={control}
                  name="salaryLt"
                  render={({ field }) => <Input {...field} placeholder="Max" size="sm" type="number" />}
                />
              </Stack>
            </FormControl>

            <FormControl size="sm">
              <FormLabel>Fee Range (%)</FormLabel>
              <Stack direction="row" spacing={1}>
                <Controller
                  control={control}
                  name="feeGt"
                  render={({ field }) => <Input {...field} placeholder="Min" size="sm" type="number" />}
                />
                <Controller
                  control={control}
                  name="feeLt"
                  render={({ field }) => <Input {...field} placeholder="Max" size="sm" type="number" />}
                />
              </Stack>
            </FormControl>
          </Stack>

          <Box sx={{ mt: 4, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button variant="plain" color="neutral" onClick={() => setIsFilterDrawerOpen(false)} size="sm">
              Cancel
            </Button>
            <Button onClick={() => setIsFilterDrawerOpen(false)} size="sm">
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
});

export { JobsTableFilters };
