import { TaskboardItem, TaskboardStatus } from '@bluebird-monorepo/types';
import { Droppable, DroppableProvided } from '@hello-pangea/dnd';
import { Box, Card, CircularProgress, Typography } from '@mui/joy';
import { FC } from 'react';
import { TaskCard } from './TaskCard';

interface TaskColumnProps {
  title: string;
  tasks: TaskboardItem[];
  status: TaskboardStatus;
  isLoading?: boolean;
}

export const TaskColumn: FC<TaskColumnProps> = ({ title, tasks, status, isLoading = false }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.level1',
      }}
    >
      <Box
        sx={{
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography level="title-lg">{title}</Typography>
        <Typography level="body-sm">{tasks.length}</Typography>
      </Box>

      <Droppable droppableId={status}>
        {(provided: DroppableProvided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              flex: 1,
              p: 1,
              overflowY: 'auto',
              minHeight: '200px',
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {tasks.map((task, index) => (
                  <TaskCard key={task.id} task={task} index={index} />
                ))}
                {provided.placeholder}
              </>
            )}
          </Box>
        )}
      </Droppable>
    </Card>
  );
};
