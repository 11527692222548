import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Company } from '@bluebird-monorepo/types';
import {
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

export class CompanyRepository extends BaseRepository<Company> {
  private static completedSearchQueries = new Set<string>();

  constructor(db: Firestore) {
    super(db, 'companies');
  }

  async getNextBatch(lastCompany: Company | null = null, batchSize = 50): Promise<Company[]> {
    const requestId = Date.now().toString();
    const timerLabel = `CompanyRepository_Batch_${requestId}`;

    console.time(timerLabel);
    console.log('🔍 Starting company batch query');

    try {
      const companiesQuery = query(this.collectionRef, orderBy('name'), limit(batchSize));

      console.log('🔎 Executing company query...');
      const snapshot = await getDocs(companiesQuery);
      const companies = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as Company);

      console.timeEnd(timerLabel);
      console.log(`🎯 Query found ${companies.length} companies`);

      return companies;
    } catch (error) {
      console.error('❌ Error in getNextBatch:', error);
      console.timeEnd(timerLabel);
      throw error;
    }
  }

  private async getDocumentSnapshot(id: string): Promise<DocumentSnapshot | null> {
    try {
      const snapshot = await getDoc(this.docRef(id));
      return snapshot;
    } catch (error) {
      console.error('Error getting document snapshot:', error);
      return null;
    }
  }

  async getByNamePrefix(searchTerm: string): Promise<Company[]> {
    const requestId = Date.now().toString();
    const timerLabel = `CompanyByNamePrefix_${requestId}`;

    console.time(timerLabel);
    console.log('🔍 Starting company search:', {
      searchTerm,
      requestId,
      isFirstSearch: !CompanyRepository.completedSearchQueries.has(searchTerm),
      completedQueries: Array.from(CompanyRepository.completedSearchQueries),
    });

    const prefixQuery = query(
      this.collectionRef,
      where('nameLowerCase', '>=', searchTerm.toLowerCase()),
      where('nameLowerCase', '<=', searchTerm.toLowerCase() + '\uf8ff'),
      orderBy('nameLowerCase'),
      limit(50),
    );

    console.log('Firestore instance used by collectionRef:', this.collectionRef.firestore);
    console.log('Firestore matches expected instance:', this.collectionRef.firestore === this.db);

    // Debug query Firestore instance
    console.log('Prefix query Firestore instance:', prefixQuery.firestore);
    console.log(
      'Does prefixQuery use the correct Firestore instance?',
      prefixQuery.firestore === this.collectionRef.firestore,
    );

    let companies: Company[] = [];
    try {
      const snapshot = await getDocs(prefixQuery);
      companies = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as Company);
    } catch (error) {
      console.error('Error getting companies by prefix:', error);
      console.log('Error getting companies by prefix:', JSON.stringify(error));
      throw error;
    }

    // Add this search term to completed queries
    CompanyRepository.completedSearchQueries.add(searchTerm);

    console.timeEnd(timerLabel);
    console.log('🎯 Search results:', {
      searchTerm,
      resultCount: companies.length,
      results: companies.map((c) => ({ id: c.id, name: c.name })),
      requestId,
      totalCompletedQueries: CompanyRepository.completedSearchQueries.size,
    });

    return companies;
  }

  async getBySorting(
    field: string,
    direction: 'asc' | 'desc',
    lastCompany: Company | null = null,
    batchSize = 50,
  ): Promise<Company[]> {
    const requestId = Date.now().toString();
    const timerLabel = `CompanyBySorting_${requestId}`;

    console.time(timerLabel);
    console.log('🔍 Starting sorted query for companies:', { field, direction });

    try {
      let sortQuery;

      if (lastCompany) {
        console.log('📑 Paginating from last company:', lastCompany.id);
        const lastSnapshot = await this.getDocumentSnapshot(lastCompany.id);
        if (!lastSnapshot) {
          console.warn('❌ Last company snapshot not found', lastCompany);
          return [];
        }

        sortQuery = query(this.collectionRef, orderBy(field, direction), startAfter(lastSnapshot), limit(batchSize));
      } else {
        sortQuery = query(this.collectionRef, orderBy(field, direction), limit(batchSize));
      }

      console.log('🔎 Executing sort query...');
      const snapshot = await getDocs(sortQuery);
      const results = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as Company);

      console.timeEnd(timerLabel);
      console.log(`🎯 Sort query found ${results.length} companies`);

      return results;
    } catch (error) {
      console.error('❌ Error in getBySorting:', error);
      console.timeEnd(timerLabel);
      throw error;
    }
  }
}
