import { User } from '@bluebird-monorepo/types';
import { memo } from 'react';
import { UserSelect } from '../UserSelect/UserSelect';

export interface EditableUserAvatarProps {
  /**
   * The current user identifier (User object, email, or name)
   */
  user: User | string | null;
  /**
   * Callback when user changes
   */
  onChange: (newValue: string | null) => void;
  /**
   * Optional size override
   */
  size?: number;
}

function EditableUserAvatarComponent({ user, onChange, size }: EditableUserAvatarProps) {
  return <UserSelect value={user} onChange={onChange} multiple={false} size={size} />;
}

export const EditableUserAvatar = memo(EditableUserAvatarComponent);
