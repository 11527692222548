import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';

import styled from '@emotion/styled';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import React, { CSSProperties, memo, ReactElement, useCallback, useMemo } from 'react';

import { useSelectionStore } from '../store/useSelectionStore';
import { CandidateCard } from '../types';
import CandidateAssignedToJobItem from './CandidateAssignedToJobItem';
import Title from './Title';

const getBackgroundColor = (isDraggingOver: boolean, isDraggingFrom: boolean): string => {
  if (isDraggingOver) {
    return 'grey';
  }
  if (isDraggingFrom) {
    return '#b0b1b5';
  }
  return '#f9f9f9';
};

interface WrapperProps {
  isDraggingFrom: boolean;
  isDraggingOver: boolean;
  isDropDisabled: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: 15px;
  border: 2px;
  padding-bottom: 0;
  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;
  width: 285px;
  border-radius: 15px;
`;

const scrollContainerHeight = 650;

const DropZone = styled.div`
  min-height: ${scrollContainerHeight}px;
  padding-bottom: 15px;
  > * {
    margin-bottom: 15px;
  }
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

const Container = styled.div``;

interface Props {
  ignoreContainerClipping?: boolean;
  internalScroll?: boolean;
  isCombineEnabled?: boolean;
  isDropDisabled?: boolean;
  isDraggingSelected?: boolean;
  listId?: string;
  listType?: string;
  cards: CandidateCard[];
  scrollContainerStyle?: CSSProperties;
  style?: CSSProperties;
  title?: string;
  useClone?: boolean;
}

interface CardListProps {
  cards: CandidateCard[];
  isDraggingSelected?: boolean;
  listId: string;
}

const InnerCardList = memo(function InnerCardList({ cards, isDraggingSelected, listId }: CardListProps) {
  const selectedItems = useSelectionStore((state) => state.selectedItems);
  const setColumnItems = useSelectionStore((state) => state.setColumnItems);

  const columnItems = useMemo(() => cards.map((card) => `${card.jobId}_${card.candidateId}`), [cards]);

  React.useEffect(() => {
    setColumnItems(listId, columnItems);
  }, [columnItems, setColumnItems, listId]);

  const renderCard = useCallback(
    (card: CandidateCard, index: number) => {
      const id = `${card.jobId}_${card.candidateId}`;
      const isSelected = selectedItems.has(id);

      return (
        <Draggable
          draggableId={'candidateId:' + card.candidateId + '&&jobId:' + card.jobId}
          index={index}
          key={card.id}
        >
          {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
            <CandidateAssignedToJobItem
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              key={card.id}
              provided={dragProvided}
              card={card}
              columnId={listId}
              style={{
                opacity: isDraggingSelected && !isSelected ? 0.5 : 1,
                transform: isDraggingSelected && isSelected ? 'scale(0.95)' : 'none',
                transition: 'opacity 0.2s ease, transform 0.2s ease',
              }}
            />
          )}
        </Draggable>
      );
    },
    [isDraggingSelected, listId, selectedItems],
  );

  return <>{cards.map(renderCard)}</>;
});

interface InnerListProps {
  dropProvided: DroppableProvided;
  cards: CandidateCard[];
  title: null | string | undefined;
  isDraggingSelected?: boolean;
  listId: string;
}

const InnerList = memo(function InnerList({ dropProvided, cards, title, isDraggingSelected, listId }: InnerListProps) {
  const titleElement = title ? <Title>{title}</Title> : null;

  return (
    <Container>
      {titleElement}
      <DropZone ref={dropProvided.innerRef}>
        <InnerCardList cards={cards} isDraggingSelected={isDraggingSelected} listId={listId} />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
});

const CandidateAssignedToJobList = memo(function CandidateAssignedToJobList({
  ignoreContainerClipping,
  internalScroll,
  isCombineEnabled,
  isDropDisabled,
  isDraggingSelected,
  listId = 'LIST',
  listType,
  cards,
  scrollContainerStyle,
  style,
  title,
  useClone,
}: Props): ReactElement {
  const renderCloneItem = useCallback(
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot, descriptor: any) => (
      <CandidateAssignedToJobItem
        isClone
        isDragging={snapshot.isDragging}
        provided={provided}
        card={cards[descriptor.source.index]}
        columnId={listId}
      />
    ),
    [cards, listId],
  );

  return (
    <Droppable
      droppableId={listId?.toString()}
      ignoreContainerClipping={ignoreContainerClipping}
      isCombineEnabled={isCombineEnabled}
      isDropDisabled={isDropDisabled}
      renderClone={useClone ? renderCloneItem : undefined}
      type={listType}
    >
      {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
        <Wrapper
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={Boolean(isDropDisabled)}
          style={style}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                dropProvided={dropProvided}
                cards={cards}
                title={title}
                isDraggingSelected={isDraggingSelected}
                listId={listId}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              dropProvided={dropProvided}
              cards={cards}
              title={title}
              isDraggingSelected={isDraggingSelected}
              listId={listId}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
});

export default CandidateAssignedToJobList;
