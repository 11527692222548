import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { Job } from '@bluebird-monorepo/types';
import { Box, FormControl, FormHelperText, FormLabel, Stack } from '@mui/joy';
import { Controller, useFormContext } from 'react-hook-form';

export function DescriptionsStep() {
  const {
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<Job>();

  return (
    <Stack spacing={3}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Descriptions
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Provide detailed descriptions about the role and company</Box>
        </Box>
      </Box>

      <FormControl error={!!errors.productDescription && !!dirtyFields.productDescription}>
        <FormLabel>Product Description</FormLabel>
        <Controller
          name="productDescription"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.productDescription && dirtyFields.productDescription && (
          <FormHelperText>{errors.productDescription.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.roleDescription && !!dirtyFields.roleDescription}>
        <FormLabel>Role Description</FormLabel>
        <Controller
          name="roleDescription"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.roleDescription && dirtyFields.roleDescription && (
          <FormHelperText>{errors.roleDescription.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.uniqueAboutCompany && !!dirtyFields.uniqueAboutCompany}>
        <FormLabel>Unique About the Company</FormLabel>
        <Controller
          name="uniqueAboutCompany"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.uniqueAboutCompany && dirtyFields.uniqueAboutCompany && (
          <FormHelperText>{errors.uniqueAboutCompany.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.idealCandidateProfile && !!dirtyFields.idealCandidateProfile}>
        <FormLabel>Ideal Candidate Profile</FormLabel>
        <Controller
          name="idealCandidateProfile"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.idealCandidateProfile && dirtyFields.idealCandidateProfile && (
          <FormHelperText>{errors.idealCandidateProfile.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.extraInformation && !!dirtyFields.extraInformation}>
        <FormLabel>Extra Information</FormLabel>
        <Controller
          name="extraInformation"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.extraInformation && dirtyFields.extraInformation && (
          <FormHelperText>{errors.extraInformation.message}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
}
