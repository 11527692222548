import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { useCompanySearch } from '@bluebird-monorepo/companies';
import { Job } from '@bluebird-monorepo/types';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';
import { httpsCallable } from 'firebase/functions';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface TemplateResponse {
  data: {
    parsedData: Partial<Job>;
  };
}

const LoadingOverlay = () => (
  <Box
    sx={{
      alignItems: 'center',
      bgcolor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1300,
    }}
  >
    <CircularProgress />
  </Box>
);

export function AboutStep() {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext<Job>();

  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState('');
  const { results: companies, isLoading: isLoadingCompanies } = useCompanySearch(searchTerm, undefined, 'asc', true);

  console.log(companies);
  console.log(isLoadingCompanies);
  const companyId = watch('companyId');
  const companyName = watch('companyName');

  const initialOption = companyId && companyName ? { id: companyId, name: companyName } : null;

  const displayedOptions = searchTerm ? companies : initialOption ? [initialOption] : [];

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['companies', 'prefix-search'] });

    return () => {
      queryClient.invalidateQueries({ queryKey: ['companies', 'prefix-search'] });
      setSearchTerm('');
    };
  }, [queryClient]);

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      if (!value) {
        queryClient.invalidateQueries({ queryKey: ['companies', 'prefix-search'] });
      }
    }, 300),
    [queryClient],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [newOppsTemplate, setNewOppsTemplate] = useState('');

  const { functions } = useFirebase();

  async function parseJobTemplate(functions: any, template: string) {
    const parseTemplate = httpsCallable<{ template: string }, any>(functions, 'parseJobTemplate');

    try {
      const result = await parseTemplate({ template });
      return result.data.parsedData;
    } catch (error) {
      console.error('Error parsing job template:', error);
      throw new Error('Failed to parse job template.');
    }
  }

  function changeNewOpps(value: string): void {
    setNewOppsTemplate(value);
  }

  const UpdateJobFormValues = (job: Partial<Job>) => {
    // Update job title if available
    if (job.jobTitle) {
      setValue('jobTitle', job.jobTitle);
    }

    // Update location if available
    if (job.location) {
      setValue('location', job.location);
    }

    // Set contract type to Full-time
    setValue('contractType', 'Full-time');

    // Update company if available
    if (job.companyName) {
      const company = companies.find((c) => c.name.toLowerCase() === job.companyName?.toLowerCase());
      if (company) {
        setValue('companyId', company.id);
      }
    }

    // Update other fields
    Object.keys(job).forEach((key) => {
      const value = (job as any)[key];
      if (
        value !== null &&
        value !== undefined &&
        key !== 'jobTitle' &&
        key !== 'location' &&
        key !== 'contractType' &&
        key !== 'companyName'
      ) {
        setValue(key as keyof Job, value);
      }
    });
  };

  function tryToMapValues(): void {
    const template = newOppsTemplate;

    setIsLoading(true);
    parseJobTemplate(functions, template)
      .then((job) => {
        setIsLoading(false);
        UpdateJobFormValues(job);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setIsOpen(false);
        alert('Failed to parse job template. Please ensure the template is valid.');
      });
  }

  return (
    <Stack spacing={3}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            About the Job
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Let's start with the basic information about the position</Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button onClick={() => setIsOpen(true)}>Import new-opps-template</Button>
      </Box>

      <FormControl error={!!errors.jobTitle && !!dirtyFields.jobTitle} required>
        <FormLabel>Job Title</FormLabel>
        <Input
          {...register('jobTitle')}
          placeholder="e.g. Senior Frontend Developer"
          slotProps={{
            input: {
              'aria-describedby': 'job-title-helper',
            },
          }}
        />
        {errors.jobTitle && dirtyFields.jobTitle && (
          <FormHelperText id="job-title-helper">{errors.jobTitle.message}</FormHelperText>
        )}
      </FormControl>

      {/* Company Selection */}
      <FormControl required error={!!errors.companyId && !!dirtyFields.companyId}>
        <FormLabel>Company</FormLabel>
        <Controller
          name="companyId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              placeholder="Search for a company..."
              options={displayedOptions}
              loading={isLoadingCompanies && !!searchTerm}
              getOptionLabel={(option) => option.name}
              value={initialOption || null}
              onChange={(_, newValue) => {
                field.onChange(newValue?.id || '');
                setValue('companyName', newValue?.name || '', {
                  shouldValidate: true,
                });
                setSearchTerm('');
              }}
              onInputChange={(_, value) => {
                debouncedSetSearchTerm(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterOptions={(x) => x}
              noOptionsText={searchTerm ? 'No companies found' : 'Start typing to search...'}
              onClose={() => {
                if (!field.value) {
                  setSearchTerm('');
                  queryClient.invalidateQueries({ queryKey: ['companies', 'prefix-search'] });
                }
              }}
            />
          )}
        />

        {errors.companyId && dirtyFields.companyId && <FormHelperText>{errors.companyId.message}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.location && !!dirtyFields.location} required>
        <FormLabel>Location</FormLabel>
        <Input
          {...register('location')}
          placeholder="e.g. Amsterdam, Netherlands"
          slotProps={{
            input: {
              'aria-describedby': 'location-helper',
            },
          }}
        />
        {errors.location && dirtyFields.location && (
          <FormHelperText id="location-helper">{errors.location.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.contractType && !!dirtyFields.contractType} required>
        <FormLabel>Employment type</FormLabel>
        <Controller
          name="contractType"
          control={control}
          render={({ field }) => (
            <Box sx={{ display: 'flex', gap: 1.5, flexWrap: 'wrap' }}>
              {['Full-time', 'Part-time', 'Contract', 'Freelance'].map((type) => (
                <Box
                  key={type}
                  onClick={() => field.onChange(type)}
                  sx={{
                    border: '1px solid',
                    borderColor: field.value === type ? 'primary.500' : 'divider',
                    borderRadius: 'sm',
                    p: 2,
                    cursor: 'pointer',
                    bgcolor: field.value === type ? 'background.level1' : 'transparent',
                    '&:hover': {
                      bgcolor: 'background.level1',
                    },
                  }}
                >
                  {type}
                </Box>
              ))}
            </Box>
          )}
        />
        {errors.contractType && dirtyFields.contractType && (
          <FormHelperText>{errors.contractType.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.vertical && !!dirtyFields.vertical}>
        <FormLabel>Vertical</FormLabel>
        <Controller
          name="vertical"
          control={control}
          render={({ field }) => (
            <Box sx={{ display: 'flex', gap: 1.5, flexWrap: 'wrap' }}>
              {['Engineering', 'Product', 'Marketing', 'Sales', 'Customer Success'].map((type) => (
                <Box
                  key={type}
                  onClick={() => field.onChange(type)}
                  sx={{
                    border: '1px solid',
                    borderColor: field.value === type ? 'primary.500' : 'divider',
                    borderRadius: 'sm',
                    p: 2,
                    cursor: 'pointer',
                    bgcolor: field.value === type ? 'background.level1' : 'transparent',
                    '&:hover': {
                      bgcolor: 'background.level1',
                    },
                  }}
                >
                  {type}
                </Box>
              ))}
            </Box>
          )}
        />
        {errors.vertical && dirtyFields.vertical && <FormHelperText>{errors.vertical.message}</FormHelperText>}
      </FormControl>

      <Modal onClose={() => setIsOpen(false)} open={isOpen} sx={{ maxWidth: 700 }}>
        <ModalOverflow>
          <ModalDialog layout="fullscreen" sx={{ padding: 3 }} variant="outlined">
            <ModalClose sx={{ m: 1 }} variant="plain" />
            <Typography level="h3">Copy paste our new-opps-template</Typography>
            <Divider inset="none" />
            {isLoading && <LoadingOverlay />}
            <RichTextEditor
              content="Please paste the new-opps template in here"
              onChange={(value) => changeNewOpps(value)}
              showMenuBar={false}
            />
            <Button onClick={() => tryToMapValues()}>Try to map</Button>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </Stack>
  );
}
