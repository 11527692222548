import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Option from '@mui/joy/Option';
import Select, { selectClasses } from '@mui/joy/Select';
import { useEffect, useRef, useState } from 'react';

import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import './ResponsibleColumn.css';

const responsibleMapping = {
  Dennis: {
    colorClass: 'mint-green',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U05QQC9SJ1W-8fccbf482c28-512',
    name: 'Dennis',
  },
  Jacob: {
    colorClass: 'blush-pink',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U06T7S16K6W-ab5ce9b172f2-192',
    name: 'Jacob',
  },
  Mitchel: {
    colorClass: 'pale-lavender',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U07RZ07JSDP-d3370936361e-192',
    name: 'Mitchel',
  },
  Pontus: {
    colorClass: 'powder-blue',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U075S4N9M6V-dec8ff679f17-192',
    name: 'Pontus',
  },
} as const;

type ResponsibleName = keyof typeof responsibleMapping;

export function ResponsibleColumn({ job }: { job: Job }) {
  const [isEditing, setIsEditing] = useState(false);

  // Handle legacy comma-separated values
  const currentResponsible = (() => {
    return job.responsible || '';
  })();

  const { responsibles: possibleResponsibles, updateJobMutation } = useJobs();
  const ref = useRef<HTMLTableCellElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);
  const listboxRef = useRef<HTMLUListElement | null>(null);

  const handleChipClick = () => {
    setIsEditing(true);
  };

  const handleResponsibleChange = async (_event: any, newValue: string | null) => {
    if (newValue) {
      await updateJobMutation.mutateAsync({
        id: job.id,
        data: { responsible: newValue },
      });
    }
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isEditing) return;

      setTimeout(() => {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          selectRef.current &&
          !selectRef.current.contains(event.target as Node) &&
          listboxRef.current &&
          !listboxRef.current.contains(event.target as Node)
        ) {
          setIsEditing(false);
        }
      }, 100);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const listbox = document.querySelector('[role="listbox"]') as HTMLUListElement;
          if (listbox) {
            listboxRef.current = listbox;
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <span ref={ref}>
      {isEditing ? (
        <Select
          component="button"
          autoFocus
          listboxOpen={isEditing}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={handleResponsibleChange}
          onClose={handleClose}
          ref={selectRef}
          size="sm"
          value={currentResponsible}
          slotProps={{
            listbox: {
              ref: listboxRef,
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
              },
            },
          }}
          sx={{
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
        >
          {possibleResponsibles.map((responsible) => (
            <Option key={responsible.name} value={responsible.name}>
              {responsible.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, pl: '1rem' }}>
          <div className="avatar-stack">
            {currentResponsible !== 'None' && currentResponsible !== 'Choose' ? (
              <img
                alt={responsibleMapping[currentResponsible as ResponsibleName]?.name}
                onClick={handleChipClick}
                src={responsibleMapping[currentResponsible as ResponsibleName]?.imgUrl}
              />
            ) : (
              <Chip onClick={handleChipClick} size="sm" sx={{ cursor: 'pointer' }} variant="outlined">
                {currentResponsible}
              </Chip>
            )}
          </div>
        </Box>
      )}
    </span>
  );
}
