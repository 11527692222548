import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { BluebirdIcon, DashedButton } from '@bluebird-monorepo/bluebird-ui';
import { ConnectGoogleCalendar } from '@bluebird-monorepo/calendar';
import { useUser } from '@bluebird-monorepo/users';
import { ChevronLeft, LogoutRounded } from '@mui/icons-material';
import { Avatar, Box, IconButton, Sheet, Tooltip, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { StyledButton } from './StyledButton';

// Layout Constants
const SIDEBAR_WIDTH = 280; // pixels
const SIDEBAR_WIDTH_COLLAPSED = 72; // pixels
const HEADER_HEIGHT = 64; // pixels
const COLLAPSE_TRANSITION = 'width 0.2s ease-in-out, margin 0.2s ease-in-out';

// Logo Component
const Logo = ({ isCollapsed }: { isCollapsed: boolean }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, overflow: 'hidden' }}>
    <BluebirdIcon sx={{ width: 24, height: 24, flexShrink: 0 }} />
    {!isCollapsed && (
      <Typography
        level="h4"
        sx={{
          background: 'linear-gradient(45deg, var(--joy-palette-primary-500), var(--joy-palette-primary-700))',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        Bluehire
      </Typography>
    )}
  </Box>
);

// User Menu Component
const UserMenu = ({ user, onLogout }: { user: any; onLogout: () => void }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <Box sx={{ textAlign: 'right' }}>
      <Typography level="title-sm">{user?.displayName}</Typography>
      <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
        {user?.email}
      </Typography>
    </Box>
    <Box sx={{ position: 'relative' }}>
      <Avatar
        src={user?.photoURL}
        alt={user?.displayName}
        size="md"
        sx={{
          cursor: 'pointer',
          '&:hover': { opacity: 0.8 },
        }}
      />
      {/* Notification badge - commented out for future use */}
      {/* <Box
        sx={{
          position: 'absolute',
          top: -2,
          right: -2,
          width: 16,
          height: 16,
          borderRadius: '50%',
          bgcolor: 'danger.500',
          border: '2px solid',
          borderColor: 'background.surface',
        }}
      /> */}
    </Box>
    <IconButton onClick={onLogout} variant="plain" color="neutral" size="sm">
      <LogoutRounded />
    </IconButton>
  </Box>
);

interface LayoutProps {
  title: string | Element;
  count?: number;
  subtitle?: string | Element;
  actionButton?: {
    label: string;
    onClick: () => void;
    variant: 'create' | 'connect' | 'delete' | 'export' | 'warning' | 'dashed';
  };
  children: React.ReactNode;
  setIsLoading?: (loading: boolean) => void;
  displayTitle?: boolean;
}

const Layout = ({ children, title, count, subtitle, actionButton, setIsLoading, displayTitle = true }: LayoutProps) => {
  const { auth } = useFirebase();
  const { user } = useUser(auth?.currentUser?.uid);
  const navigate = useNavigate();
  const nonGlobalLocation = useLocation();
  const isCalendarPage = nonGlobalLocation.pathname === '/calendar';
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const stored = localStorage.getItem('sidebarCollapsed');
    return stored ? JSON.parse(stored) : false;
  });

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isCollapsed));

    // Add keyboard shortcut for toggling sidebar
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === '\\') {
        setIsCollapsed((prev: any) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isCollapsed]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const currentWidth = isCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* Sidebar */}
      <Sheet
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          width: currentWidth,
          borderRight: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.surface',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          transition: COLLAPSE_TRANSITION,
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: HEADER_HEIGHT,
          }}
        >
          <Logo isCollapsed={isCollapsed} />
          <Tooltip title={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'} placement="right">
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => setIsCollapsed((prev: any) => !prev)}
              sx={{
                transform: isCollapsed ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s',
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Tooltip>
        </Box>
        <Sidebar isCollapsed={isCollapsed} />
      </Sheet>

      {/* Main Content Area */}
      <Box
        sx={{
          ml: `${currentWidth}px`,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          overflow: 'hidden',
          bgcolor: 'background.surface',
          transition: COLLAPSE_TRANSITION,
        }}
      >
        {/* Header */}
        <Sheet
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            left: currentWidth,
            height: HEADER_HEIGHT,
            borderBottom: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.surface',
            zIndex: 900,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            transition: COLLAPSE_TRANSITION,
          }}
        >
          <UserMenu user={user} onLogout={handleLogout} />
        </Sheet>

        {/* Scrollable Content */}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            mt: `${HEADER_HEIGHT}px`,
            p: 3,
          }}
        >
          {displayTitle && (
            <Box
              sx={{
                alignItems: { sm: 'center', xs: 'start' },
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                flexDirection: { sm: 'row', xs: 'column' },
                gap: 1,
                justifyContent: 'space-between',
                mb: 3,
                pb: 2,
              }}
            >
              <Box>
                <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                  <Typography
                    component="h1"
                    level="h2"
                    sx={{
                      background:
                        'linear-gradient(45deg, var(--joy-palette-primary-500), var(--joy-palette-primary-700))',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: 'bold',
                    }}
                  >
                    {title as React.ReactNode}
                  </Typography>
                  {count !== undefined && `(${count})`}
                </Box>
                {subtitle && (
                  <Typography level="body-sm" sx={{ color: 'text.secondary', mt: 0.5 }}>
                    {subtitle as React.ReactNode}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {isCalendarPage && (
                  <ConnectGoogleCalendar color="primary" setIsLoading={setIsLoading} size="md" variant="soft" />
                )}
                {actionButton && actionButton.variant !== 'dashed' && (
                  <StyledButton onClick={actionButton.onClick} size="md" customVariant={actionButton.variant}>
                    {actionButton.label}
                  </StyledButton>
                )}
                {actionButton && actionButton.variant === 'dashed' && (
                  <DashedButton onClick={actionButton.onClick} size="md">
                    {actionButton.label}
                  </DashedButton>
                )}
              </Box>
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export { Layout };
