import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { CreateTaskboardItemDto, UpdateTaskboardItemDto } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';
import { TaskRepository } from '../repositories/task.repository';
import { useTaskStore } from '../store/tasks.store';

interface UseTasksOptions {
  entityType?: string;
  entityId?: string;
  assigneeId?: string;
  status?: string;
}

export const useTasks = (options: UseTasksOptions = {}) => {
  const { firestore } = useFirebase();
  const taskRepository = useMemo(() => new TaskRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { tasks, setTasks, addTask, updateTask: updateTaskInStore, removeTask, setIsLoading } = useTaskStore();

  // Build query key based on options
  const queryKey = useMemo(() => {
    const key = ['tasks'];
    if (options.entityType && options.entityId) {
      key.push('entity', options.entityType, options.entityId);
    }
    if (options.assigneeId) {
      key.push('assignee', options.assigneeId);
    }
    if (options.status) {
      key.push('status', options.status);
    }
    return key;
  }, [options.entityType, options.entityId, options.assigneeId, options.status]);

  // Query function based on options
  const queryFn = async () => {
    if (options.entityType && options.entityId) {
      return taskRepository.getTasksByEntity(options.entityType, options.entityId);
    }
    if (options.assigneeId) {
      return taskRepository.getTasksByAssignee(options.assigneeId);
    }
    if (options.status) {
      return taskRepository.getTasksByStatus(options.status);
    }
    return taskRepository.getAll();
  };

  const { data, isLoading, error } = useQuery({
    queryKey,
    queryFn,
  });

  useMemo(() => {
    if (data) {
      setTasks(data);
    }
  }, [data, setTasks]);

  useMemo(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const createTaskMutation = useMutation({
    mutationFn: (newTask: CreateTaskboardItemDto) => {
      const now = Timestamp.now();
      const taskWithTimestamps = {
        ...newTask,
        createdAt: now,
        updatedAt: now,
      };
      return taskRepository.create(taskWithTimestamps);
    },
    onSuccess: (task) => {
      addTask(task);
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const updateTaskMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: UpdateTaskboardItemDto }) => {
      const taskWithTimestamp = {
        ...data,
        updatedAt: Timestamp.now(),
      };
      return taskRepository.update(id, taskWithTimestamp);
    },
    onSuccess: (_, variables) => {
      updateTaskInStore(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  const deleteTaskMutation = useMutation({
    mutationFn: (id: string) => taskRepository.delete(id),
    onSuccess: (_, id) => {
      removeTask(id);
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });

  return {
    tasks,
    isLoading,
    error,
    createTask: createTaskMutation.mutate,
    updateTask: updateTaskMutation.mutate,
    deleteTask: deleteTaskMutation.mutate,
  };
};
