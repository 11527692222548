import { LanguageSelect } from '@bluebird-monorepo/bluebird-forms';
import { Job } from '@bluebird-monorepo/types';
import { Box, Checkbox, FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useFormContext } from 'react-hook-form';

type JobFormData = Omit<Job, 'openSinceDate'> & {
  openSinceDate?: { seconds: number; nanoseconds: number } | undefined;
};

export function DetailsStep() {
  const {
    register,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<JobFormData>();

  const openSinceDate = watch('openSinceDate');

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date && date.isValid()) {
      // Clear any existing date errors
      clearErrors('openSinceDate');

      // Create a timestamp and extract seconds and nanoseconds
      const timestamp = Timestamp.fromDate(date.startOf('day').toDate());
      setValue(
        'openSinceDate',
        {
          seconds: timestamp.seconds,
          nanoseconds: timestamp.nanoseconds,
        },
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    } else {
      setValue('openSinceDate', undefined, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  // Convert the timestamp-like object to dayjs for the DateCalendar
  const dateValue = openSinceDate ? dayjs.unix(openSinceDate.seconds) : null;

  return (
    <Stack spacing={3} sx={{ maxWidth: '80%' }}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Job Details
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Specify salary, requirements, and technical details</Box>
        </Box>
      </Box>

      <FormControl error={!!errors.openSinceDate && !!dirtyFields.openSinceDate}>
        <FormLabel>Open Since Date</FormLabel>
        <DateCalendar
          value={dateValue}
          onChange={handleDateChange}
          views={['year', 'month', 'day']}
          disableFuture
          sx={{ width: '100%' }}
        />
        {errors.openSinceDate && dirtyFields.openSinceDate && (
          <FormHelperText>{errors.openSinceDate.message}</FormHelperText>
        )}
      </FormControl>

      <Stack spacing={2}>
        <FormLabel>Salary Range</FormLabel>
        <Stack direction="row" spacing={2}>
          <FormControl error={!!errors.salaryRange?.min && !!dirtyFields.salaryRange?.min}>
            <Input
              {...register('salaryRange.min', { valueAsNumber: true })}
              type="number"
              placeholder="Min"
              startDecorator="€"
            />
            {errors.salaryRange?.min && dirtyFields.salaryRange?.min && (
              <FormHelperText>{errors.salaryRange.min.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={!!errors.salaryRange?.max && !!dirtyFields.salaryRange?.max}>
            <Input
              {...register('salaryRange.max', { valueAsNumber: true })}
              type="number"
              placeholder="Max"
              startDecorator="€"
            />
            {errors.salaryRange?.max && dirtyFields.salaryRange?.max && (
              <FormHelperText>{errors.salaryRange.max.message}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </Stack>

      <FormControl error={!!errors.feePercentage && !!dirtyFields.feePercentage} required>
        <FormLabel>Fee Percentage</FormLabel>
        <Input
          {...register('feePercentage', { valueAsNumber: true })}
          type="number"
          endDecorator="%"
          placeholder="This should be autofilled by company"
        />
        {errors.feePercentage && dirtyFields.feePercentage && (
          <FormHelperText>{errors.feePercentage.message}</FormHelperText>
        )}
      </FormControl>

      <LanguageSelect
        control={control as any}
        error={errors.preferredLanguages && dirtyFields.preferredLanguages ? errors.preferredLanguages : undefined}
        required={false}
      />

      <Stack direction="row" spacing={2} width="100%">
        <FormControl sx={{ width: '50%' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox {...register('offersRelocation')} />
            <Typography>Offers Relocation</Typography>
          </Stack>
        </FormControl>

        <FormControl sx={{ width: '50%' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox {...register('providesVisaSponsorship')} />
            <Typography>Provides Visa Sponsorship</Typography>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
}
