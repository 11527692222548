import { User } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface UserState {
  loggedInUser: User | null;
  activeUser: User | null;
  setLoggedInUser: (user: User | null) => void;
  setActiveUser: (user: User | null) => void;
  updateLoggedInUser: (user: Partial<User>) => void;
  updateActiveUser: (user: Partial<User>) => void;
  clearLoggedInUser: () => void;
  clearActiveUser: () => void;
}

export const useUserStore = create<UserState>((set) => ({
  loggedInUser: null,
  activeUser: null,
  setLoggedInUser: (user) => set({ loggedInUser: user }),
  setActiveUser: (user) => set({ activeUser: user }),
  updateLoggedInUser: (updatedUser) =>
    set((state) => ({
      loggedInUser: state.loggedInUser ? { ...state.loggedInUser, ...updatedUser } : null,
    })),
  updateActiveUser: (updatedUser) =>
    set((state) => ({
      activeUser: state.activeUser ? { ...state.activeUser, ...updatedUser } : null,
    })),
  clearLoggedInUser: () => set({ loggedInUser: null }),
  clearActiveUser: () => set({ activeUser: null }),
}));
