import { useCompanySearch } from '@bluebird-monorepo/companies';
import { Company } from '@bluebird-monorepo/types';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  FormLabel,
  ListItemContent,
  Typography,
  autocompleteClasses,
} from '@mui/joy';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';

interface CompanySearchAutocompleteProps {
  label?: string;
  initialCompany?: Company | null;
  onChange: (company: Company | null) => void;
  disabled?: boolean;
}

export function CompanySearchAutocomplete({
  label = 'Search for a company',
  initialCompany = null,
  onChange,
  disabled = false,
}: CompanySearchAutocompleteProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { results: companies, isLoading } = useCompanySearch(searchTerm, undefined, 'asc', true);

  // Limit results to 20 companies
  const limitedResults = useMemo(() => companies.slice(0, 20), [companies]);

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    [],
  );

  const handleInputChange: AutocompleteProps<Company, false, false, false>['onInputChange'] = (event, value) => {
    if (event) {
      debouncedSearch(value);
    }
  };

  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        defaultValue={initialCompany}
        loading={isLoading && !!searchTerm}
        options={searchTerm ? limitedResults : initialCompany ? [initialCompany] : []}
        disabled={disabled}
        onChange={(_, value) => onChange(value)}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.name || ''}
        placeholder="Start typing to search..."
        noOptionsText={searchTerm ? 'No companies found' : 'Start typing to search...'}
        renderOption={(props, option) => (
          <li {...props}>
            <ListItemContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography level="title-sm">{option.name}</Typography>
              {option.location && (
                <Typography level="body-xs" sx={{ color: 'text.tertiary' }}>
                  {option.location}
                </Typography>
              )}
            </ListItemContent>
          </li>
        )}
        slotProps={{
          listbox: {
            sx: {
              [`& .${autocompleteClasses.option}`]: {
                padding: '8px 12px',
              },
            },
          },
          input: {
            sx: {
              padding: '8px 12px',
            },
          },
        }}
        sx={{
          minWidth: 240,
          [`& .${autocompleteClasses.listbox}`]: {
            '--ListItem-minHeight': '48px',
            '--ListItem-padding': '8px 12px',
          },
        }}
      />
    </Box>
  );
}
