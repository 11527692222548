import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import {
  CreateTaskboardItemDto,
  TaskboardEntityType,
  TaskboardPriority,
  TaskboardStatus,
} from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
} from '@mui/joy';
import { Timestamp } from 'firebase/firestore';
import { FC, useState } from 'react';

interface CreateTaskModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (task: CreateTaskboardItemDto) => Promise<void>;
  entityType?: TaskboardEntityType;
  entityId?: string;
}

export const CreateTaskModal: FC<CreateTaskModalProps> = ({ open, onClose, onSubmit, entityType, entityId }) => {
  const { auth } = useFirebase();
  const { users } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<Partial<CreateTaskboardItemDto>>({
    title: '',
    description: '',
    status: 'todo' as TaskboardStatus,
    priority: 'medium' as TaskboardPriority,
    dueDate: Timestamp.fromDate(new Date()),
    assignedTo: '',
    createdBy: auth.currentUser?.uid || '',
    entityType: entityType,
    entityId: entityId,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.title || !formData.assignedTo) return;

    setIsSubmitting(true);
    try {
      await onSubmit(formData as CreateTaskboardItemDto);
      onClose();
    } catch (error) {
      console.error('Failed to create task:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      dueDate: Timestamp.fromDate(new Date(e.target.value)),
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormControl required>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Enter task title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                minRows={3}
                placeholder="Enter task description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
            </FormControl>

            <FormControl required>
              <FormLabel>Assignee</FormLabel>
              <Select
                value={formData.assignedTo}
                onChange={(_, value) => setFormData({ ...formData, assignedTo: value as string })}
              >
                {users?.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.displayName || user.email}
                  </Option>
                ))}
              </Select>
            </FormControl>

            <FormControl required>
              <FormLabel>Priority</FormLabel>
              <Select
                value={formData.priority}
                onChange={(_, value) => setFormData({ ...formData, priority: value as TaskboardPriority })}
              >
                <Option value="low">Low</Option>
                <Option value="medium">Medium</Option>
                <Option value="high">High</Option>
              </Select>
            </FormControl>

            <FormControl required>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="datetime-local"
                value={new Date(formData.dueDate?.toMillis() || Date.now()).toISOString().slice(0, 16)}
                onChange={handleDateChange}
              />
            </FormControl>

            <Button type="submit" loading={isSubmitting}>
              Create Task
            </Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};
