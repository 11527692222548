import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore } from 'firebase/firestore';
import { CreateVerticalDto, UpdateVerticalDto, Vertical } from '../../../types/src/verticals/vertical.types';

export class VerticalRepository extends BaseRepository<Vertical> {
  constructor(db: Firestore) {
    if (!db) throw new Error('Firestore instance is required');
    super(db, 'verticals');
  }

  async createVertical(vertical: CreateVerticalDto): Promise<Vertical> {
    try {
      return this.create(vertical);
    } catch (error) {
      throw this.handleError(error, 'Error creating vertical');
    }
  }

  async updateVertical(id: string, vertical: UpdateVerticalDto): Promise<void> {
    try {
      return this.update(id, vertical);
    } catch (error) {
      throw this.handleError(error, 'Error updating vertical');
    }
  }

  async deleteVertical(id: string): Promise<void> {
    try {
      return this.delete(id);
    } catch (error) {
      throw this.handleError(error, 'Error deleting vertical');
    }
  }

  async getVertical(id: string): Promise<Vertical | null> {
    try {
      return this.getById(id);
    } catch (error) {
      throw this.handleError(error, 'Error fetching vertical');
    }
  }

  async getAllVerticals(): Promise<Vertical[]> {
    try {
      return this.getAll();
    } catch (error) {
      throw this.handleError(error, 'Error fetching all verticals');
    }
  }
}
