import { Timeline as ITimeline } from '@bluebird-monorepo/types';
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { TimelineEntry } from './TimelineEntry';

export interface TimelineProps {
  timeline: ITimeline;
  initialLimit?: number;
}

export const Timeline: React.FC<TimelineProps> = ({ timeline, initialLimit = 5 }) => {
  const [showAll, setShowAll] = useState(false);
  const sortedEntries = timeline?.entries?.length
    ? [...(timeline?.entries ?? [])].sort((a, b) => {
        // Convert timestamps to Date objects if they're not already
        const timestampA = a?.timestamp instanceof Date ? a.timestamp : a?.timestamp?.toDate?.() || new Date();
        const timestampB = b?.timestamp instanceof Date ? b.timestamp : b?.timestamp?.toDate?.() || new Date();
        return timestampB.getTime() - timestampA.getTime();
      })
    : [];
  const displayedEntries = showAll ? sortedEntries : sortedEntries?.slice(0, initialLimit ?? 5);
  const hasMore = (sortedEntries?.length ?? 0) > (initialLimit ?? 5);

  if (!sortedEntries.length) {
    return (
      <Card variant="soft">
        <CardContent>
          <Typography level="body-sm" textAlign="center">
            No timeline entries yet
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Stack spacing={1}>
      <Box>
        {displayedEntries.map((entry, index) => (
          <TimelineEntry key={entry.id} entry={entry} isLast={index === displayedEntries.length - 1 && !hasMore} />
        ))}
      </Box>

      {hasMore && !showAll && (
        <Button variant="plain" color="neutral" onClick={() => setShowAll(true)} sx={{ alignSelf: 'center' }}>
          Show all {sortedEntries.length} entries
        </Button>
      )}
    </Stack>
  );
};
