import Anthropic from '@anthropic-ai/sdk';
import { TextBlock } from '@anthropic-ai/sdk/resources/messages/messages';
import OpenAI from 'openai';

export class AIService {
  private openai: OpenAI;
  private anthropic: Anthropic;

  constructor() {
    this.openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    this.anthropic = new Anthropic({
      apiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
      dangerouslyAllowBrowser: true,
    });
  }

  async generateIntroduction(params: {
    provider: 'openai' | 'anthropic';
    prompt: string;
    calendarEvent?: any;
    conversations?: any[];
    resumeUrl?: string;
  }) {
    const { provider, prompt, calendarEvent, conversations, resumeUrl } = params;

    // Build context from available data
    let contextPrompt = prompt || '';
    if (calendarEvent) {
      contextPrompt += `\n\nCalendar Event:\n${JSON.stringify(calendarEvent, null, 2)}`;
    }
    if (conversations?.length) {
      contextPrompt += `\n\nConversations:\n${conversations.map((c) => c.content).join('\n\n')}`;
    }
    if (resumeUrl) {
      contextPrompt += `\n\nResume URL: ${resumeUrl}`;
    }

    try {
      if (provider === 'openai') {
        const response = await this.openai.chat.completions.create({
          model: 'gpt-4o',
          messages: [{ role: 'user', content: contextPrompt }],
          temperature: 0.7,
          max_tokens: 4000,
        });
        return response.choices[0]?.message?.content;
      } else {
        const response = await this.anthropic.messages.create({
          model: 'claude-3-5-sonnet-latest',
          max_tokens: 4096,
          messages: [{ role: 'user', content: contextPrompt }],
        });
        const textBlock = response.content[0] as TextBlock;
        return textBlock.text;
      }
    } catch (error) {
      console.error('Error generating introduction:', error);
      throw error;
    }
  }
}

export const aiService = new AIService();
