import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { NoteTimelineEntry } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';
import { JobNote, JobNotesRepository } from '../store/job-notes.repository';
import { JobsRepository } from '../store/job.repository';

export const useJobNotes = (jobId: string) => {
  const { firestore } = useFirebase();
  const queryClient = useQueryClient();

  const notesRepository = useMemo(() => new JobNotesRepository(firestore), [firestore]);
  const jobsRepository = useMemo(() => new JobsRepository(firestore), [firestore]);

  const {
    data: notes = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['jobNotes', jobId],
    queryFn: () => notesRepository.getNotesByJobId(jobId),
    enabled: !!jobId,
  });

  const addNoteMutation = useMutation({
    mutationFn: async (newNote: Omit<JobNote, 'id'>) => {
      // 1. Create the note
      const note = await notesRepository.create(newNote);

      // 2. Create corresponding timeline entry
      const timelineEntry: Omit<NoteTimelineEntry, 'id'> = {
        type: 'note',
        timestamp: Timestamp.now(),
        content: newNote.content,
        visibility: 'team',
        createdBy: {
          id: newNote.author.id,
          name: newNote.author.name || newNote.author.email,
          avatar: undefined,
        },
        entityType: 'job',
        entityId: newNote.jobId,
      };

      // 3. Add to job's timeline
      await jobsRepository.addTimelineEntry(newNote.jobId, timelineEntry);

      return note;
    },
    onMutate: async (newNote) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['jobNotes', jobId] });
      await queryClient.cancelQueries({ queryKey: ['job', jobId] });

      // Snapshot the previous values
      const previousNotes = queryClient.getQueryData(['jobNotes', jobId]) as JobNote[];
      const previousJob = queryClient.getQueryData(['job', jobId]);

      // Create optimistic note
      const optimisticNote: JobNote = {
        ...newNote,
        id: 'temp_' + Date.now(),
        createdAt: new Date().toISOString(),
      };

      // Create optimistic timeline entry
      const optimisticTimelineEntry: NoteTimelineEntry = {
        id: 'temp_' + Date.now(),
        type: 'note',
        timestamp: Timestamp.now(),
        content: newNote.content,
        visibility: 'team',
        createdBy: {
          id: newNote.author.id,
          name: newNote.author.name || newNote.author.email,
          avatar: undefined,
        },
        entityType: 'job',
        entityId: newNote.jobId,
      };

      // Optimistically update notes
      queryClient.setQueryData(['jobNotes', jobId], (old: JobNote[] = []) => [optimisticNote, ...old]);

      // Optimistically update job timeline
      queryClient.setQueryData(['job', jobId], (old: any) => {
        if (!old) return old;
        return {
          ...old,
          timeline: {
            ...old.timeline,
            entries: [optimisticTimelineEntry, ...(old.timeline?.entries || [])],
          },
        };
      });

      return { previousNotes, previousJob };
    },
    onError: (err, newNote, context) => {
      // Rollback on error
      if (context) {
        queryClient.setQueryData(['jobNotes', jobId], context.previousNotes);
        queryClient.setQueryData(['job', jobId], context.previousJob);
      }
    },
    onSettled: () => {
      // Refetch after error or success
      queryClient.invalidateQueries({ queryKey: ['jobNotes', jobId] });
      queryClient.invalidateQueries({ queryKey: ['job', jobId] });
    },
  });

  return {
    notes,
    isLoading,
    error,
    addNote: addNoteMutation.mutate,
    isAddingNote: addNoteMutation.isPending,
    addNoteError: addNoteMutation.error,
  };
};
