import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CandidateJobAssignmentRepository } from '../store/candidateJobAssignment.repository';
import { useCandidateJobAssignmentStore } from '../store/candidateJobAssignments.store';

export const useCandidateJobAssignments = () => {
  console.log('🎯 useCandidateJobAssignments: Initializing hook');

  const { firestore } = useFirebase();
  const { auth } = useFirebase();
  const { user } = useUser(auth.currentUser?.uid);

  useEffect(() => {
    console.log('👤 Auth state:', {
      hasFirestore: !!firestore,
      hasAuth: !!auth,
      user: user ? { email: user.email, id: user.uid } : null,
    });
  }, [firestore, auth, user]);

  const assignmentRepository = useMemo(() => {
    console.log('🏗️ Creating CandidateJobAssignmentRepository');
    return new CandidateJobAssignmentRepository(firestore);
  }, [firestore]);

  const queryClient = useQueryClient();
  const { setAssignments, addAssignment, updateAssignment, removeAssignment } = useCandidateJobAssignmentStore();

  const useAssignmentsByOwner = (ownerId: string) => {
    console.log('🔄 getAssignmentsByOwner: Initializing query for owner:', ownerId);

    const query = useQuery({
      queryKey: ['candidateJobAssignments', { ownerId }],
      queryFn: async () => {
        console.log('📥 Fetching assignments for owner:', ownerId);
        if (!ownerId) {
          console.log('⚠️ No ownerId provided, returning empty array');
          return [];
        }
        const results = await assignmentRepository.getAssignmentsByOwner(ownerId);
        console.log('✅ Fetched assignments:', {
          ownerId,
          count: results.length,
        });
        return results;
      },
      enabled: !!ownerId && ownerId.length > 0,
    });

    useEffect(() => {
      if (query.data) {
        console.log('💾 Updating store with owner assignments:', {
          ownerId,
          count: query.data.length,
        });
        setAssignments(query.data);
      }
    }, [query.data, ownerId]);

    return query;
  };

  const useAssignmentsByJob = (jobId: string) => {
    console.log('🔄 getAssignmentsByJob: Initializing query for job:', jobId);
    return useQuery({
      queryKey: ['candidateJobAssignments', { jobId }],
      queryFn: async () => {
        console.log('📥 Fetching assignments for job:', jobId);
        const results = await assignmentRepository.getAssignmentsByJob(jobId);
        console.log('✅ Fetched assignments:', {
          jobId,
          count: results.length,
        });
        return results;
      },
    });
  };

  const createAssignmentMutation = useMutation({
    mutationFn: async (newAssignment: CandidateJobAssignment) => {
      console.log('📝 Creating new assignment:', newAssignment);
      const result = await assignmentRepository.set(newAssignment.id, newAssignment);
      console.log('✅ Assignment created:', { id: newAssignment.id });
      return result;
    },
    onSuccess: (assignment) => {
      console.log('🔄 Processing successful assignment creation:', assignment);
      addAssignment(assignment);
      console.log('🔄 Invalidating queries');
      queryClient.invalidateQueries({ queryKey: ['candidateJobAssignments'] });
    },
    onError: (error) => {
      console.error('❌ Assignment creation failed:', error);
    },
  });

  const updateAssignmentMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<CandidateJobAssignment> }) => {
      console.log('📝 Updating assignment:', { id, data });
      if (!user?.email) {
        console.error('❌ No user email available for update');
        throw new Error('No user email available');
      }
      await assignmentRepository.upsertAssignment(id, data, user.email);
      console.log('✅ Assignment updated:', { id });
    },
    onSuccess: (_, variables) => {
      console.log('🔄 Processing successful assignment update:', variables);
      updateAssignment(variables.id, variables.data);
      console.log('🔄 Invalidating queries');
      queryClient.invalidateQueries({ queryKey: ['candidateJobAssignments'] });
    },
    onError: (error) => {
      console.error('❌ Assignment update failed:', error);
    },
  });

  const result = {
    useAssignmentsByJob,
    useAssignmentsByOwner,
    createAssignmentMutation,
    updateAssignmentMutation,
    createAssignment: createAssignmentMutation.mutate,
    updateAssignment: updateAssignmentMutation.mutate,
  };

  console.log('✅ useCandidateJobAssignments: Hook initialized successfully');
  return result;
};
