import { BaseRepository } from '../base.repository';
import { Firestore } from 'firebase/firestore';

export interface CollectionSize {
  id: string;
  size: number;
  updatedAt: Date;
}

export class CollectionSizesRepository extends BaseRepository<CollectionSize> {
  constructor(db: Firestore) {
    super(db, 'collectionSizes');
  }
} 