import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { Input } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';

export function LocationColumn({ job }: { job: Job }) {
  const [isEditing, setIsEditing] = useState(false);
  const [location, setLocation] = useState(job.location || '');
  const { updateJobMutation } = useJobs();

  const handleLocationBlur = async () => {
    if (location !== job.location) {
      await updateJobMutation.mutateAsync({
        id: job.id,
        data: { location },
      });
    }
    setIsEditing(false);
  };

  const handleLocationKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleLocationBlur();
    }
  };

  return (
    <td onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }}>
      {isEditing ? (
        <Input
          autoFocus
          onBlur={handleLocationBlur}
          onChange={(e) => setLocation(e.target.value)}
          onKeyDown={handleLocationKeyDown}
          size="sm"
          type="text"
          value={location}
        />
      ) : (
        <Typography level="body-sm">{job.location || 'No location set'}</Typography>
      )}
    </td>
  );
}
