import { RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { Job } from '@bluebird-monorepo/types';
import { Box, Chip, Divider, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function ReviewStep() {
  const { watch } = useFormContext<Job>();
  const values = watch();
  const defaultStyle = { whiteSpace: 'pre-wrap' };

  // Helper function to get label from EntityOption or string
  const getLabel = (item: any) => {
    if (typeof item === 'string') return item;
    return item?.label || item;
  };

  const Section = ({ title, children }: { title: string; children: React.ReactNode }) => (
    <Box sx={{ mb: 3 }}>
      <Typography level="title-md" sx={{ mb: 1 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );

  const RichTextSection = ({ content }: { content: string | undefined }) => {
    if (!content) return null;
    return (
      <Box sx={{ ...defaultStyle }}>
        <RawHTML children={content} />
      </Box>
    );
  };

  return (
    <Stack spacing={2}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Review Job Details
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Please review all information before submitting</Box>
        </Box>
      </Box>

      <Section title="Basic Information">
        <Typography level="body-md">Title: {values.jobTitle}</Typography>
        <Typography level="body-md">Location: {values.location}</Typography>
        <Typography level="body-md">Contract Type: {values.contractType}</Typography>
      </Section>

      <Divider />

      <Section title="Salary & Requirements">
        <Typography level="body-md">
          Salary Range: €{values.salaryRange?.min} - €{values.salaryRange?.max}
        </Typography>
        <Typography level="body-md">Fee: {values.feePercentage}%</Typography>

        {values.preferredLanguages && values.preferredLanguages.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Typography level="body-md">Preferred Languages:</Typography>
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
              {values.preferredLanguages.map((lang: any, index: number) => (
                <Chip key={index} size="sm">
                  {getLabel(lang)}
                </Chip>
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ mt: 1 }}>
          <Typography level="body-md">Offers Relocation: {values.offersRelocation ? 'Yes' : 'No'}</Typography>
          <Typography level="body-md">
            Provides Visa Sponsorship: {values.providesVisaSponsorship ? 'Yes' : 'No'}
          </Typography>
        </Box>
      </Section>

      <Divider />

      <Section title="Requirements & Responsibilities">
        <Typography level="body-md" sx={{ mb: 1 }}>
          Must-Have Requirements:
        </Typography>
        <RichTextSection content={values.mustHaves} />
        <Typography level="body-md" sx={{ mb: 1 }}>
          Tech stack:
        </Typography>
        <RichTextSection content={values.techStack} />

        {values.niceToHaves && (
          <>
            <Typography level="body-md" sx={{ mb: 1, mt: 2 }}>
              Nice-to-Have Requirements:
            </Typography>
            <RichTextSection content={values.niceToHaves} />
          </>
        )}

        <Typography level="body-md" sx={{ mb: 1, mt: 2 }}>
          Key Responsibilities:
        </Typography>
        <RichTextSection content={values.keyResponsibilities} />
      </Section>

      <Divider />

      <Section title="Hiring Process">
        <RichTextSection content={values.hiringProcess} />
      </Section>

      <Section title="About the Company">
        {values.productDescription && <RichTextSection content={values.productDescription} />}
        {values.uniqueAboutCompany && <RichTextSection content={values.uniqueAboutCompany} />}
      </Section>

      <Divider />

      <Section title="Role Description">
        <RichTextSection content={values.roleDescription} />
      </Section>
    </Stack>
  );
}
