import { RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { TimelineEntry as ITimelineEntry } from '@bluebird-monorepo/types';
import { AccessTime, Assignment, Call, Description, Email, Event, Note, Settings } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, Chip, Stack, Typography, styled } from '@mui/joy';
import React from 'react';

const TimelineDot = styled('div')(({ theme }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: theme.vars.palette.primary[500],
  position: 'absolute',
  left: -5,
  top: 24,
}));

const TimelineLine = styled('div')(({ theme }) => ({
  width: 2,
  backgroundColor: theme.vars.palette.divider,
  position: 'absolute',
  left: -1,
  top: 0,
  bottom: 0,
}));

const getIconByType = (type: ITimelineEntry['type']) => {
  switch (type) {
    case 'note':
      return <Note />;
    case 'status_change':
      return <Settings />;
    case 'event':
      return <Event />;
    case 'call':
      return <Call />;
    case 'email':
      return <Email />;
    case 'document':
      return <Description />;
    case 'system_change':
      return <Settings />;
    case 'assignment':
      return <Assignment />;
    default:
      return <AccessTime />;
  }
};

const getTimelineContent = (entry: ITimelineEntry) => {
  switch (entry.type) {
    case 'note':
      return (
        <>
          <RawHTML
            children={entry.content.replace(/<br\s*\/?>/g, ' ')}
            style={{ fontSize: 'var(--joy-fontSize-sm)', lineHeight: 1.6, whiteSpace: 'pre-wrap' }}
          />
          <Chip
            size="sm"
            variant="soft"
            color={entry.visibility === 'private' ? 'warning' : entry.visibility === 'team' ? 'primary' : 'success'}
          >
            {entry.visibility}
          </Chip>
        </>
      );

    case 'status_change':
      return (
        <Stack spacing={1}>
          <Typography level="body-sm">
            Status changed from <Chip size="sm">{entry.oldStatus}</Chip> to <Chip size="sm">{entry.newStatus}</Chip>
          </Typography>
          {entry.reason && (
            <Typography level="body-sm" color="neutral">
              Reason: {entry.reason}
            </Typography>
          )}
        </Stack>
      );

    case 'event':
      return (
        <Stack spacing={1}>
          <Typography level="title-sm">{entry.title}</Typography>
          <Stack direction="row" spacing={2} useFlexGap>
            <Typography level="body-sm">
              {entry.startTime.toLocaleTimeString()} - {entry.endTime.toLocaleTimeString()}
            </Typography>
            <Chip size="sm" variant="soft">
              {entry.eventType}
            </Chip>
          </Stack>
          {entry.location && <Typography level="body-sm">📍 {entry.location}</Typography>}
          {entry.virtualMeetingUrl && (
            <Typography level="body-sm">
              🔗{' '}
              <Typography component="a" href={entry.virtualMeetingUrl} target="_blank">
                Join meeting
              </Typography>
            </Typography>
          )}
          <Stack direction="row" spacing={1} useFlexGap>
            {entry.attendees.map((attendee) => (
              <Chip
                key={attendee.id}
                size="sm"
                color={
                  attendee.status === 'accepted' ? 'success' : attendee.status === 'declined' ? 'danger' : 'neutral'
                }
              >
                {attendee.name}
              </Chip>
            ))}
          </Stack>
        </Stack>
      );

    case 'call':
      return (
        <Stack spacing={1}>
          <Stack direction="row" spacing={2} useFlexGap alignItems="center">
            <Chip size="sm" variant="soft">
              {entry.callType}
            </Chip>
            <Typography level="body-sm">
              {Math.floor(entry.duration / 60)}:{(entry.duration % 60).toString().padStart(2, '0')}
            </Typography>
            {entry.outcome && (
              <Chip
                size="sm"
                color={
                  entry.outcome === 'successful' ? 'success' : entry.outcome === 'unsuccessful' ? 'danger' : 'neutral'
                }
              >
                {entry.outcome}
              </Chip>
            )}
          </Stack>
          {entry.summary && <Typography level="body-sm">{entry.summary}</Typography>}
        </Stack>
      );

    case 'email':
      return (
        <Stack spacing={1}>
          <Typography level="title-sm">{entry.subject}</Typography>
          <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
            {entry.snippet}
          </Typography>
          <Stack direction="row" spacing={1} useFlexGap>
            <Chip size="sm" variant="soft" color={entry.direction === 'inbound' ? 'primary' : 'success'}>
              {entry.direction}
            </Chip>
            {entry.recipients.map((recipient, index) => (
              <Chip key={index} size="sm">
                {recipient}
              </Chip>
            ))}
          </Stack>
        </Stack>
      );

    case 'document':
      return (
        <Stack spacing={1}>
          <Stack direction="row" spacing={2} useFlexGap alignItems="center">
            <Typography level="body-sm">{entry.documentName}</Typography>
            <Chip size="sm" variant="soft">
              {entry.documentType}
            </Chip>
          </Stack>
          {entry.documentUrl && (
            <Typography level="body-sm">
              🔗{' '}
              <Typography component="a" href={entry.documentUrl} target="_blank">
                View document
              </Typography>
            </Typography>
          )}
        </Stack>
      );

    case 'system_change':
      return (
        <Stack spacing={1}>
          {entry.changes.map((change, index) => (
            <Typography key={index} level="body-sm">
              Changed {change.field} from <Chip size="sm">{String(change.oldValue)}</Chip> to{' '}
              <Chip size="sm">{String(change.newValue)}</Chip>
            </Typography>
          ))}
        </Stack>
      );

    case 'assignment':
      return (
        <Stack spacing={1}>
          <Typography level="body-sm">
            {entry.action === 'assigned' ? 'Assigned to' : 'Unassigned from'}{' '}
            {entry.jobTitle ? (
              <Chip size="sm">{entry.jobTitle}</Chip>
            ) : (
              entry.candidateName && <Chip size="sm">{entry.candidateName}</Chip>
            )}
          </Typography>
          {entry.stage && (
            <Typography level="body-sm">
              Stage: <Chip size="sm">{entry.stage}</Chip>
            </Typography>
          )}
        </Stack>
      );

    default:
      return null;
  }
};

export interface TimelineEntryProps {
  entry: ITimelineEntry;
  isLast?: boolean;
}

export const TimelineEntry: React.FC<TimelineEntryProps> = ({ entry, isLast }) => {
  console.log('Timeline Entry:', {
    entry,
    timestamp: entry.timestamp,
    isTimestamp: entry.timestamp?.toDate instanceof Function,
    type: typeof entry.timestamp,
    value: entry.timestamp,
  });

  const formatDate = (timestamp: any) => {
    console.log('🔄 Formatting date:', { timestamp });
    try {
      // Convert Firestore Timestamp to Date
      let date;
      if (timestamp?.toDate instanceof Function) {
        date = timestamp.toDate();
      } else if (timestamp?.seconds && timestamp?.nanoseconds) {
        // Handle Firestore timestamp object with seconds/nanoseconds
        date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
      } else {
        date = new Date(timestamp);
      }

      return new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', { timestamp, error });
      return 'Invalid date';
    }
  };

  return (
    <Box sx={{ position: 'relative', pl: 3, pb: isLast ? 0 : 3 }}>
      {!isLast && <TimelineLine />}
      <TimelineDot />
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar size="sm" src={entry.createdBy.avatar}>
                {entry.createdBy.name.charAt(0)}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography level="title-sm">{entry.createdBy.name}</Typography>
                <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                  {formatDate(entry.timestamp)}
                </Typography>
              </Box>
              <Box sx={{ color: 'neutral.500' }}>{getIconByType(entry.type)}</Box>
            </Stack>
            {getTimelineContent(entry)}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
