import {
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  WhereFilterOp
} from 'firebase/firestore';
import { handleFirebaseError } from '../utils/error-handler';


export abstract class BaseRepository<T extends { id: string }> {
  protected constructor(
    protected readonly db: Firestore,
    protected readonly collectionName: string,
  ) {}

  protected get collectionRef() {
    return collection(this.db, this.collectionName);
  }

  protected docRef(id: string): DocumentReference<T> {
    return doc(this.db, this.collectionName, id).withConverter({
      toFirestore: (data: T) => ({ ...data }),
      fromFirestore: (snap) => ({ id: snap.id, ...snap.data() }) as T,
    });
  }

  protected isTimestamp(value: any): value is Timestamp {
    return (
      value instanceof Timestamp ||
      (typeof value === 'object' && value !== null && 'seconds' in value && 'nanoseconds' in value)
    );
  }

  protected normalizeTimestampValue(value: any): any {
    if (this.isTimestamp(value)) {
      return new Timestamp(value.seconds, value.nanoseconds);
    }
    return value;
  }

  async getAll(queryConstraints: QueryConstraint[] = []): Promise<T[]> {
    try {
      console.log('BaseRepository - getAll:', {
        collection: this.collectionName,
        constraints: queryConstraints,
      });
      const querySnapshot = await getDocs(query(this.collectionRef, ...queryConstraints));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as T[];
    } catch (error) {
      throw handleFirebaseError(error, `Error fetching ${this.collectionName}`);
    }
  }

  async getAllForExtension(queryConstraints: { field: string; op: WhereFilterOp; value: any }[] = []): Promise<T[]> {
    console.log('BaseRepository - getAllForExtension:', {
      collection: this.collectionName,
      constraints: queryConstraints,
    });

    try {
      const constraints = queryConstraints.map(({ field, op, value }) => {
        const normalizedValue = this.normalizeTimestampValue(value);
        console.log('Creating constraint:', { field, op, value: normalizedValue });
        return where(field, op, normalizedValue);
      });

      const querySnapshot = await getDocs(query(this.collectionRef, ...constraints));
      console.log('BaseRepository - Query Results:', {
        empty: querySnapshot.empty,
        size: querySnapshot.size,
        docs: querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      });

      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as T[];
    } catch (error) {
      console.error('BaseRepository - Error in getAllForExtension:', error);
      throw handleFirebaseError(error, `Error fetching ${this.collectionName}`);
    }
  }

  async getById(id: string): Promise<T | null> {
    try {
      const docSnapshot = await getDoc(this.docRef(id));
      if (!docSnapshot.exists()) return null;
      const data = docSnapshot.data();
      return { ...data, id: docSnapshot.id } as T;
    } catch (error) {
      throw handleFirebaseError(error, `Error fetching ${this.collectionName} by id`);
    }
  }

  async create(data: Omit<T, 'id'>): Promise<T> {
    try {
      const docRef = doc(this.collectionRef);
      console.log('DocRef', docRef);
      const cleanData = this.removeUndefinedValues(data);
      console.log('CleanData', cleanData);
      const newData = { ...cleanData, id: docRef.id } as T;
      console.log('NewData', newData);
      await setDoc(docRef, newData);
      return newData;
    } catch (error) {
      throw handleFirebaseError(error, `Error creating ${this.collectionName}`);
    }
  }

  async update(id: string, data: Partial<T>): Promise<void> {
    try {
      const docRef = this.docRef(id);
      const cleanData = this.removeUndefinedValues(data);
      await updateDoc(docRef, cleanData as any, { merge: true });
    } catch (error) {
      console.log(JSON.stringify(error));
      throw handleFirebaseError(error, `Error updating ${this.collectionName}`);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      await deleteDoc(this.docRef(id));
    } catch (error) {
      throw handleFirebaseError(error, `Error deleting ${this.collectionName}`);
    }
  }

  async set(id: string, data: Partial<T>): Promise<T> {
    try {
      const docRef = this.docRef(id);
      const newData = { ...data, id } as T;
      await setDoc(docRef, newData);
      return newData;
    } catch (error) {
      throw handleFirebaseError(error, `Error setting ${this.collectionName}`);
    }
  }

  protected handleError(error: any, message: string): Error {
    console.error(message, error);
    return new Error(message);
  }

  protected async getAllWithNormalizedTimestamps<K extends keyof T>(
    timestampFields: K[],
    queryConstraints: QueryConstraint[] = [],
  ): Promise<T[]> {
    try {
      console.log('BaseRepository - getAllWithNormalizedTimestamps:', {
        collection: this.collectionName,
        timestampFields,
        constraints: queryConstraints,
      });

      const querySnapshot = await getDocs(query(this.collectionRef, ...queryConstraints));

      return querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const normalizedData = { ...data, id: doc.id } as T;

        timestampFields.forEach((field) => {
          if (field in normalizedData) {
            normalizedData[field] = this.normalizeTimestampValue(normalizedData[field]);
          }
        });

        return normalizedData;
      });
    } catch (error) {
      throw handleFirebaseError(error, `Error fetching ${this.collectionName}`);
    }
  }

  /**
   * Recursively removes keys with undefined values from an object
   * @param obj The object to clean
   * @returns A new object with all undefined values removed
   */
  private removeUndefinedValues<T extends Record<string, any>>(obj: T): Partial<T> {
    const result: Partial<T> = {};

    Object.entries(obj).forEach(([key, value]) => {
      // Skip undefined values
      if (value === undefined) {
        return;
      }

      // Recursively clean nested objects
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        result[key as keyof T] = this.removeUndefinedValues(value) as T[keyof T];
      }
      // Handle arrays by filtering out undefined values and recursively cleaning objects
      else if (Array.isArray(value)) {
        result[key as keyof T] = value
          .filter((item) => item !== undefined)
          .map((item) => (item && typeof item === 'object' ? this.removeUndefinedValues(item) : item)) as T[keyof T];
      }
      // Keep non-undefined primitive values
      else {
        result[key as keyof T] = value;
      }
    });

    return result;
  }
}
