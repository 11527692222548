import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { CalendarEvent, FirestoreCalendarEvent } from '@bluebird-monorepo/types';
import { Firestore, serverTimestamp, Timestamp } from 'firebase/firestore';

export class CalendarRepository extends BaseRepository<FirestoreCalendarEvent> {
  constructor(db: Firestore) {
    super(db, 'calendarEvents');
  }

  private convertToCalendarEvent(event: FirestoreCalendarEvent): CalendarEvent {
    return {
      ...event,
      when: {
        startTime: event.when.startTime.toMillis(),
        endTime: event.when.endTime.toMillis(),
      },
    };
  }

  private convertToFirestoreEvent(event: Partial<CalendarEvent>): Partial<FirestoreCalendarEvent> {
    if (!event.when) return event as Partial<FirestoreCalendarEvent>;
    return {
      ...event,
      when: {
        startTime: Timestamp.fromMillis(event.when.startTime),
        endTime: Timestamp.fromMillis(event.when.endTime),
      },
    } as Partial<FirestoreCalendarEvent>;
  }

  async getFirestoreEvents(startDate: Date, endDate: Date, userId: string): Promise<CalendarEvent[]> {
    const startTimestamp = Timestamp.fromDate(new Date(startDate));
    const endTimestamp = Timestamp.fromDate(new Date(endDate));

    const events = await this.getAllForExtension([
      { field: 'when.startTime', op: '>=', value: startTimestamp },
      { field: 'when.startTime', op: '<=', value: endTimestamp },
      { field: 'userId', op: '==', value: userId },
    ]);

    return events.map((event) => this.convertToCalendarEvent(event));
  }

  async getFirestoreEventsByJobId(jobId: string): Promise<CalendarEvent[]> {
    const events = await this.getAllForExtension([{ field: 'jobId', op: '==', value: jobId }]);
    return events.map((event) => this.convertToCalendarEvent(event));
  }

  async upsertEvent(id: string, event: Partial<CalendarEvent>): Promise<CalendarEvent> {
    const firestoreEvent = this.convertToFirestoreEvent(event);
    const cleanEvent = {
      ...firestoreEvent,
      updatedAt: serverTimestamp(),
    };

    delete cleanEvent.when;

    if (event.id) {
      await this.update(id, cleanEvent);
      const updatedEvent = await this.getById(id);
      if (!updatedEvent) throw new Error('Failed to get updated event');
      return this.convertToCalendarEvent(updatedEvent);
    } else {
      const created = await this.create(cleanEvent as Omit<FirestoreCalendarEvent, 'id'>);
      return this.convertToCalendarEvent(created);
    }
  }

  async getJobEventStats(jobId: string): Promise<{ total: number; scheduled: number }> {
    const events = await this.getAllForExtension([{ field: 'jobId', op: '==', value: jobId }]);

    const now = Date.now();
    let scheduled = 0;
    const total = events.length;

    events.forEach((event) => {
      if (event.when.startTime.toMillis() > now) {
        scheduled++;
      }
    });

    return { total, scheduled };
  }
}
