import { IntroductionModal, useIntroduction } from '@bluebird-monorepo/candidates';
import { useJobs } from '@bluebird-monorepo/jobs';
import { NoteTimelineEntry } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import type { DragStart, DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Timestamp } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelectionStore } from '../store/useSelectionStore';
import { KanbanColumn, Stage } from '../types';
import { reorderCards } from '../utils/reorder';
import Column from './Column';

interface KanbanBoardProps {
  isCombineEnabled?: boolean;
  onStatusUpdate?: (candidateId: string, statusId: number, status: string, jobId: string) => void;
  stages?: Stage[];
  useClone?: boolean;
  withScrollableColumns?: boolean;
  initial: KanbanColumn;
  onIntroductionNeeded?: (candidateId: string, jobId: string) => void;
}

export const KanbanBoard: React.FC<KanbanBoardProps> = React.memo(
  ({
    isCombineEnabled,
    onStatusUpdate,
    stages = [],
    useClone,
    withScrollableColumns,
    initial,
    onIntroductionNeeded,
  }) => {
    console.log('🔍 KanbanBoard Initial Props:', {
      isCombineEnabled,
      stages,
      useClone,
      withScrollableColumns,
      initial,
    });

    const [columns, setColumns] = useState<KanbanColumn>(initial || {});
    const [isDraggingSelected, setIsDraggingSelected] = useState(false);
    const [showIntroModal, setShowIntroModal] = useState(false);
    const [introModalData, setIntroModalData] = useState<{ candidateId: string; jobId: string } | null>(null);
    const { createIntroduction } = useIntroduction(introModalData?.candidateId || '', introModalData?.jobId || '');
    const { addTimelineEntryMutation } = useJobs();
    const { loggedInUser } = useUser();

    console.log('🔍 KanbanBoard State:', {
      columns,
      isDraggingSelected,
    });

    // Update columns when initial prop changes
    useEffect(() => {
      console.log('🔄 Updating columns with new initial data');
      setColumns(initial || {});
    }, [initial]);

    // Memoize ordered stages
    const ordered = useMemo(() => {
      console.log('📊 Computing ordered stages');
      return stages
        .sort((a, b) => {
          console.log('📊 Sorting stages:', { a, b, orderA: a.order, orderB: b.order });
          return a.order - b.order;
        })
        .map((stage) => {
          console.log('🔄 Mapping stage to ID:', stage);
          return stage.id;
        });
    }, [stages]);

    // Memoize complete columns
    const completeColumns = useMemo(() => {
      console.log('🔄 Computing complete columns');
      const complete = { ...columns };
      stages.forEach((stage) => {
        if (!complete[stage.id]) {
          console.log('➕ Adding empty column for stage:', stage.id);
          complete[stage.id] = [];
        }
      });
      return complete;
    }, [columns, stages]);

    const camelize = useCallback((str: string) => {
      console.log('🔄 Camelizing string:', str);
      const result = str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
        .replace(/\s+/g, '');
      console.log('✅ Camelized result:', result);
      return result;
    }, []);

    const handleDragStart = useCallback((initial: DragStart) => {
      console.log('🎯 Drag Start:', initial);
      const selectedItems = useSelectionStore.getState().selectedItems;
      console.log('🔍 Selected Items:', selectedItems);

      const id = initial.draggableId.split('&&').reduce((acc, curr) => {
        const [key, value] = curr.split(':');
        console.log('🔄 Processing drag ID part:', { key, value, currentAcc: acc });
        return key === 'jobId' ? `${value}_${acc}` : `${acc}${value}`;
      }, '');

      console.log('✅ Final drag ID:', id);
      setIsDraggingSelected(selectedItems.has(id));
    }, []);

    const handleDragEnd = useCallback(
      (result: DropResult) => {
        console.log('🎯 Drag End:', result);
        setIsDraggingSelected(false);

        const selectedItems = useSelectionStore.getState().selectedItems;
        const destination = result.destination;
        const source = result.source;

        if (!destination) {
          console.log('⚠️ No destination, cancelling drag');
          return;
        }

        if (source.droppableId === destination.droppableId && source.index === destination.index) {
          console.log('ℹ️ Item dropped in same position, no action needed');
          return;
        }

        // Handle multi-drag
        if (isDraggingSelected && selectedItems.size > 1) {
          console.log('🔄 Processing multi-drag');
          const tempDestination = camelize(destination.droppableId);
          const stageId: string = tempDestination?.charAt(0)?.toUpperCase() + tempDestination?.slice(1) || '';
          const stage = stages?.find((s) => s.id == stageId);
          const statusId = Number(stage?.id);

          console.log('📊 Multi-drag details:', {
            tempDestination,
            stageId,
            stage,
            statusId,
            selectedItemsCount: selectedItems.size,
          });

          selectedItems.forEach((itemId) => {
            const [itemJobId, itemCandidateId] = itemId.split('_');
            console.log('🔄 Updating item:', { itemJobId, itemCandidateId, statusId, stageName: stage?.name });
            onStatusUpdate?.(itemCandidateId, statusId, stage?.name || '', itemJobId);
          });

          const data = reorderCards({
            destination,
            source,
            cardMap: columns,
            selectedCardIds: selectedItems,
          });

          console.log('✅ Multi-drag reorder complete:', data);
          setColumns(data.cardMap);
          return;
        }

        // Handle single drag
        console.log('🔄 Processing single drag');
        const draggedItemId = result.draggableId.split('&&').reduce((acc, curr) => {
          const [key, value] = curr.split(':');
          return key === 'jobId' ? `${value}_${acc}` : `${acc}${value}`;
        }, '');

        const [jobId, candidateId] = draggedItemId.split('_');
        const tempDestination = camelize(destination.droppableId);
        const stageId: string = tempDestination?.charAt(0)?.toUpperCase() + tempDestination?.slice(1) || '';
        const stage = stages?.find((s) => s.id == stageId);
        const statusId = Number(stage?.id);

        console.log('📊 Single drag details:', {
          draggedItemId,
          jobId,
          candidateId,
          tempDestination,
          stageId,
          stage,
          statusId,
        });

        // Check if the destination is "Introduced to Client"
        if (stage?.name === 'Introduced to Client') {
          setIntroModalData({ candidateId, jobId });
          setShowIntroModal(true);
          return;
        }

        onStatusUpdate?.(candidateId, statusId, stage?.name || '', jobId);

        const data = reorderCards({
          destination,
          source,
          cardMap: columns,
        });

        console.log('✅ Single drag reorder complete:', data);
        setColumns(data.cardMap);
      },
      [columns, isDraggingSelected, stages, onStatusUpdate, camelize],
    );

    const handleIntroModalClose = useCallback(() => {
      setShowIntroModal(false);
      setIntroModalData(null);
    }, []);

    const handleIntroModalSave = useCallback(
      async (content: string) => {
        if (!introModalData) return;

        const { candidateId, jobId } = introModalData;
        const stage = stages.find((s) => s.name === 'Introduced to Client');
        if (!stage) return;

        try {
          // First create the introduction
          await createIntroduction(content);

          // Then update the status
          await onStatusUpdate?.(candidateId, Number(stage.id), stage.name, jobId);

          // Add timeline entry
          const timelineEntry: Omit<NoteTimelineEntry, 'id'> = {
            type: 'note',
            content,
            visibility: 'team',
            timestamp: Timestamp.now(),
            createdBy: {
              id: loggedInUser?.id || '',
              name: loggedInUser?.displayName || loggedInUser?.email || '',
              avatar: loggedInUser?.photoURL,
            },
            entityType: 'job',
            entityId: jobId,
          };

          await addTimelineEntryMutation.mutateAsync({
            jobId,
            entry: timelineEntry,
          });

          // Update columns
          const source = Object.entries(columns).find(([_, cards]) =>
            cards.some((card) => card.candidateId === candidateId && card.jobId === jobId),
          );

          if (source) {
            const [sourceId] = source;
            const data = reorderCards({
              source: { droppableId: sourceId, index: 0 },
              destination: { droppableId: stage.id, index: 0 },
              cardMap: columns,
            });

            setColumns(data.cardMap);
          }

          handleIntroModalClose();
        } catch (error) {
          console.error('Failed to save introduction:', error);
          // TODO: Add error handling UI
        }
      },
      [
        columns,
        stages,
        onStatusUpdate,
        handleIntroModalClose,
        introModalData,
        createIntroduction,
        addTimelineEntryMutation,
        loggedInUser,
      ],
    );

    console.log('🎨 KanbanBoard Render:', {
      columns,
      stages,
      ordered,
      completeColumns,
    });

    return (
      <>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  padding: '1rem',
                }}
              >
                {ordered.map((stageId: string, index: number) => {
                  console.log('🔄 Rendering column for stage:', stageId);
                  const stage = stages?.find((s) => s.id === stageId);
                  return (
                    <Column
                      key={stageId}
                      cards={completeColumns[stageId] || []}
                      stage={stage}
                      title={stage?.name || ''}
                      useClone={useClone}
                      isDraggingSelected={isDraggingSelected}
                      isCombineEnabled={isCombineEnabled}
                      isScrollable={withScrollableColumns}
                      index={index}
                      bgColor={stage?.color || '#ddd'}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {showIntroModal && introModalData && (
          <IntroductionModal
            isOpen={showIntroModal}
            onClose={handleIntroModalClose}
            onSave={handleIntroModalSave}
            candidateId={introModalData.candidateId}
            jobId={introModalData.jobId}
          />
        )}
      </>
    );
  },
);

KanbanBoard.displayName = 'KanbanBoard';

export default KanbanBoard;
