import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useJobs } from '@bluebird-monorepo/jobs';
import { useUser } from '@bluebird-monorepo/users';
import { Search } from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Button, Card, Grid, Input, Sheet, Stack, Typography } from '@mui/joy';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../layout/Layout';

const responsibleMapping = {
  Dennis: {
    colorClass: 'mint-green',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U05QQC9SJ1W-8fccbf482c28-512',
    name: 'Dennis',
  },
  Jacob: {
    colorClass: 'blush-pink',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U06T7S16K6W-ab5ce9b172f2-192',
    name: 'Jacob',
  },
  Mitchel: {
    colorClass: 'pale-lavender',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U07RZ07JSDP-d3370936361e-192',
    name: 'Mitchel',
  },
  Pontus: {
    colorClass: 'powder-blue',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U075S4N9M6V-dec8ff679f17-192',
    name: 'Pontus',
  },
} as const;

type ResponsibleName = keyof typeof responsibleMapping;

const DashboardPage: React.FC = () => {
  const { auth } = useFirebase();
  const { user } = useUser(auth?.currentUser?.uid);
  const { users } = useUser();
  const { jobs } = useJobs();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());

  // Filter team members to only show user's team
  const teamMembers = useMemo(() => {
    if (!user?.teamId) return [];
    return users?.filter((u) => u.teamId === user.teamId) || [];
  }, [users, user?.teamId]);

  // Get jobs where user is responsible or working on
  const userJobs = useMemo(() => {
    if (!user?.displayName) return [];
    const userName = Object.keys(responsibleMapping).find((name) => name === user.displayName);
    if (!userName) return [];
    return jobs
      .filter((job) => {
        const isResponsible = job.responsible === userName;
        const isWorkingOn = job.workingOn?.includes(userName);
        return isResponsible || isWorkingOn;
      })
      .slice(0, 5);
  }, [jobs, user?.displayName]);

  // Get recent notes from jobs with author information
  const recentNotes = useMemo(() => {
    return jobs
      .filter((job) => job.notes && job.notes.length > 0)
      .flatMap((job) =>
        (job.notes || []).map((note) => {
          const author = users?.find((u) => u.email === note.created_by);
          return {
            ...note,
            jobTitle: job.jobTitle,
            jobId: job.id,
            author,
          };
        }),
      )
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .slice(0, 5);
  }, [jobs, users]);

  const handleJobClick = (jobId: string) => {
    navigate(`/jobs/${jobId}`);
  };

  return (
    <Layout title="Today's agenda" subtitle="Welcome to Bluehire!">
      {/* Search Bar */}
      <Box sx={{ mb: 3 }}>
        <Input
          size="lg"
          placeholder="Search for candidates, jobs, or companies"
          startDecorator={<Search />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            '--Input-focusedThickness': '2px',
            '--Input-placeholderOpacity': 0.5,
            '--Input-decoratorChildHeight': '24px',
          }}
        />
      </Box>

      {/* Main Content Grid */}
      <Grid container spacing={2}>
        {/* Calendar Section */}
        <Grid xs={12} md={6}>
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'sm',
              p: 2,
              height: '100%',
              bgcolor: 'background.surface',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue || dayjs())}
                sx={{
                  width: '100%',
                  '& .MuiPickersCalendarHeader-root': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  },
                }}
              />
            </LocalizationProvider>
          </Sheet>
        </Grid>

        {/* Urgent Tasks Section */}
        <Grid xs={12} md={6}>
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'sm',
              p: 2,
              height: '100%',
              bgcolor: 'background.surface',
            }}
          >
            <Typography level="h4" sx={{ mb: 2 }}>
              Urgent tasks (placeholders)
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
              {['Finish monthly reporting', 'Report signing', 'Market overview keynote'].map((task) => (
                <Box
                  key={task}
                  component="li"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 1,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: 'primary.solidBg',
                      }}
                    />
                    <Typography>{task}</Typography>
                  </Box>
                  <Typography level="body-sm">Today</Typography>
                </Box>
              ))}
            </Box>
          </Sheet>
        </Grid>

        {/* Your Jobs Section */}
        <Grid xs={12} md={6}>
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'sm',
              p: 2,
              height: '100%',
              bgcolor: 'background.surface',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography level="h4">Your jobs</Typography>
              <Button
                variant="outlined"
                color="neutral"
                size="sm"
                onClick={() => navigate('/jobs')}
                sx={{ whiteSpace: 'nowrap' }}
              >
                View all
              </Button>
            </Box>
            <Stack spacing={1}>
              {userJobs.map((job) => (
                <Card
                  key={job.id}
                  variant="soft"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'background.level1' },
                  }}
                  onClick={() => handleJobClick(job.id)}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box>
                      <Typography level="title-sm">{job.jobTitle}</Typography>
                      <Typography level="body-sm" textColor="text.secondary">
                        {job.companyName}
                      </Typography>
                      <Typography level="body-xs" textColor="text.tertiary" sx={{ mt: 0.5 }}>
                        {job.responsible === user?.displayName ? 'Responsible' : 'Working on'}
                      </Typography>
                    </Box>
                    <AvatarGroup
                      size="sm"
                      sx={{
                        '--AvatarGroup-gap': '-8px',
                        '--Avatar-size': '24px',
                      }}
                    >
                      {job.workingOn?.map((name) => {
                        const person = responsibleMapping[name as ResponsibleName];
                        return person ? (
                          <Avatar key={name} src={person.imgUrl} alt={person.name}>
                            {person.name[0]}
                          </Avatar>
                        ) : null;
                      })}
                    </AvatarGroup>
                  </Box>
                </Card>
              ))}
              {userJobs.length === 0 && (
                <Typography level="body-sm" textAlign="center">
                  No jobs assigned to you
                </Typography>
              )}
            </Stack>
          </Sheet>
        </Grid>

        {/* Recent Notes Section */}
        <Grid xs={12} md={6}>
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'sm',
              p: 2,
              height: '100%',
              bgcolor: 'background.surface',
            }}
          >
            <Typography level="h4" sx={{ mb: 2 }}>
              Recent notes
            </Typography>
            <Stack spacing={1}>
              {/* {recentNotes.map((note) => (
                <Card
                  key={note.id}
                  variant="soft"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'background.level1' },
                  }}
                  onClick={() => handleJobClick(note.jobId)}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Avatar src={note.author?.photoURL} size="sm">
                        {note.author?.displayName?.[0] || note.created_by[0]}
                      </Avatar>
                      <Box>
                        <Typography level="title-sm">{note.jobTitle}</Typography>
                        <Typography level="body-xs" textColor="text.tertiary">
                          {note.author?.displayName || note.created_by} •{' '}
                          {new Date(note.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      level="body-sm"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {note.content}
                    </Typography>
                  </Box>
                </Card>
              ))}
              {recentNotes.length === 0 && ( */}
              <Typography level="body-sm" textAlign="center">
                No recent notes
              </Typography>
            </Stack>
          </Sheet>
        </Grid>

        {/* Team Directory Section */}
        <Grid xs={12}>
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'sm',
              p: 2,
              height: '100%',
              bgcolor: 'background.surface',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography level="h4">Team directory</Typography>
              <Button
                variant="outlined"
                color="neutral"
                size="sm"
                onClick={() => navigate('/users')}
                sx={{ whiteSpace: 'nowrap' }}
              >
                View all
              </Button>
            </Box>
            <Grid container spacing={2}>
              {/* {teamMembers.map((teamMember) => (
                <Grid key={teamMember.id} xs={12} sm={6} md={3}>
                  <Card variant="soft">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar src={teamMember.photoURL} alt={teamMember.displayName}>
                        {teamMember.displayName?.[0] || teamMember.email[0]}
                      </Avatar>
                      <Box>
                        <Typography level="title-sm">{teamMember.displayName || teamMember.email}</Typography>
                        <Typography level="body-xs" textColor="text.tertiary">
                          {teamMember.title || teamMember.role || 'Team Member'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              ))}
              {teamMembers.length === 0 && ( */}
              <Grid xs={12}>
                <Typography level="body-sm" textAlign="center">
                  No team members found
                </Typography>
              </Grid>
            </Grid>
          </Sheet>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default DashboardPage;
