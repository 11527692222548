import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Job, TimelineEntry } from '@bluebird-monorepo/types';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { JobsRepository } from '../store/job.repository';
import { useJobsStore } from '../store/jobs.store';
import { ResponsiblesRepository } from '../store/responsible.repository';
import { StatusRepository } from '../store/status.repository';

export const useJobs = (jobId?: string) => {
  const { firestore } = useFirebase();
  if (!firestore) throw new Error('Firestore not initialized');

  const repositories = useMemo(
    () => ({
      jobsRepository: new JobsRepository(firestore),
      responsiblesRepository: new ResponsiblesRepository(firestore),
      statusRepository: new StatusRepository(firestore),
    }),
    [firestore],
  );

  const queryClient = useQueryClient();
  const { setJobs, addJob, setResponsibles, setStatuses } = useJobsStore();

  // Fetch single job if jobId is provided
  const jobQuery = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => repositories.jobsRepository.getById(jobId!),
    enabled: !!jobId,
  });

  const [jobsQuery, responsiblesQuery, statusesQuery] = useQueries({
    queries: [
      {
        queryKey: ['jobs'],
        queryFn: () => repositories.jobsRepository.getAll(),
        staleTime: 5 * 60 * 1000, // 5 minutes
      },
      {
        queryKey: ['responsibles'],
        queryFn: () => repositories.responsiblesRepository.getAll(),
        staleTime: 30 * 60 * 1000, // 30 minutes
      },
      {
        queryKey: ['statuses'],
        queryFn: () => repositories.statusRepository.getAll(),
        staleTime: 30 * 60 * 1000, // 30 minutes
      },
    ],
  });

  // Single effect to batch state updates
  React.useEffect(() => {
    if (jobsQuery.data && responsiblesQuery.data && statusesQuery.data) {
      // Batch updates in a single state update
      queueMicrotask(() => {
        setJobs(jobsQuery.data);
        setResponsibles(responsiblesQuery.data);
        setStatuses(statusesQuery.data);
      });
    }
  }, [jobsQuery.data, responsiblesQuery.data, statusesQuery.data, setJobs, setResponsibles, setStatuses]);

  const createJobMutation = useMutation({
    mutationFn: (newJob: Omit<Job, 'id'>) => repositories.jobsRepository.create(newJob),
    onSuccess: (job) => {
      addJob(job);
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
  });

  const updateJobMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Job> }) => repositories.jobsRepository.update(id, data),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      queryClient.invalidateQueries({ queryKey: ['job', id] });
    },
  });

  const addTimelineEntryMutation = useMutation({
    mutationFn: ({ jobId, entry }: { jobId: string; entry: Omit<TimelineEntry, 'id'> }) =>
      repositories.jobsRepository.addTimelineEntry(jobId, entry),
    onSuccess: (_, { jobId }) => {
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      queryClient.invalidateQueries({ queryKey: ['job', jobId] });
    },
  });

  return {
    jobs: jobsQuery.data ?? [],
    job: jobId ? jobQuery.data : null,
    responsibles: responsiblesQuery.data ?? [],
    statuses: statusesQuery.data ?? [],
    isLoading: jobId ? jobQuery.isPending : [jobsQuery, responsiblesQuery, statusesQuery].some((q) => q.isPending),
    error: jobId ? jobQuery.error : [jobsQuery, responsiblesQuery, statusesQuery].find((q) => q.error)?.error,
    createJobMutation,
    updateJobMutation,
    addTimelineEntryMutation,
  };
};
