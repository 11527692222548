import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Company } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CompanyRepository } from '../repositories/company.repository';
import { useCompanyStore } from '../store/companies.store';

export const useCompanySearch = (
  searchTerm: string,
  sortField?: string,
  sortDirection: 'asc' | 'desc' = 'asc',
  prefixSearchOnly = false
) => {
  const { firestore } = useFirebase();
  
  // Add debugging logs
  console.group('useCompanySearch Debug');
  console.log('Firestore instance:', firestore);
  console.log('Firestore constructor:', firestore?.constructor?.name);
  console.log('Firestore type:', Object.prototype.toString.call(firestore));
  console.groupEnd();

  if (!firestore) throw new Error('Firestore not initialized');
  
  const companyRepository = new CompanyRepository(firestore);
  const queryClient = useQueryClient();

  const { data: companies, isLoading } = useQuery({
    queryKey: ['companies', 'initial', sortField, sortDirection],
    queryFn: async () => {
      if (sortField) {
        return await companyRepository.getBySorting(sortField, sortDirection);
      }
      return await companyRepository.getNextBatch(null);
    },
    enabled: !prefixSearchOnly,
  });

  const loadedCompanies = companies || [];
  
  const hasMatchingPrefix = useMemo(() => {
    if (!searchTerm) return true;
    const searchTermLower = searchTerm.toLowerCase();
    
    return loadedCompanies.some(company => 
      (company.name || '').toLowerCase().startsWith(searchTermLower)
    );
  }, [loadedCompanies, searchTerm]);

  const prefixSearchQuery = useQuery({
    queryKey: ['companies', 'prefix-search', searchTerm],
    queryFn: () => companyRepository.getByNamePrefix(searchTerm),
    enabled: !!searchTerm,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const results = useMemo(() => {
    if (prefixSearchOnly) {
      if (!searchTerm) return [];
      return prefixSearchQuery.data || [];
    }

    if (!searchTerm) return loadedCompanies;

    const searchTermLower = searchTerm.toLowerCase();
    const results = new Map<string, { company: Company; matchType: number }>();
    
    const getMatchType = (company: Company): number => {
      const nameLower = (company.name || '').toLowerCase();
      
      // Exact match
      if (nameLower === searchTermLower) return 0;
      
      // Starts with search term
      if (nameLower.startsWith(searchTermLower)) return 1;
      
      // Contains search term as a whole word
      const regex = new RegExp(`\\b${searchTermLower}\\b`, 'i');
      if (regex.test(nameLower)) return 2;
      
      // Contains search term anywhere
      if (nameLower.includes(searchTermLower)) return 3;
      
      return 4;
    };

    loadedCompanies.forEach(company => {
      const matchType = getMatchType(company);
      if (matchType < 4) {
        results.set(company.id, { company, matchType });
      }
    });
    
    if (prefixSearchQuery.data) {
      prefixSearchQuery.data.forEach(company => {
        if (!results.has(company.id)) {
          const matchType = getMatchType(company);
          if (matchType < 4) {
            results.set(company.id, { company, matchType });
          }
        }
      });
    }

    return Array.from(results.values())
      .sort((a, b) => {
        if (a.matchType !== b.matchType) {
          return a.matchType - b.matchType;
        }
        return (a.company.name || '').localeCompare(b.company.name || '');
      })
      .map(item => item.company);
  }, [loadedCompanies, prefixSearchQuery.data, searchTerm, prefixSearchOnly]);

  return {
    results,
    isLoading: (!prefixSearchOnly && isLoading) || prefixSearchQuery.isLoading,
    error: prefixSearchQuery.error
  };
};

export const useCompanies = (
  companyId?: string,
  sortField = 'name',
  sortDirection: 'asc' | 'desc' = 'asc'
) => {
  const { firestore } = useFirebase();
  if (!firestore) throw new Error('Firestore not initialized');
  
  const companyRepository = new CompanyRepository(firestore);
  const queryClient = useQueryClient();
  const { 
    setCompanies,
    addCompany, 
    updateCompany, 
    removeCompany,
    setTotalCompanies,
  } = useCompanyStore();

  const { data, isLoading, error } = useQuery({
    queryKey: ['companies', companyId ? 'single' : 'sorted', companyId, sortField, sortDirection],
    queryFn: async () => {
      if (companyId) {
        const company = await companyRepository.getById(companyId);
        return company ? [company] : [];
      }
      return await companyRepository.getNextBatch(null);
    },
  });

  useEffect(() => {
    if (data) {
      setCompanies(data);
      setTotalCompanies(data.length);
    }
  }, [data, setCompanies, setTotalCompanies]);

  const createCompanyMutation = useMutation({
    mutationFn: (newCompany: Omit<Company, 'id'>) => companyRepository.create(newCompany),
    onSuccess: (company) => {
      addCompany(company);
      queryClient.invalidateQueries({ queryKey: ['companies'] });
    },
  });

  const updateCompanyMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Company> }) => companyRepository.update(id, data),
    onSuccess: (_, variables) => {
      updateCompany(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['companies'] });
    },
  });

  const deleteCompanyMutation = useMutation({
    mutationFn: (id: string) => companyRepository.delete(id),
    onSuccess: (_, id) => {
      removeCompany(id);
      queryClient.invalidateQueries({ queryKey: ['companies'] });
    },
  });

  return {
    companies: data || [],
    isLoading,
    error,
    createCompany: createCompanyMutation.mutate,
    updateCompany: updateCompanyMutation.mutate,
    deleteCompany: deleteCompanyMutation.mutate,
  };
};