import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { CalendarRepository } from '@bluebird-monorepo/calendar';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useJobEvents = (jobId: string) => {
  const { firestore: db } = useFirebase();
  const calendarRepository = useMemo(() => new CalendarRepository(db), [db]);

  const {
    data: events,
    isLoading: eventsLoading,
    refetch: refetchEvents,
  } = useQuery({
    queryKey: ['job-events', jobId],
    queryFn: async () => {
      const events = await calendarRepository.getFirestoreEventsByJobId(jobId);
      return events;
    },
    enabled: !!jobId,
  });

  const {
    data: stats,
    isLoading: statsLoading,
    refetch: refetchStats,
  } = useQuery({
    queryKey: ['job-events-stats', jobId],
    queryFn: async () => {
      const stats = await calendarRepository.getJobEventStats(jobId);
      return stats;
    },
    enabled: !!jobId,
  });

  const refetch = async () => {
    await Promise.all([refetchEvents(), refetchStats()]);
  };

  return {
    events: events || [],
    stats,
    isLoading: eventsLoading || statsLoading,
    refetch,
  };
};
