import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore, QueryConstraint, where } from 'firebase/firestore';

export interface JobNote {
  id: string;
  jobId: string;
  content: string;
  author: {
    id: string;
    email: string;
    name: string | null;
  };
  createdAt: string;
}

export class JobNotesRepository extends BaseRepository<JobNote> {
  constructor(db: Firestore) {
    super(db, 'jobNotes');
  }

  async getNotesByJobId(jobId: string): Promise<JobNote[]> {
    try {
      const constraints: QueryConstraint[] = [where('jobId', '==', jobId)];
      return this.getAll(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching notes by job ID');
    }
  }
}
