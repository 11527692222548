import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, CardContent, IconButton, Menu, MenuItem, Typography } from '@mui/joy';
import { FC, useCallback, useState } from 'react';

import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { CandidateAssignmentSection } from '@bluebird-monorepo/candidateJobAssignments';
import { Job } from '@bluebird-monorepo/types';
import { Close, Visibility } from '@mui/icons-material';
import { JobInfoCard } from './jobInfo';
import { SidePanels } from './sidePanels';

type SectionKey =
  | 'mustHaves'
  | 'techStack'
  | 'niceToHaves'
  | 'keyResponsibilities'
  | 'idealCandidateProfile'
  | 'hiringProcess'
  | 'howToIntro';

type CardVisibility = {
  jobInfo: boolean;
  externalDescription: boolean;
  candidateAssignment: boolean;
};

type SectionVisibility = Record<SectionKey, boolean>;

const cardNames: Record<keyof CardVisibility, string> = {
  jobInfo: 'Job Information',
  externalDescription: 'External Description',
  candidateAssignment: 'Candidate Assignment',
};

const sectionNames: Record<SectionKey, string> = {
  mustHaves: 'Must Haves',
  techStack: 'Tech Stack',
  niceToHaves: 'Nice to Haves',
  keyResponsibilities: 'Key Responsibilities',
  idealCandidateProfile: 'Candidate Profile',
  hiringProcess: 'Hiring Process',
  howToIntro: 'How to Intro',
};

type HiddenItem = {
  key: keyof CardVisibility | SectionKey;
  name: string;
  type: 'card' | 'section';
};

const GeneralTab: FC<{ editableJob: Job }> = ({ editableJob: job }) => {
  const [visibleCards, setVisibleCards] = useState<CardVisibility>(() => ({
    jobInfo: true,
    externalDescription: true,
    candidateAssignment: true,
  }));
  const [visibleSections, setVisibleSections] = useState<SectionVisibility>(() => ({
    mustHaves: true,
    techStack: true,
    niceToHaves: true,
    keyResponsibilities: true,
    idealCandidateProfile: true,
    hiringProcess: true,
    howToIntro: true,
  }));
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  if (!job) return null;

  const toggleCard = useCallback((cardKey: keyof CardVisibility) => {
    setVisibleCards((prev) => ({
      ...prev,
      [cardKey]: !prev[cardKey],
    }));
  }, []);

  const handleSectionHide = useCallback((sectionKey: SectionKey) => {
    setVisibleSections((prev) => ({
      ...prev,
      [sectionKey]: false,
    }));
  }, []);

  const handleMenuToggle = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchor((prev) => (prev ? null : event.currentTarget));
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (item: HiddenItem) => {
      if (item.type === 'card') {
        toggleCard(item.key as keyof CardVisibility);
      } else {
        setVisibleSections((prev) => ({
          ...prev,
          [item.key]: true,
        }));
      }
      handleMenuClose();
    },
    [toggleCard, handleMenuClose],
  );

  const visibleCardCount = Object.values(visibleCards).filter(Boolean).length;
  const hiddenItems: HiddenItem[] = [
    ...Object.entries(visibleCards)
      .filter(([_, isVisible]) => !isVisible)
      .map(([key]) => ({
        key: key as keyof CardVisibility,
        name: cardNames[key as keyof CardVisibility],
        type: 'card' as const,
      })),
    ...Object.entries(visibleSections)
      .filter(([_, isVisible]) => !isVisible)
      .map(([key]) => ({
        key: key as SectionKey,
        name: sectionNames[key as SectionKey],
        type: 'section' as const,
      })),
  ];

  const renderCard = (cardKey: keyof CardVisibility, content: React.ReactNode) => {
    if (!visibleCards[cardKey]) return null;

    return (
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 2,
            backgroundColor: 'background.surface',
            borderRadius: '50%',
            boxShadow: 'sm',
            opacity: 0.8,
            transition: 'opacity 0.2s',
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <IconButton size="sm" variant="soft" color="neutral" onClick={() => toggleCard(cardKey)}>
            <Close />
          </IconButton>
        </Box>
        {content}
      </Box>
    );
  };

  return (
    <>
      {hiddenItems.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button
            variant="soft"
            color="primary"
            startDecorator={<Visibility />}
            onClick={handleMenuToggle}
            aria-haspopup="true"
            aria-expanded={Boolean(menuAnchor)}
          >
            Show Hidden Cards ({hiddenItems.length})
          </Button>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            placement="bottom-start"
            sx={{ zIndex: 1000 }}
            variant="outlined"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ]}
          >
            {hiddenItems.map((item) => (
              <MenuItem key={item.key} onClick={() => handleMenuItemClick(item)} role="menuitem">
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
      <Box
        sx={{
          display: 'grid',
          gap: 4,
          gridTemplateColumns: {
            lg: visibleCardCount > 1 ? '1fr 1fr' : '1fr',
            md: '1fr',
            sm: '1fr',
            xs: '1fr',
          },
          mb: 6,
          width: '100%',
        }}
      >
        {renderCard('jobInfo' as keyof CardVisibility, <JobInfoCard job={job} />)}
        <SidePanels job={job} onHideCard={handleSectionHide} visibleSections={visibleSections} />

        {renderCard(
          'externalDescription',
          <BluebirdCard>
            <Typography level="body-md" startDecorator={<InfoOutlined />} sx={{ paddingBottom: '1rem' }}>
              External Job Description (Used for website and Job Boards)
            </Typography>
            <Typography level="body-sm">This feature is coming..</Typography>
            <CardContent>
              <RichTextEditor
                showMenuBar={false}
                onChange={(value) => {
                  console.log(value);
                }}
                content={job?.externalJobDescription}
              />
            </CardContent>
          </BluebirdCard>,
        )}

        {renderCard(
          'candidateAssignment' as keyof CardVisibility,
          <BluebirdCard>
            <Typography level="body-md" startDecorator={<InfoOutlined />} sx={{ paddingBottom: '1rem' }}>
              Quick Candidate Assignment
            </Typography>
            <CardContent>
              <CandidateAssignmentSection job={job} />
            </CardContent>
          </BluebirdCard>,
        )}
      </Box>
    </>
  );
};

export default GeneralTab;
