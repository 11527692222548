import { EventDetailDialog, useCalendarEvents } from '@bluebird-monorepo/calendar';
import { CalendarEvent, Job } from '@bluebird-monorepo/types';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { useJobEvents } from '../../../../hooks/useJobEvents';
import { useJobs } from '../../../../hooks/useJobs';
import { AssignEventToJob } from './AssignEventToJob';
import { EventList } from './EventList';

interface EventsTabProps {
  editableJob: Job;
}

export const EventsTab: FC<EventsTabProps> = ({ editableJob }) => {
  const { events, stats, refetch } = useJobEvents(editableJob.id);
  const { updateEvent } = useCalendarEvents();
  const { jobs } = useJobs();
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  const handleEventClick = (event: CalendarEvent) => {
    setSelectedEvent(event);
  };

  const handleEventUpdate = async ({ id, data }: { id: string; data: Partial<CalendarEvent> }) => {
    await updateEvent({ id, data });
    setSelectedEvent(null);
    refetch();
  };

  if (!events) return null;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <AssignEventToJob job={editableJob} onEventAssigned={refetch} />
        <Button component="a" href="/calendar" startDecorator={<Add />} variant="soft">
          Manage Calendar Events
        </Button>
      </Box>

      {events.length > 0 ? (
        <EventList events={events} stats={stats || { total: 0, scheduled: 0 }} onEventClick={handleEventClick} />
      ) : (
        <Typography level="body-sm">No events associated with this job.</Typography>
      )}

      {selectedEvent && (
        <EventDetailDialog
          event={selectedEvent}
          open={!!selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onEventUpdate={handleEventUpdate}
          jobs={jobs || []}
          companies={[]} // We don't need companies for job events
        />
      )}
    </Box>
  );
};
