import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { User } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { UserRepository } from '../store/user.repository';
import { useUserStore } from '../store/users.store';
import { useAuthSync } from './useAuthSync';

export const useUser = (userId?: string) => {
  const { firestore } = useFirebase();
  const userRepository = useMemo(() => new UserRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { loggedInUser, activeUser, setActiveUser, clearActiveUser } = useUserStore();

  // Always call useAuthSync
  useAuthSync();

  // Fetch specific user if userId is provided
  const {
    data: fetchedUser,
    isLoading: isLoadingUser,
    error: userError,
  } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => (userId ? userRepository.getUserById(userId) : null),
    enabled: !!userId,
  });

  // Handle active user updates separately from the query
  useEffect(() => {
    if (userId && fetchedUser) {
      setActiveUser(fetchedUser);
    } else if (!userId) {
      clearActiveUser();
    }
  }, [userId, fetchedUser, setActiveUser, clearActiveUser]);

  const updateUserMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<User> }) => userRepository.update(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      if (userId) {
        queryClient.invalidateQueries({ queryKey: ['user', userId] });
      }
    },
  });

  const {
    data: users,
    isLoading: isLoadingUsers,
    error: usersError,
  } = useQuery({
    queryKey: ['users'],
    queryFn: () => userRepository.fetchAll(),
  });

  return {
    updateUser: updateUserMutation.mutate,
    isUpdating: updateUserMutation.isPending,
    updateError: updateUserMutation.error,
    loggedInUser,
    isAuthenticated: !!loggedInUser,
    user: userId ? fetchedUser : loggedInUser,
    activeUser,
    isLoadingUser,
    userError,
    users,
    isLoadingUsers,
    usersError,
  };
};
