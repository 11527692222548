import { Box, Card, styled, Typography } from '@mui/joy';
import React from 'react';
import { createPortal } from 'react-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1000,
  minWidth: 320,
  maxWidth: 'calc(100vw - 32px)',
  border: '1px solid',
  borderColor: theme.vars.palette.divider,
  boxShadow: theme.vars.shadow.md,
  backgroundColor: theme.vars.palette.background.surface,
  animation: 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
  transformOrigin: 'var(--radix-hover-card-content-transform-origin)',
  willChange: 'transform, opacity',
  pointerEvents: 'auto',

  '&[data-side="top"]': {
    animation: 'slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
  },
  '&[data-side="right"]': {
    animation: 'slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
  },
  '&[data-side="bottom"]': {
    animation: 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
  },
  '&[data-side="left"]': {
    animation: 'slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
  },

  '@keyframes slideUpAndFade': {
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },

  '@keyframes slideRightAndFade': {
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
  },

  '@keyframes slideDownAndFade': {
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },

  '@keyframes slideLeftAndFade': {
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
  },
}));

const Arrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 8,
  height: 8,
  transform: 'rotate(45deg)',
  backgroundColor: theme.vars.palette.background.surface,
  borderRight: '1px solid',
  borderBottom: '1px solid',
  borderColor: theme.vars.palette.divider,

  '[data-side="top"] &': {
    bottom: -4,
    borderTop: 'none',
    borderLeft: 'none',
  },
  '[data-side="right"] &': {
    left: -4,
    borderBottom: 'none',
    borderRight: 'none',
  },
  '[data-side="bottom"] &': {
    top: -4,
    borderBottom: 'none',
    borderRight: 'none',
  },
  '[data-side="left"] &': {
    right: -4,
    borderTop: 'none',
    borderLeft: 'none',
  },
}));

export interface HoverCardProps {
  /** The element that triggers the hover card */
  children: React.ReactNode;
  /** The content to display in the hover card */
  content: React.ReactNode;
  /** Control the hover card visibility */
  open?: boolean;
  /** Callback when hover card visibility changes */
  onOpenChange?: (open: boolean) => void;
  /** The preferred side of the trigger to render the hover card */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /** The preferred alignment against the trigger */
  align?: 'start' | 'center' | 'end';
  /** The distance in pixels from the trigger */
  sideOffset?: number;
  /** The distance in pixels from the alignment edge */
  alignOffset?: number;
  /** Delay in ms before showing the hover card */
  openDelay?: number;
  /** Delay in ms before hiding the hover card */
  closeDelay?: number;
  /** Maximum width of the hover card */
  maxWidth?: number | string;
}

export const HoverCard = React.forwardRef<HTMLDivElement, HoverCardProps>(
  (
    {
      children,
      content,
      open: controlledOpen,
      onOpenChange,
      side = 'top',
      align = 'center',
      sideOffset = 8,
      alignOffset = 0,
      openDelay = 200,
      closeDelay = 300,
      maxWidth,
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isPinned, setIsPinned] = React.useState(false);
    const triggerRef = React.useRef<HTMLDivElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const [position, setPosition] = React.useState({ top: 0, left: 0 });
    const openTimeout = React.useRef<NodeJS.Timeout>();
    const closeTimeout = React.useRef<NodeJS.Timeout>();

    const open = controlledOpen ?? isOpen;

    const handleOpen = React.useCallback(() => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
      openTimeout.current = setTimeout(() => {
        if (onOpenChange) {
          onOpenChange(true);
        }
        setIsOpen(true);
      }, openDelay);
    }, [onOpenChange, openDelay]);

    const handleClose = React.useCallback(() => {
      if (isPinned) return; // Don't close if pinned
      if (openTimeout.current) {
        clearTimeout(openTimeout.current);
      }
      closeTimeout.current = setTimeout(() => {
        if (onOpenChange) {
          onOpenChange(false);
        }
        setIsOpen(false);
      }, closeDelay);
    }, [onOpenChange, closeDelay, isPinned]);

    // Update position when content changes or window resizes
    React.useEffect(() => {
      const updatePosition = () => {
        if (triggerRef.current && open) {
          const rect = triggerRef.current.getBoundingClientRect();
          const contentRect = contentRef.current?.getBoundingClientRect();
          const contentWidth = contentRect?.width || 320;
          const contentHeight = contentRect?.height || 0;

          let top = 0;
          let left = 0;

          switch (side) {
            case 'top':
              top = rect.top - contentHeight - sideOffset;
              left = rect.left + (rect.width - contentWidth) / 2;
              break;
            case 'right':
              top = rect.top + (rect.height - contentHeight) / 2;
              left = rect.right + sideOffset;
              break;
            case 'bottom':
              top = rect.bottom + sideOffset;
              left = rect.left + (rect.width - contentWidth) / 2;
              break;
            case 'left':
              top = rect.top + (rect.height - contentHeight) / 2;
              left = rect.left - contentWidth - sideOffset;
              break;
          }

          // Alignment adjustments
          switch (align) {
            case 'start':
              if (side === 'top' || side === 'bottom') {
                left = rect.left + alignOffset;
              } else {
                top = rect.top + alignOffset;
              }
              break;
            case 'end':
              if (side === 'top' || side === 'bottom') {
                left = rect.right - contentWidth - alignOffset;
              } else {
                top = rect.bottom - contentHeight - alignOffset;
              }
              break;
          }

          // Prevent overflow
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          // Adjust horizontal position
          if (left < 16) {
            left = 16;
          } else if (left + contentWidth > viewportWidth - 16) {
            left = viewportWidth - contentWidth - 16;
          }

          // Adjust vertical position
          if (top < 16) {
            top = 16;
          } else if (top + contentHeight > viewportHeight - 16) {
            top = viewportHeight - contentHeight - 16;
          }

          setPosition({ top, left });
        }
      };

      updatePosition();

      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);

      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition);
      };
    }, [open, side, align, sideOffset, alignOffset]);

    // Cleanup timeouts
    React.useEffect(() => {
      return () => {
        if (openTimeout.current) clearTimeout(openTimeout.current);
        if (closeTimeout.current) clearTimeout(closeTimeout.current);
      };
    }, []);

    return (
      <>
        <Box
          ref={triggerRef}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onFocus={handleOpen}
          onBlur={handleClose}
          sx={{ display: 'inline-block' }}
        >
          {children}
        </Box>
        {open &&
          createPortal(
            <StyledCard
              ref={contentRef}
              variant="outlined"
              data-side={side}
              sx={{
                top: position.top,
                left: position.left,
                ...(maxWidth && { maxWidth }),
              }}
              onMouseEnter={handleOpen}
              onMouseLeave={handleClose}
            >
              <Box sx={{ position: 'relative', height: '100%' }}>
                <Typography
                  level="body-xs"
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    color: 'neutral.500',
                    cursor: 'pointer',
                    fontSize: '11px',
                    '&:hover': {
                      color: 'neutral.600',
                    },
                    zIndex: 10,
                    marginTop: '-15px',
                    marginRight: '-15px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPinned(!isPinned);
                  }}
                >
                  {isPinned ? 'unpin' : 'keep open'}
                </Typography>
                {content}
                <Typography
                  level="body-xs"
                  sx={{
                    position: 'absolute',
                    bottom: 4,
                    right: 4,
                    color: 'neutral.500',
                    cursor: 'pointer',
                    fontSize: '11px',
                    '&:hover': {
                      color: 'neutral.600',
                    },
                    marginBottom: '-15px',
                    marginRight: '-15px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPinned(false);
                    setIsOpen(false);
                    onOpenChange?.(false);
                  }}
                >
                  close
                </Typography>
              </Box>
              <Arrow />
            </StyledCard>,
            document.body,
          )}
      </>
    );
  },
);

HoverCard.displayName = 'HoverCard';
