import { HoverCard } from '@bluebird-monorepo/bluebird-ui';
import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from '@mui/joy';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { AboutStep } from './steps/AboutStep';
import { DescriptionsStep } from './steps/DescriptionsStep';
import { DetailsStep } from './steps/DetailsStep';
import { RequirementsStep } from './steps/RequirementsStep';
import { ReviewStep } from './steps/ReviewStep';

// Custom Zod schema for Timestamp
const timestampSchema = z.custom<{ seconds: number; nanoseconds: number }>(
  (val) => {
    return val && typeof val === 'object' && 'seconds' in val && 'nanoseconds' in val;
  },
  {
    message: 'Invalid date',
  },
);

// Validation schema
const jobSchema = z.object({
  companyId: z.string().min(1, 'Company is required'),
  companyName: z.string().min(1, 'Company name is required'),
  jobTitle: z.string().min(1, 'Job title is required'),
  location: z.string().min(1, 'Location is required'),
  contractType: z.string().min(1, 'Contract type is required'),
  vertical: z.enum(['Engineering', 'Product', 'Marketing', 'Sales', 'Customer Success']).optional(),
  roleDescription: z.string().optional(),
  productDescription: z.string().optional(),
  uniqueAboutCompany: z.string().optional(),
  extraInformation: z.string().optional(),
  idealCandidateProfile: z.string().optional(),
  salaryRange: z
    .object({
      min: z.number().min(1, 'Minimum salary is required'),
      max: z.number().min(1, 'Maximum salary is required'),
    })
    .optional(),
  feePercentage: z.number().min(1, 'Fee percentage is required'),
  preferredLanguages: z.array(z.string()).optional(),
  mustHaves: z.string().optional(),
  techStack: z.string().optional(),
  niceToHaves: z.string().optional(),
  keyResponsibilities: z.string().optional(),
  hiringProcess: z.string().min(1, 'Hiring process is required'),
  howToIntro: z.string().min(1, 'Introduction guidance is required'),
  externalJobDescription: z.string(),
  createdAt: z.string(),
  referenceNumber: z.number(),
  responsible: z.string(),
  workingOn: z.array(z.string()),
  openSinceDate: timestampSchema.optional(),
});

type StepConfig = {
  label: string;
  description: string;
  component: React.ComponentType;
  fields: Array<keyof Job>;
};

interface CreateJobWizardProps {
  initialData?: Partial<Job>;
  mode?: 'create' | 'edit';
  onComplete: (data: Partial<Job>) => void;
  onCancel: () => void;
}

export const CreateJobWizard: React.FC<CreateJobWizardProps> = ({
  onComplete,
  onCancel,
  mode = 'create',
  initialData,
}) => {
  const steps: StepConfig[] = [
    {
      label: 'About',
      description: 'Basic job information',
      component: AboutStep,
      fields: ['companyId', 'companyName', 'jobTitle', 'location', 'contractType'],
    },
    {
      label: 'Details',
      description: 'Salary and requirements',
      component: DetailsStep,
      fields: ['salaryRange', 'feePercentage', 'openSinceDate'],
    },
    {
      label: 'Descriptions',
      description: 'Description about the role and product',
      component: DescriptionsStep,
      fields: ['roleDescription', 'productDescription', 'uniqueAboutCompany', 'extraInformation'],
    },
    {
      label: 'Requirements',
      description: 'Key responsibilities and process',
      component: RequirementsStep,
      fields: ['mustHaves', 'techStack', 'niceToHaves', 'keyResponsibilities', 'hiringProcess', 'howToIntro'],
    },
    {
      label: 'Review',
      description: 'Review and submit',
      component: ReviewStep,
      fields: [],
    },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [stepValidationState, setStepValidationState] = useState<Record<number, boolean>>({});
  const { createJobMutation, updateJobMutation, addTimelineEntryMutation } = useJobs();
  const { user } = useUser();

  const methods = useForm<Job>({
    resolver: zodResolver(jobSchema),
    defaultValues: {
      ...initialData,
      createdAt: initialData?.createdAt || new Date().toISOString(),
      openSinceDate: initialData?.openSinceDate || Timestamp.fromDate(new Date()),
      externalJobDescription: initialData?.externalJobDescription || '',
      referenceNumber: initialData?.referenceNumber || Math.floor(Math.random() * 10000),
      responsible: initialData?.responsible || '',
      workingOn: initialData?.workingOn || [],
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUnregister: false,
  });

  const {
    formState: { errors, isValid, touchedFields, dirtyFields },
    trigger,
    watch,
  } = methods;

  // Debug logging
  useEffect(() => {
    console.log('Form validation state:', {
      isValid,
      errors,
      activeStep,
      stepValidationState,
    });
  }, [isValid, errors, activeStep, stepValidationState]);

  // Watch fields for current step to trigger validation
  useEffect(() => {
    const currentStepFields = steps[activeStep].fields;
    const subscription = watch((_, { name, type }) => {
      if (name && currentStepFields.includes(name as keyof Job)) {
        validateCurrentStep();
      }
    });
    return () => subscription.unsubscribe();
  }, [activeStep, watch]);

  const validateCurrentStep = async () => {
    const currentStepFields = steps[activeStep].fields;
    const results = await trigger(currentStepFields);
    setStepValidationState((prev) => ({ ...prev, [activeStep]: results }));
    return results;
  };

  // Initial validation of current step
  useEffect(() => {
    validateCurrentStep();
    // When on the final step, validate all fields
    if (activeStep === steps.length - 1) {
      trigger(); // Trigger validation for all fields
    }
  }, [activeStep]);

  const handleNext = async (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent any form submission
    const stepValid = await validateCurrentStep();
    if (stepValid) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      // When moving to the final step, validate all fields
      if (nextStep === steps.length - 1) {
        trigger(); // Trigger validation for all fields
      }
    }
  };

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent any form submission
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleStepClick = (index: number) => {
    // Only allow navigation to steps that come before the current step
    if (index < activeStep) {
      setActiveStep(index);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    if (activeStep !== steps.length - 1) {
      return; // Only allow submission on the last step
    }

    const formData = methods.getValues();
    await onFormSubmit(formData as Job);
  };

  const onFormSubmit = async (formData: Job) => {
    if (!user) return;

    try {
      if (mode === 'create') {
        // Add openSinceDate before creating the job
        console.log('formData', formData);
        console.log('openSinceDate', Timestamp.fromDate(new Date()));
        const jobDataWithOpenSince = {
          ...formData,
          openSinceDate: formData.openSinceDate || Timestamp.fromDate(new Date()),
          status: 'needs_work',
        };
        console.log('jobDataWithOpenSince', jobDataWithOpenSince);
        const createdJob = await createJobMutation.mutateAsync(jobDataWithOpenSince);

        /* // Add creation timeline entry
        const timelineEntry: Omit<SystemChangeTimelineEntry, 'id'> = {
          type: 'system_change',
          timestamp: Timestamp.now(),
          createdBy: {
            id: user.id,
            name: user.displayName || user.email || 'Unknown',
            avatar: user.photoURL || undefined,
          },
          entityType: 'job',
          entityId: createdJob.id,
          changes: [
            {
              field: 'status',
              oldValue: null,
              newValue: 'created',
            },
          ],
        };
        try {
          await addTimelineEntryMutation.mutateAsync({
            jobId: createdJob.id,
            entry: timelineEntry,
          });
        } catch (error) {
          console.log(JSON.stringify(error));
        } */

        onComplete(formData);
      } else {
        if (!initialData?.id) throw new Error('Job ID is required for updates');

        /* // Find changed fields
        const changes = Object.entries(formData).reduce<Array<{ field: string; oldValue: any; newValue: any }>>(
          (acc, [key, value]) => {
            const oldValue = initialData[key as keyof Job];
            if (oldValue !== value) {
              acc.push({
                field: key,
                oldValue,
                newValue: value,
              });
            }
            return acc;
          },
          [],
        ); */

        await updateJobMutation.mutateAsync({
          id: initialData.id,
          data: formData,
        });

        /* // Add update timeline entry if there are changes
        if (changes.length > 0) {
          const timelineEntry: Omit<SystemChangeTimelineEntry, 'id'> = {
            type: 'system_change',
            timestamp: Timestamp.now(),
            createdBy: {
              id: user.id,
              name: user.displayName || user.email || 'Unknown',
              avatar: user.photoURL || undefined,
            },
            entityType: 'job',
            entityId: initialData.id,
            changes,
          };

          await addTimelineEntryMutation.mutateAsync({
            jobId: initialData.id,
            entry: timelineEntry,
          });
        } */

        onComplete(formData);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  // Get all validation errors as a flat array of messages
  const getAllErrorMessages = () => {
    const messages: string[] = [];
    Object.entries(errors).forEach(([field, error]) => {
      // Only show errors for dirty fields
      const isDirty = Object.prototype.hasOwnProperty.call(dirtyFields, field);
      if (isDirty && dirtyFields[field as keyof Job]) {
        if (error?.message) {
          messages.push(`${field}: ${error.message}`);
        } else if (typeof error === 'object' && error !== null) {
          Object.entries(error).forEach(([subField, subError]: [string, any]) => {
            // For nested fields, check if the parent field is dirty
            if (isDirty && dirtyFields[field as keyof Job] && subError?.message) {
              messages.push(`${field}.${subField}: ${subError.message}`);
            }
          });
        }
      }
    });
    return messages;
  };

  const renderStepContent = (step: number) => {
    const StepComponent = steps[step].component;
    return <StepComponent />;
  };

  const errorMessages = getAllErrorMessages();

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          p: 3,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        {/* Error Alert */}
        {errorMessages.length > 0 && (
          <Alert color="danger" size="sm" variant="soft">
            <Box>
              <Typography level="title-sm" sx={{ mb: 1 }}>
                Please fix the following errors to {mode === 'edit' ? 'save' : 'create'} the job:
              </Typography>
              <List size="sm">
                {errorMessages.map((message, index) => (
                  <ListItem key={index}>• {message}</ListItem>
                ))}
              </List>
            </Box>
          </Alert>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 4,
          }}
        >
          {/* Close button */}
          <IconButton
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
              },
            }}
          >
            <Close />
          </IconButton>

          {/* Left side - Stepper */}
          <Box
            sx={{
              width: '280px',
              borderRight: '1px solid',
              borderColor: 'divider',
              pr: 3,
            }}
          >
            <Stepper
              component="nav"
              orientation="vertical"
              sx={{
                '--Stepper-spacing': '1rem',
                '--StepIndicator-size': '2rem',
                '--Step-gap': '1rem',
                '--Step-connectorThickness': '2px',
                '--Step-connectorRadius': '1px',
                minHeight: 400,
              }}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  completed={index < activeStep}
                  indicator={
                    <StepIndicator
                      variant={activeStep === index ? 'solid' : 'soft'}
                      color={activeStep === index ? 'primary' : 'neutral'}
                    >
                      {index + 1}
                    </StepIndicator>
                  }
                  slotProps={{
                    root: {
                      sx: {
                        cursor: index < activeStep ? 'pointer' : 'default',
                        '&:hover': {
                          bgcolor: index < activeStep ? 'background.level1' : undefined,
                        },
                      },
                      onClick: () => handleStepClick(index),
                    },
                  }}
                >
                  <Box>
                    <Box sx={{ fontWeight: 'bold' }}>{step.label}</Box>
                    <Box sx={{ color: 'text.secondary', fontSize: 'sm' }}>{step.description}</Box>
                  </Box>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Right side - Content */}
          <Box sx={{ flex: 1, maxWidth: '800px' }}>
            <form onSubmit={handleFormSubmit}>
              {renderStepContent(activeStep)}

              {/* Navigation buttons */}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 4,
                  pt: 2,
                  borderTop: '1px solid',
                  borderColor: 'divider',
                }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                  }}
                  sx={{ minWidth: 100 }}
                >
                  Cancel
                </Button>
                <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0} sx={{ minWidth: 100 }}>
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <HoverCard
                    content={
                      !isValid ? (
                        <Box>
                          <Typography level="title-sm" sx={{ mb: 1 }}>
                            Please fix the following errors:
                          </Typography>
                          <List size="sm">
                            {getAllErrorMessages().map((message, index) => (
                              <ListItem key={index}>• {message}</ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null
                    }
                  >
                    <Button type="submit" disabled={!isValid} sx={{ minWidth: 100 }}>
                      {mode === 'edit' ? 'Save' : 'Submit'}
                    </Button>
                  </HoverCard>
                ) : (
                  <HoverCard
                    content={
                      !stepValidationState[activeStep] ? (
                        <Box>
                          <Typography level="title-sm" sx={{ mb: 1 }}>
                            Please fix the following errors in this step:
                          </Typography>
                          <List size="sm">
                            {Object.entries(errors)
                              .filter(([field]) => steps[activeStep].fields.includes(field as keyof Job))
                              .map(([field, fieldError]) => {
                                const error = fieldError as { message?: string };
                                return (
                                  <ListItem key={field}>
                                    • {field}: {error?.message || 'Invalid value'}
                                  </ListItem>
                                );
                              })}
                          </List>
                        </Box>
                      ) : null
                    }
                  >
                    <Button onClick={handleNext} disabled={!stepValidationState[activeStep]} sx={{ minWidth: 100 }}>
                      Next
                    </Button>
                  </HoverCard>
                )}
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
};
