import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CreateVerticalDto, UpdateVerticalDto, Vertical } from '../../../types/src/verticals/vertical.types';
import { VerticalRepository } from '../repositories/vertical.repository';

export const useVerticals = () => {
  const { firestore } = useFirebase();
  const verticalRepository = new VerticalRepository(firestore);
  const VERTICALS_QUERY_KEY = 'verticals';
  const queryClient = useQueryClient();

  const { data: verticals, isLoading } = useQuery<Vertical[]>({
    queryKey: [VERTICALS_QUERY_KEY],
    queryFn: () => verticalRepository.getAllVerticals(),
  });

  const createVerticalMutation = useMutation({
    mutationFn: (vertical: CreateVerticalDto) => verticalRepository.createVertical(vertical),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VERTICALS_QUERY_KEY] });
    },
  });

  const updateVerticalMutation = useMutation({
    mutationFn: ({ id, vertical }: { id: string; vertical: UpdateVerticalDto }) =>
      verticalRepository.updateVertical(id, vertical),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VERTICALS_QUERY_KEY] });
    },
  });

  const deleteVerticalMutation = useMutation({
    mutationFn: (id: string) => verticalRepository.deleteVertical(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VERTICALS_QUERY_KEY] });
    },
  });

  return {
    verticals,
    isLoading,
    createVertical: createVerticalMutation.mutate,
    updateVertical: updateVerticalMutation.mutate,
    deleteVertical: deleteVerticalMutation.mutate,
  };
};
