import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Candidate } from '@bluebird-monorepo/types';
import { and, DocumentSnapshot, Firestore, getDoc, getDocs, limit, or, orderBy, query, startAfter, where, WhereFilterOp } from 'firebase/firestore';

export class CandidatesRepository extends BaseRepository<Candidate> {
  private static completedSearchQueries = new Set<string>();

  constructor(db: Firestore) {
    super(db, 'candidates');
  }

  async get(id: string): Promise<Candidate | null> {
    const doc = await this.getDocumentSnapshot(id);
    if (!doc || !doc.exists()) return null;
    return { id: doc.id, ...doc.data() } as Candidate;
  }

  async getByLinkedin(linkedinUrl: string): Promise<Candidate[]> {
    const constraints: { field: string; op: WhereFilterOp; value: any }[] = [
      { field: 'linkedin', op: '==', value: linkedinUrl },
    ];
    return this.getAllForExtension(constraints);
  }

  async getBySorting(
    field: string,
    direction: 'asc' | 'desc',
    lastCandidate: Candidate | null = null,
    batchSize = 50
  ): Promise<Candidate[]> {
    const requestId = Date.now().toString();
    const timerLabel = `CandidateBySorting_${requestId}`;
    
    console.time(timerLabel);
    console.log('🔍 Starting sorted query for candidates:', { field, direction });
    
    try {
      let sortQuery;
      
      if (lastCandidate) {
        console.log('📑 Paginating from last candidate:', lastCandidate.id);
        const lastSnapshot = await this.getDocumentSnapshot(lastCandidate.id);
        if (!lastSnapshot) {
          console.warn('❌ Last candidate snapshot not found', lastCandidate);
          return [];
        }
        
        sortQuery = query(
          this.collectionRef,
          orderBy(field, direction),
          startAfter(lastSnapshot),
          limit(batchSize)
        );
      } else {
        sortQuery = query(
          this.collectionRef,
          orderBy(field, direction),
          limit(batchSize)
        );
      }
      
      console.log('🔎 Executing sort query...');
      const snapshot = await getDocs(sortQuery);
      const results = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Candidate));
      
      console.timeEnd(timerLabel);
      console.log(`🎯 Sort query found ${results.length} candidates`);
      
      return results;
    } catch (error) {
      console.error('❌ Error in getBySorting:', error);
      console.timeEnd(timerLabel);
      throw error;
    }
  }

  async getNextBatch(lastCandidate: Candidate | null, batchSize = 50): Promise<Candidate[]> {
    const requestId = Date.now().toString();
    const timerLabel = `CandidateRepository_Batch_${requestId}`;
    
    console.time(timerLabel);

    let candidatesQuery;
    
    if (lastCandidate) {
      // Get the document snapshot for the last candidate
      const lastSnapshot = await this.getDocumentSnapshot(lastCandidate.id);
      if (!lastSnapshot) {
        console.warn('Last candidate snapshot not found', lastCandidate);
        return [];
      }
      
      candidatesQuery = query(
        this.collectionRef,
        orderBy('firstName'),
        startAfter(lastSnapshot),
        limit(batchSize)
      );
    } else {
      candidatesQuery = query(
        this.collectionRef,
        orderBy('firstName'),
        limit(batchSize)
      );
    }

    const snapshot = await getDocs(candidatesQuery);
    const candidates = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Candidate));
    
    console.timeEnd(timerLabel);
    console.log(`Fetched batch of ${candidates.length} candidates`);
    
    return candidates;
  }

  private async getDocumentSnapshot(id: string): Promise<DocumentSnapshot | null> {
    try {
      const snapshot = await getDoc(this.docRef(id));
      return snapshot;
    } catch (error) {
      console.error('Error getting document snapshot:', error);
      return null;
    }
  }

  async getByNamePrefix(searchTerm: string): Promise<Candidate[]> {
    const requestId = Date.now().toString();
    const timerLabel = `CandidateByNamePrefix_${requestId}`;
    
    console.time(timerLabel);
    console.log('🔍 Starting candidate search:', {
      searchTerm,
      requestId,
      isFirstSearch: !CandidatesRepository.completedSearchQueries.has(searchTerm),
      completedQueries: Array.from(CandidatesRepository.completedSearchQueries)
    });
    
    try {
      // Create both capitalized and lowercase versions for search
      const capitalizedTerm = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1);
      const lowercaseTerm = searchTerm.toLowerCase();
      console.log('📝 Search variations:', {
        capitalizedTerm,
        lowercaseTerm,
        requestId
      });
      
      // First try searching by firstName
      const firstNameQuery = query(
        this.collectionRef,
        or(
          and(
            where('firstName', '>=', capitalizedTerm),
            where('firstName', '<', capitalizedTerm + '\uf8ff')
          ),
        ),
        orderBy('firstName'),
        limit(50)
      );
      
      console.log('🔎 Executing firstName query...');
      const firstNameSnapshot = await getDocs(firstNameQuery);
      const firstNameResults = firstNameSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Candidate));
      
      // Then search by lastName
      const lastNameQuery = query(
        this.collectionRef,
        or(
          and(
            where('lastName', '>=', capitalizedTerm),
            where('lastName', '<', capitalizedTerm + '\uf8ff')
          ),
          and(
            where('lastName', '>=', lowercaseTerm),
            where('lastName', '<', lowercaseTerm + '\uf8ff')
          )
        ),
        orderBy('lastName'),
        limit(50)
      );
      
      console.log('🔎 Executing lastName query...');
      const lastNameSnapshot = await getDocs(lastNameQuery);
      const lastNameResults = lastNameSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Candidate));
      
      // Combine and deduplicate results
      const combinedResults = [...firstNameResults, ...lastNameResults];
      const uniqueResults = Array.from(new Map(combinedResults.map(item => [item.id, item])).values());
      
      // Add this search term to completed queries
      CandidatesRepository.completedSearchQueries.add(searchTerm);
      
      console.timeEnd(timerLabel);
      console.log('🎯 Search results:', {
        searchTerm,
        resultCount: uniqueResults.length,
        results: uniqueResults.map(c => ({ id: c.id, firstName: c.firstName, lastName: c.lastName })),
        requestId,
        totalCompletedQueries: CandidatesRepository.completedSearchQueries.size
      });
      
      return uniqueResults;
    } catch (error) {
      console.error('❌ Error in getByNamePrefix:', error);
      console.timeEnd(timerLabel);
      throw error;
    }
  }
}
