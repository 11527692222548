import { User } from '@bluebird-monorepo/types';
import { AvatarGroup, Box, Chip } from '@mui/joy';
import { memo } from 'react';
import { UserAvatar } from '../UserAvatar/UserAvatar';

export interface UserAvatarStackProps {
  /**
   * The users to display (User objects, emails, or names)
   */
  users: (User | string | null)[];
  /**
   * Optional size override
   */
  size?: number;
  /**
   * Text to show when there are no users
   */
  emptyText?: string;
  /**
   * Maximum number of avatars to show before +X
   */
  maxAvatars?: number;
}

function UserAvatarStackComponent({ users = [], size = 40, emptyText = 'None', maxAvatars = 3 }: UserAvatarStackProps) {
  const validUsers = users.filter((user): user is User | string => user !== null);

  if (!validUsers.length) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Chip size="sm" variant="outlined">
          {emptyText}
        </Chip>
      </Box>
    );
  }

  return (
    <AvatarGroup
      sx={{
        '--AvatarGroup-gap': '-8px',
        '--Avatar-size': `${size}px`,
      }}
    >
      {validUsers.map((user, index) => (
        <UserAvatar key={typeof user === 'string' ? user : user.id || index} user={user} size={size} />
      ))}
    </AvatarGroup>
  );
}

export const UserAvatarStack = memo(UserAvatarStackComponent);
