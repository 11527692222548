import { FirebaseApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Functions } from 'firebase/functions';
import { FirebaseStorage } from 'firebase/storage';
import { createContext, ReactNode } from 'react';
import { firebaseService } from '../service/firebase.service';

interface FirebaseContextValue {
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
}

const FirebaseContext = createContext<FirebaseContextValue | undefined>(undefined);

export function FirebaseProvider({ children }: { children: ReactNode }) {
  return <FirebaseContext.Provider value={firebaseService}>{children}</FirebaseContext.Provider>;
}

// Export the context if needed by other components
export { FirebaseContext };
