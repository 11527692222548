import { CreateJobWizard, useJobs } from '@bluebird-monorepo/jobs';
import { Box, Typography } from '@mui/joy';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../../layout';

const EditJobPage = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const { job, isLoading, error } = useJobs(jobId);

  const handleComplete = () => {
    navigate(`/jobs/${jobId}`);
  };

  const handleCancel = () => {
    navigate(`/jobs/${jobId}`);
  };

  if (isLoading) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Loading...</Typography>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Error: {error.message}</Typography>
      </Layout>
    );
  }

  if (!job) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Job not found</Typography>
      </Layout>
    );
  }

  return (
    <Layout title={`Edit ${job.jobTitle}`} subtitle={job.companyName}>
      <Box sx={{ width: '100%' }}>
        <CreateJobWizard mode="edit" initialData={job} onComplete={handleComplete} onCancel={handleCancel} />
      </Box>
    </Layout>
  );
};

export default EditJobPage;
