import { CssBaseline } from '@mui/joy';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import {
  THEME_ID as MATERIAL_THEME_ID,
  ThemeProvider as MaterialCssVarsProvider,
  createTheme as materialExtendTheme,
} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { pdfjs } from 'react-pdf';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { useCollectionSizes } from '@bluebird-monorepo/bluebird-firebase';
import DashboardPage from './pages/DashboardPage';
import EditJobPage from './pages/jobs/EditJobPage';
import JobDetailsPage, {
  CandidatesTab,
  EventsTab,
  GeneralTab,
  TasksTab,
  TimelineTab,
} from './pages/jobs/JobDetailPage';
import JobsPage from './pages/jobs/JobsPage';
import UserSettingsPage from './pages/settings/UserSettingsPage';
import SignInPage from './pages/SignInPage';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const materialTheme = materialExtendTheme();

import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

const SLOW_QUERY_THRESHOLD = 1000; // 1 second

function useQueryPerformance() {
  const queryClient = useQueryClient();

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      if (event?.type === 'updated' && event.query.state.status === 'success') {
        const duration =
          event.query.state.fetchStatus === 'fetching' ? event.query.state.dataUpdatedAt - Date.now() : 0;
        if (duration > SLOW_QUERY_THRESHOLD) {
          console.warn(`Slow query detected: ${event.query.queryKey.join('/')} took ${duration}ms`);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient]);
}

function App() {
  useQueryPerformance();
  useCollectionSizes();

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline enableColorScheme />
          <Router>
            <Routes>
              <Route path="/login" element={<SignInPage />} />
              <Route path="/" element={<DashboardPage />} />
              {/* <Route path="/pipeline" element={<PipelinePage />} />
              <Route path="/pipeline/my" element={<PipelinePage />} />
              <Route path="/pipeline/team" element={<PipelinePage />} />
              <Route path="/pipeline/job" element={<PipelinePage />} /> */}
              <Route element={<JobsPage />} path="/jobs" />
              <Route path="/jobs/:jobId" element={<JobDetailsPage />}>
                <Route index element={<Navigate to="general" replace />} />
                <Route path="general" element={<GeneralTab />} />
                <Route path="candidates" element={<CandidatesTab />} />
                <Route path="events" element={<EventsTab />} />
                <Route path="timeline" element={<TimelineTab />} />
                <Route path="tasks" element={<TasksTab />} />
                <Route path="*" element={<Navigate to="general" replace />} />
              </Route>
              <Route path="/jobs/:jobId/edit" element={<EditJobPage />} />

              {/* <Route element={<CompaniesPage />} path="/companies" />
              <Route path="/companies/:companyId/*" element={<CompanyDetailPage />} />

              <Route element={<CandidatesPage />} path="/candidates" />
              <Route element={<CandidateDetailPage />} path="/candidates/:candidateId/*" />

              <Route element={<UsersPage />} path="/users" />
              <Route element={<UserDetailPage />} path="/users/:userId" />
              <Route element={<TeamDetailPage />} path="/teams/:teamId" />

              <Route element={<PlacementsPage />} path="/placements" />
              <Route element={<PlacementDetailPage />} path="/placements/:placementId" />

              <Route element={<InvoicesPage />} path="/invoices" />
              <Route element={<FirefliesPage />} path="/fireflies" />
              <Route element={<TranscriptDetailPage />} path="/fireflies/:transcriptId" /> */}

              <Route element={<SignInPage />} path="/sign-in" />

              {/* <Route element={<CalendarPage />} path="/calendar" />
              <Route element={<TasksPage />} path="/tasks" /> */}

              <Route path="/user-settings/:userId" element={<UserSettingsPage />} />
              {/*  <Route path="/introductions" element={<IntroductionsPage />} />
              <Route path="/metrics" element={<MetricsPage />} /> */}
            </Routes>
          </Router>
        </LocalizationProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
