import { BluebirdCard, RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { Job } from '@bluebird-monorepo/types';
import { Close, InfoOutlined } from '@mui/icons-material';
import { Box, CardContent, Divider, IconButton, Typography, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';

type SectionKey =
  | 'mustHaves'
  | 'techStack'
  | 'niceToHaves'
  | 'keyResponsibilities'
  | 'idealCandidateProfile'
  | 'hiringProcess'
  | 'howToIntro';

type Section = {
  key: SectionKey;
  order: number;
  placeholder: string;
  title: string;
};

type SidePanelsProps = {
  job: Job;
  onHideCard?: (key: SectionKey) => void;
  visibleSections: Record<SectionKey, boolean>;
};

const SidePanels: FC<SidePanelsProps> = ({ job, onHideCard, visibleSections }) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm')); // 0-600px
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600-900px
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900-1200px
  const isLarge = useMediaQuery(theme.breakpoints.up('lg')); // 1200px+

  // Layout configuration based on screen size
  const layoutConfig = {
    gridColumns: {
      xs: '1fr',
      sm: 'repeat(1, 1fr)',
      md: 'repeat(2, 1fr)',
      lg: 'repeat(2, 1fr)',
    },
    spacing: {
      xs: 2,
      sm: 2.5,
      md: 3,
      lg: 4,
    },
    fontSize: {
      title: {
        xs: 14,
        sm: 15,
        md: 16,
      },
      body: {
        xs: 13,
        sm: 14,
        md: 15,
      },
    },
    padding: {
      xs: 0.5,
      sm: 0.75,
      md: 0.75,
    },
  };

  const sections: Section[] = [
    {
      key: 'mustHaves',
      order: 1,
      placeholder: 'List essential skills and qualifications required for this role.',
      title: 'Must haves',
    },
    {
      key: 'techStack',
      order: 2,
      placeholder: 'List technical skills and technologies that this company uses.',
      title: 'Tech Stack',
    },
    {
      key: 'niceToHaves',
      order: 3,
      placeholder: 'List nice-to-have skills and qualifications required for this role.',
      title: 'Nice to haves',
    },
    {
      key: 'keyResponsibilities',
      order: 4,
      placeholder: 'List key responsibilities for this role.',
      title: 'Key responsibilities',
    },
    {
      key: 'idealCandidateProfile',
      order: 5,
      placeholder: "Describe the ideal candidate's experience, traits, and skills.",
      title: 'Candidate Profile',
    },
    {
      key: 'hiringProcess',
      order: 6,
      placeholder: 'Outline the steps in the hiring process, including timelines and methods.',
      title: 'Hiring process',
    },
    {
      key: 'howToIntro',
      order: 7,
      placeholder: 'Provide tips for introducing candidates to the team and organization.',
      title: 'How to Intro?',
    },
  ];

  const [isResponsibilitiesCollapsed, setResponsibilitiesCollapsed] = useState(true);

  return (
    <Box
      sx={{
        display: 'grid',
        gap: {
          xs: layoutConfig.spacing.xs,
          sm: layoutConfig.spacing.sm,
          md: layoutConfig.spacing.md,
          lg: layoutConfig.spacing.lg,
        },
        gridTemplateColumns: {
          xs: layoutConfig.gridColumns.xs,
          sm: layoutConfig.gridColumns.sm,
          md: layoutConfig.gridColumns.md,
          lg: layoutConfig.gridColumns.lg,
        },
        width: '100%',
      }}
    >
      {sections.map((section) =>
        visibleSections[section.key] ? (
          <Box key={section.key} sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 2,
                backgroundColor: 'background.surface',
                borderRadius: '50%',
                boxShadow: 'sm',
                opacity: 0.8,
                transition: 'opacity 0.2s',
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <IconButton size="sm" variant="soft" color="neutral" onClick={() => onHideCard?.(section.key)}>
                <Close />
              </IconButton>
            </Box>
            <BluebirdCard
              sx={{
                order: section.order,
              }}
            >
              <CardContent>
                <Typography
                  level="body-md"
                  startDecorator={
                    <InfoOutlined
                      sx={{
                        fontSize: {
                          xs: '1.1rem',
                          sm: '1.2rem',
                          md: '1.3rem',
                        },
                      }}
                    />
                  }
                  sx={{
                    alignItems: 'center',
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: 1,
                  }}
                  onClick={() => setResponsibilitiesCollapsed(!isResponsibilitiesCollapsed)}
                >
                  {section.title}
                </Typography>

                <Divider
                  inset="none"
                  sx={{
                    mt: {
                      xs: 1,
                      sm: 1.5,
                      md: 2,
                    },
                  }}
                />

                <RawHTML
                  children={job?.[section.key] || ''}
                  style={{
                    padding: 0,
                    fontSize: isXSmall
                      ? layoutConfig.fontSize.body.xs
                      : isSmall
                        ? layoutConfig.fontSize.body.sm
                        : layoutConfig.fontSize.body.md,
                    lineHeight: 1.6,
                  }}
                />
              </CardContent>
            </BluebirdCard>
          </Box>
        ) : null,
      )}
    </Box>
  );
};

export default SidePanels;
