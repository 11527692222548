import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { TaskboardItem } from '@bluebird-monorepo/types';
import { Firestore, where } from 'firebase/firestore';

export class TaskRepository extends BaseRepository<TaskboardItem> {
  constructor(db: Firestore) {
    if (!db) throw new Error('Firestore instance is required');
    super(db, 'tasks');
  }

  async getTasksByEntity(entityType: string, entityId: string): Promise<TaskboardItem[]> {
    return this.getAllWithNormalizedTimestamps(
      ['dueDate', 'createdAt', 'updatedAt'],
      [where('entityType', '==', entityType), where('entityId', '==', entityId)],
    );
  }

  async getTasksByAssignee(assigneeId: string): Promise<TaskboardItem[]> {
    return this.getAllWithNormalizedTimestamps(
      ['dueDate', 'createdAt', 'updatedAt'],
      [where('assignedTo', '==', assigneeId)],
    );
  }

  async getTasksByStatus(status: string): Promise<TaskboardItem[]> {
    return this.getAllWithNormalizedTimestamps(['dueDate', 'createdAt', 'updatedAt'], [where('status', '==', status)]);
  }

  async getTasksByDueDate(startDate: Date, endDate: Date): Promise<TaskboardItem[]> {
    return this.getAllWithNormalizedTimestamps(
      ['dueDate', 'createdAt', 'updatedAt'],
      [where('dueDate', '>=', startDate), where('dueDate', '<=', endDate)],
    );
  }
}
