import { CalendarEvent, EventType } from '@bluebird-monorepo/types';
import dayjs from 'dayjs';
import { create } from 'zustand';

interface CalendarState {
  events: CalendarEvent[];
  currentWeek: dayjs.Dayjs;
  showNoAttendees: boolean;
  selectedEventTypes: EventType[];
  removedEventIds: string[];
  isLoading: boolean;

  // Actions
  setEvents: (events: CalendarEvent[]) => void;
  setCurrentWeek: (date: dayjs.Dayjs) => void;
  setShowNoAttendees: (show: boolean) => void;
  setSelectedEventTypes: (types: EventType[]) => void;
  addEvent: (event: CalendarEvent) => void;
  updateEvent: (id: string, data: Partial<CalendarEvent>) => void;
  removeEvent: (id: string) => void;
  restoreEvent: (id: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  initializeWeekFromUrl: () => void;
}

export const useCalendarStore = create<CalendarState>((set, get) => {
  // Helper to get work week date
  const getWorkWeekDate = (date: dayjs.Dayjs) => {
    const dayOfWeek = date.day();
    if (dayOfWeek === 0) {
      // Sunday
      return date.add(1, 'day');
    } else if (dayOfWeek === 6) {
      // Saturday
      return date.add(2, 'day');
    }
    return date;
  };

  // Helper to get week from URL
  const getWeekFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const weekParam = params.get('week');

    // If no week parameter, use current date aligned to work week
    if (!weekParam) {
      return getWorkWeekDate(dayjs());
    }

    // If week parameter exists, parse it and align to work week
    const baseDate = dayjs(weekParam);
    return getWorkWeekDate(baseDate);
  };

  // Helper to update URL
  const updateUrlWeek = (week: dayjs.Dayjs) => {
    const workWeekDate = getWorkWeekDate(week);
    const url = new URL(window.location.href);
    url.searchParams.set('week', workWeekDate.format('YYYY-MM-DD'));
    window.history.replaceState({}, '', url.toString());
  };

  return {
    events: [],
    currentWeek: getWeekFromUrl(),
    showNoAttendees: false,
    selectedEventTypes: [],
    removedEventIds: [],
    isLoading: false,

    setEvents: (events) => {
      console.log('CalendarStore - Setting Events:', {
        eventCount: events.length,
        events,
      });
      set({ events });
    },
    setCurrentWeek: (date) => {
      updateUrlWeek(date);
      set({ currentWeek: date });
    },
    setShowNoAttendees: (show) => set({ showNoAttendees: show }),
    setSelectedEventTypes: (types) => set({ selectedEventTypes: types }),
    addEvent: (event) => {
      console.log('CalendarStore - Adding Event:', event);
      set((state) => ({ events: [...state.events, event] }));
    },
    updateEvent: (id, data) => {
      console.log('CalendarStore - Updating Event:', {
        id,
        updatedEvent: data,
      });
      set((state) => ({
        events: state.events.map((event) => (event.id === id ? { ...event, ...data } : event)),
      }));
    },
    removeEvent: (id) =>
      set((state) => ({
        removedEventIds: [...state.removedEventIds, id],
      })),
    restoreEvent: (id) =>
      set((state) => ({
        removedEventIds: state.removedEventIds.filter((eventId) => eventId !== id),
      })),
    setIsLoading: (isLoading) => set({ isLoading }),
    initializeWeekFromUrl: () => {
      const weekFromUrl = getWeekFromUrl();
      set({ currentWeek: weekFromUrl });
    },
  };
});
