import styled from '@emotion/styled';

const StyledShared = styled.div`
  color: pink;
`;
export function Shared() {
  return (
    <StyledShared>
      <h1>Welcome to Shared!</h1>
    </StyledShared>
  );
}

export default Shared;
