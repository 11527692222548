import { TaskboardEntityType, TaskboardItem, TaskboardStatus } from '@bluebird-monorepo/types';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { useTasks } from '../../hooks/useTasks';
import { CreateTaskModal } from '../CreateTaskModal/CreateTaskModal';
import { TaskColumn } from './TaskColumn';
import { TaskFilters } from './TaskFilters';

interface TaskBoardProps {
  entityType?: TaskboardEntityType;
  entityId?: string;
}

export const TaskBoard: FC<TaskBoardProps> = ({ entityType, entityId }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    assigneeId: '',
    priority: '',
  });

  const { tasks, isLoading, createTask, updateTask } = useTasks({
    entityType,
    entityId,
  });

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const taskId = result.draggableId;
    const newStatus = result.destination.droppableId as TaskboardStatus;
    updateTask({ id: taskId, data: { status: newStatus } });
  };

  const handleCreateTask = async (taskData: Omit<TaskboardItem, 'id' | 'createdAt' | 'updatedAt'>) => {
    await createTask(taskData);
    setIsCreateModalOpen(false);
  };

  // Group tasks by status
  const tasksByStatus = {
    todo: tasks.filter((task) => task.status === 'todo'),
    'in-progress': tasks.filter((task) => task.status === 'in-progress'),
    done: tasks.filter((task) => task.status === 'done'),
  };

  // Apply filters
  const filterTasks = (tasksToFilter: TaskboardItem[]) => {
    return tasksToFilter.filter((task) => {
      if (filters.assigneeId && task.assignedTo !== filters.assigneeId) {
        return false;
      }
      if (filters.priority && task.priority !== filters.priority) {
        return false;
      }
      return true;
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography level="h4">Tasks</Typography>
        <Button startDecorator={<AddIcon />} onClick={() => setIsCreateModalOpen(true)}>
          Create Task
        </Button>
      </Box>

      <TaskFilters filters={filters} onFiltersChange={setFilters} sx={{ mb: 3 }} />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2} sx={{ minHeight: '70vh' }}>
          <Grid xs={4}>
            <TaskColumn title="To Do" tasks={filterTasks(tasksByStatus.todo)} status="todo" isLoading={isLoading} />
          </Grid>
          <Grid xs={4}>
            <TaskColumn
              title="In Progress"
              tasks={filterTasks(tasksByStatus['in-progress'])}
              status="in-progress"
              isLoading={isLoading}
            />
          </Grid>
          <Grid xs={4}>
            <TaskColumn title="Done" tasks={filterTasks(tasksByStatus.done)} status="done" isLoading={isLoading} />
          </Grid>
        </Grid>
      </DragDropContext>

      {isCreateModalOpen && (
        <CreateTaskModal
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onSubmit={handleCreateTask}
          entityType={entityType}
          entityId={entityId}
        />
      )}
    </Box>
  );
};
