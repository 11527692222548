import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { Job } from '@bluebird-monorepo/types';
import { Box, FormControl, FormHelperText, FormLabel, Stack } from '@mui/joy';
import { Controller, useFormContext } from 'react-hook-form';

export function RequirementsStep() {
  const {
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<Job>();

  return (
    <Stack spacing={3}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Job Requirements
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Define the requirements and responsibilities for this position</Box>
        </Box>
      </Box>

      <FormControl error={!!errors.mustHaves && !!dirtyFields.mustHaves}>
        <FormLabel>Must-Have Requirements</FormLabel>
        <Controller
          name="mustHaves"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.mustHaves && dirtyFields.mustHaves && <FormHelperText>{errors.mustHaves.message}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.techStack && !!dirtyFields.techStack}>
        <FormLabel>Tech Stack</FormLabel>
        <Controller
          name="techStack"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.techStack && dirtyFields.techStack && <FormHelperText>{errors.techStack.message}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.niceToHaves && !!dirtyFields.niceToHaves}>
        <FormLabel>Nice-to-Have Requirements</FormLabel>
        <Controller
          name="niceToHaves"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.niceToHaves && dirtyFields.niceToHaves && <FormHelperText>{errors.niceToHaves.message}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.keyResponsibilities && !!dirtyFields.keyResponsibilities}>
        <FormLabel>Key Responsibilities</FormLabel>
        <Controller
          name="keyResponsibilities"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.keyResponsibilities && dirtyFields.keyResponsibilities && (
          <FormHelperText>{errors.keyResponsibilities.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.hiringProcess && !!dirtyFields.hiringProcess}>
        <FormLabel>Hiring Process</FormLabel>
        <Controller
          name="hiringProcess"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.hiringProcess && dirtyFields.hiringProcess && (
          <FormHelperText>{errors.hiringProcess.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.howToIntro && !!dirtyFields.howToIntro}>
        <FormLabel>How to Introduce Candidates</FormLabel>
        <Controller
          name="howToIntro"
          control={control}
          render={({ field }) => (
            <RichTextEditor showMenuBar={false} content={field.value || ''} onChange={field.onChange} />
          )}
        />
        {errors.howToIntro && dirtyFields.howToIntro && <FormHelperText>{errors.howToIntro.message}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}
