import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { StageRepository } from '../repositories/stage.repository';

export const useStages = () => {
  console.log('🎯 useStages: Hook initialized');

  const { firestore } = useFirebase();
  const stageRepository = useMemo(() => {
    console.log('🏗️ useStages: Creating StageRepository');
    return new StageRepository(firestore);
  }, [firestore]);

  const query = useQuery({
    queryKey: ['stages'],
    queryFn: async () => {
      console.log('🔄 useStages: Fetching stages');
      const result = await stageRepository.getAll();
      console.log('✅ useStages: Stages fetched:', result);
      return result;
    },
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  });

  useEffect(() => {
    console.log('📊 useStages: Query state updated:', {
      isLoading: query.isLoading,
      isError: !!query.error,
      dataLength: query.data?.length,
    });
  }, [query.data, query.isLoading, query.error]);

  return useMemo(() => {
    console.log('🔄 useStages: Memoizing return value');
    const result = {
      stages:
        query.data?.filter(
          (stage) => stage.name !== 'Interview Stage NL' && stage.name !== 'Interview Stage with Client',
        ) || [],
      loading: query.isLoading,
      error: query.error,
    };
    console.log('✅ useStages: Return value:', result);
    return result;
  }, [query.data, query.isLoading, query.error]);
};
