import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/joy';
import { Pagination } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableLayout } from './TableLayout';

export interface ColumnDefinition<T = any> {
  field: string;
  headerName: string;
  width?: string;
  sortable?: boolean;
  renderCell?: (params: { row: T }) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
}

export interface BluebirdTableProps<T = any> {
  data: T[];
  columns: ColumnDefinition<T>[];
  onSort?: (field: string, direction: 'asc' | 'desc') => void;
  onFilter?: (filters: any) => void;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  defaultRowsPerPage?: number;
  filterComponent?: React.ReactNode;
  rowsPerPageOptions?: number[];
  tableProps?: any;
  searchValue?: string;
}

// Add this function before the BluebirdTable component
const compareFirestoreStrings = (a: string, b: string): number => {
  // Handle null/undefined
  if (a == null && b == null) return 0;
  if (a == null) return -1;
  if (b == null) return 1;

  // Convert strings to arrays of UTF-8 bytes for comparison
  const aBytes = Array.from(a).map((char) => char.charCodeAt(0));
  const bBytes = Array.from(b).map((char) => char.charCodeAt(0));

  // Compare byte by byte
  const minLength = Math.min(aBytes.length, bBytes.length);
  for (let i = 0; i < minLength; i++) {
    if (aBytes[i] !== bBytes[i]) {
      return aBytes[i] - bBytes[i];
    }
  }

  // If all bytes match up to the minimum length, shorter string comes first
  return aBytes.length - bBytes.length;
};

export function BluebirdTable<T extends Record<string, any>>({
  data,
  columns,
  onSort,
  onFilter,
  onPageChange,
  onRowsPerPageChange,
  defaultRowsPerPage = 10,
  filterComponent,
  rowsPerPageOptions = [10, 25, 50],
  tableProps,
  searchValue,
}: BluebirdTableProps<T>) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string | undefined>('priority');

  const handleSort = useCallback(
    (field: string) => {
      console.log('📊 BluebirdTable: Sorting by field:', field);
      console.log(data);
      const isAsc = orderBy === field && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(field);
      if (onSort) {
        onSort(field, isAsc ? 'asc' : 'desc');
      }
    },
    [order, orderBy, onSort],
  );

  const handlePageChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value - 1);
      onPageChange?.(value - 1);
    },
    [onPageChange],
  );

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
      onRowsPerPageChange?.(newRowsPerPage);
    },
    [onRowsPerPageChange],
  );

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    console.log('📊 BluebirdTable: Calculating paginated data:', {
      totalRecords: data.length,
      page,
      rowsPerPage,
      orderBy,
      order,
      records: data.slice(startIndex, startIndex + rowsPerPage).map((record) => ({
        ...record,
        displayName: record['firstName'] ? `${record['firstName']} ${record['lastName']}` : JSON.stringify(record),
      })),
    });

    // If no sorting is requested, return paginated data as is
    if (!orderBy) {
      return [...data].slice(startIndex, startIndex + rowsPerPage);
    }

    // Sort the data
    const sortedData = [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      // Handle null/undefined values based on sort direction
      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return order === 'asc' ? -1 : 1;
      if (bValue == null) return order === 'asc' ? 1 : -1;

      // Handle different types of values
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = compareFirestoreStrings(aValue, bValue);
        return order === 'asc' ? comparison : -comparison;
      }

      // Handle numbers and other types
      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });

    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [data, page, rowsPerPage, orderBy, order]);

  // Reset sorting when search value changes
  useEffect(() => {
    if (searchValue) {
      setOrderBy(undefined);
      setOrder('asc');
    }
  }, [searchValue]);

  const renderSortIcon = (field: string) => {
    if (orderBy !== field) return null;
    return order === 'asc' ? (
      <ArrowDropUpRounded color="primary" sx={{ height: 30, width: 30 }} />
    ) : (
      <ArrowDropDownRounded color="primary" sx={{ height: 30, width: 30 }} />
    );
  };

  const TableHeader = useMemo(() => {
    return (
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.field}
              onClick={() => column.sortable && handleSort(column.field)}
              style={{
                width: column.width,
                padding: '12px 6px',
                verticalAlign: 'middle',
                cursor: column.sortable ? 'pointer' : 'default',
              }}
            >
              {column.renderHeader ? (
                column.renderHeader()
              ) : column.sortable ? (
                <Link
                  color="primary"
                  component="button"
                  fontWeight="lg"
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  {column.headerName}
                  {renderSortIcon(column.field)}
                </Link>
              ) : (
                column.headerName
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  }, [columns, handleSort, order, orderBy]);

  const TableRows = useMemo(() => {
    return paginatedData.map((row, index) => (
      <tr key={index}>
        {columns.map((column) => (
          <td
            key={column.field}
            style={{
              padding: '12px 6px',
              verticalAlign: 'middle',
            }}
          >
            {column.renderCell ? column.renderCell({ row }) : (row[column.field]?.toString() ?? '')}
          </td>
        ))}
      </tr>
    ));
  }, [columns, paginatedData]);

  return (
    <>
      {filterComponent}
      <TableLayout
        header={TableHeader}
        rows={TableRows}
        tableProps={{
          ...tableProps,
          variant: 'outlined',
          sx: {
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '12px',
            '--TableCell-paddingX': '8px',
            backgroundColor: 'white',
            borderRadius: 'md',
            '& thead th:first-child': { borderTopLeftRadius: 'var(--joy-radius-md)' },
            '& thead th:last-child': { borderTopRightRadius: 'var(--joy-radius-md)' },
            '& tr:last-child td:first-child': { borderBottomLeftRadius: 'var(--joy-radius-md)' },
            '& tr:last-child td:last-child': { borderBottomRightRadius: 'var(--joy-radius-md)' },
            '& tbody tr': {
              transition: 'background-color 0.2s ease-in-out',
            },
            '& tbody tr:hover': {
              backgroundColor: 'var(--TableRow-hoverBackground)',
            },
            '& th': {
              backgroundColor: 'var(--TableCell-headBackground)',
              borderBottom: '1px solid var(--joy-palette-divider)',
              fontWeight: '600',
            },
            ...tableProps?.sx,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
          px: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography level="body-sm">
            Showing {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, data.length)} of {data.length}{' '}
            entries
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography level="body-sm">Rows per page:</Typography>
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                border: '1px solid var(--joy-palette-divider)',
              }}
            >
              {rowsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Box>
        </Box>
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={page + 1}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
          size="small"
        />
      </Box>
    </>
  );
}
