import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { User } from '@bluebird-monorepo/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Step, StepIndicator, Stepper, Typography } from '@mui/joy';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { TeamRepository } from '../../store/team.repository';
import { UserRepository } from '../../store/user.repository';
import { AboutStep } from './steps/AboutStep';
import { ReviewStep } from './steps/ReviewStep';
import { RoleStep } from './steps/RoleStep';
import { TeamStep } from './steps/TeamStep';

const userSchema = z.object({
  email: z.string().email('Invalid email address'),
  displayName: z.string().min(1, 'Display name is required'),
  role: z.enum(['admin', 'user', 'consultant']),
  title: z.string().optional(),
  phoneNumber: z.string().optional(),
  isActive: z.boolean().default(true),
  teamId: z.string().optional(),
  recruitCrmId: z.string().optional(),
});

type UserFormData = z.infer<typeof userSchema>;

const steps = [
  {
    label: 'About',
    description: 'Basic user information',
    component: AboutStep,
  },
  {
    label: 'Role',
    description: 'User role and permissions',
    component: RoleStep,
  },
  {
    label: 'Team',
    description: 'Team assignment',
    component: TeamStep,
  },
  {
    label: 'Review',
    description: 'Review and create user',
    component: ReviewStep,
  },
];

const stepValidationFields = {
  0: ['email', 'displayName'],
  1: ['role'],
  2: [], // Team is optional
  3: [], // Review step doesn't have its own fields
} as const;

interface CreateUserWizardProps {
  onComplete: () => void;
  onCancel: () => void;
  mode?: 'create' | 'edit';
  initialData?: User;
}

export function CreateUserWizard({ onComplete, onCancel, mode = 'create', initialData }: CreateUserWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { firestore, auth } = useFirebase();
  const queryClient = useQueryClient();
  const userRepository = new UserRepository(firestore);
  const teamRepository = new TeamRepository(firestore);

  const createUserMutation = useMutation({
    mutationFn: async (data: UserFormData) => {
      if (!auth.currentUser) {
        throw new Error('No authenticated user found');
      }
      
      const userData = {
        ...data,
        uid: auth.currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      
      const user = await userRepository.create(userData);
      if (data.teamId) {
        await teamRepository.addTeamMember(data.teamId, user.id);
      }
      return user;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<User> }) => {
      const oldUser = await userRepository.getById(id);
      await userRepository.update(id, data);

      // Handle team changes
      if (oldUser?.teamId !== data.teamId) {
        // Remove from old team if exists
        if (oldUser?.teamId) {
          await teamRepository.removeTeamMember(oldUser.teamId, id);
        }
        // Add to new team if exists
        if (data.teamId) {
          await teamRepository.addTeamMember(data.teamId, id);
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
    },
  });

  const methods = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      email: initialData?.email || '',
      displayName: initialData?.displayName || '',
      role: (initialData?.role as 'admin' | 'user' | 'consultant') || 'user',
      title: initialData?.title || '',
      phoneNumber: initialData?.phoneNumber || '',
      isActive: initialData?.isActive ?? true,
      teamId: initialData?.teamId || '',
      recruitCrmId: initialData?.recruitCrmId || '',
    },
  });

  const handleNext = async () => {
    const fieldsToValidate = stepValidationFields[activeStep as keyof typeof stepValidationFields];
    const isValid = await methods.trigger(fieldsToValidate);

    if (!isValid) {
      console.log('Form validation failed:', methods.formState.errors);
      return;
    }

    if (activeStep === steps.length - 1) {
      // Final step - submit the form
      const formData = methods.getValues();
      try {
        if (mode === 'edit' && initialData?.id) {
          await updateUserMutation.mutateAsync({
            id: initialData.id,
            data: formData,
          });
        } else {
          await createUserMutation.mutateAsync(formData);
        }
        onComplete();
      } catch (error) {
        console.error('Failed to save user:', error);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const CurrentStepComponent = steps[activeStep].component;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: 4,
          p: 3,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary',
            },
          }}
        >
          <Close />
        </IconButton>

        {/* Left side - Stepper */}
        <Box
          sx={{
            width: '280px',
            borderRight: '1px solid',
            borderColor: 'divider',
            pr: 3,
          }}
        >
          <Stepper
            component="nav"
            orientation="vertical"
            sx={{
              '--Stepper-spacing': '1rem',
              '--StepIndicator-size': '2rem',
              '--Step-gap': '1rem',
              '--Step-connectorThickness': '2px',
              '--Step-connectorRadius': '1px',
              minHeight: 400,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={index < activeStep}
                indicator={
                  <StepIndicator
                    variant={activeStep === index ? 'solid' : 'soft'}
                    color={activeStep === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
              >
                <Box>
                  <Typography level="title-sm">{step.label}</Typography>
                  <Typography level="body-xs">{step.description}</Typography>
                </Box>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* Right side - Content */}
        <Box sx={{ flex: 1, maxWidth: '800px' }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <CurrentStepComponent />

            {/* Navigation buttons */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 4,
                pt: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Button variant="outlined" color="neutral" onClick={onCancel} sx={{ minWidth: 100 }}>
                Cancel
              </Button>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 100 }}>
                  Back
                </Button>
              )}
              <Button onClick={handleNext} sx={{ minWidth: 100 }}>
                {activeStep === steps.length - 1 ? (mode === 'edit' ? 'Save Changes' : 'Create User') : 'Next'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
}
