import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Step, StepIndicator, Stepper } from '@mui/joy';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useCandidates } from '../../hooks/useCandidates';
import { BasicInfoStep } from './steps/BasicInfoStep';
import { PreferencesStep } from './steps/PreferencesStep';
import { ReviewStep } from './steps/ReviewStep';
import { SkillsStep } from './steps/SkillsStep';
import { WorkDetailsStep } from './steps/WorkDetailsStep';

const candidateSchema = z.object({
  // Basic Info
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  contactInfo: z.object({
    email: z.string().email('Invalid email address'),
    phone: z.string().optional(),
  }),
  city: z.string().optional(),
  currentCountryResidingIn: z.string().optional(),
  
  // Work Details
  currentOrganization: z.string().optional(),
  currentPosition: z.string().optional(),
  workExperienceYears: z.number().min(0).optional(),
  customSource: z.string().optional(),
  resumeUrl: z.string().optional(),
  
  // Skills & Languages
  skills: z.array(z.object({
    id: z.string(),
    label: z.string()
  })).optional(),
  potentialRoles: z.array(z.string()),
  languagesCandidateSpeak: z.array(z.string()),
  techStack: z.array(z.object({
    id: z.string(),
    label: z.string()
  })).optional(),
  
  // Preferences & Requirements
  isOpenToRelocate: z.boolean(),
  isOpenToWorkRemotely: z.boolean(),
  requiresVisa: z.boolean(),
  inOfficeExpectations: z.string().optional(),
  
  // Additional fields
  availability: z.string().optional(),
  avatar: z.string().default(''),
  notes: z.array(z.string()).optional(),
  linkedin: z.string().optional(),
  
  // Salary Information
  salaryInformation: z.object({
    preferNotToDisclose: z.boolean().optional(),
    reasonForNonDisclosure: z.string().optional(),
    currentSalary: z.object({
      currency: z.string(),
      monthly: z.number().optional(),
      yearly: z.number().optional(),
      bonus: z.number().optional(),
      totalOTE: z.number().optional(),
      hasEquity: z.boolean().optional(),
    }).optional(),
    salaryExpectationRange: z.object({
      currency: z.string(),
      min: z.number(),
      max: z.number(),
    }).optional(),
  }).optional(),
});

type CandidateFormData = z.infer<typeof candidateSchema>;

const steps = [
  {
    label: 'Basic Info',
    description: 'Personal and contact information',
    component: BasicInfoStep,
  },
  {
    label: 'Work Details',
    description: 'Current work and experience',
    component: WorkDetailsStep,
  },
  {
    label: 'Skills',
    description: 'Skills and qualifications',
    component: SkillsStep,
  },
  {
    label: 'Preferences',
    description: 'Work preferences and requirements',
    component: PreferencesStep,
  },
  {
    label: 'Review',
    description: 'Review and create candidate',
    component: ReviewStep,
  },
];

const stepValidationFields = {
  0: ['firstName', 'lastName', 'contactInfo.email'],
  1: ['currentPosition'],
  2: ['skills', 'potentialRoles'],
  3: [], // Preferences are optional
  4: [], // Review step doesn't have its own fields
} as const;

interface CreateCandidateWizardProps {
  onComplete: () => void;
  onCancel: () => void;
}

export function CreateCandidateWizard({ onComplete, onCancel }: CreateCandidateWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { createCandidateMutation } = useCandidates();

  const methods = useForm<CandidateFormData>({
    resolver: zodResolver(candidateSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      contactInfo: {
        email: '',
        phone: '',
      },
      skills: [],
      potentialRoles: [],
      languagesCandidateSpeak: [],
      isOpenToRelocate: false,
      isOpenToWorkRemotely: false,
      requiresVisa: false,
    },
  });

  const handleNext = async () => {
    const fieldsToValidate = stepValidationFields[activeStep as keyof typeof stepValidationFields];
    
    const isValid = await methods.trigger(fieldsToValidate);
    if (!isValid) {
      console.log('Form validation failed:', methods.formState.errors);
      return;
    }

    if (activeStep === steps.length - 1) {
      // Final step - submit the form
      const formData = methods.getValues();
      
      // Transform EntityOption arrays to string arrays
      const data = {
        ...formData,
        skills: formData.skills?.map((skill: any) => skill.label || skill) || [],
        techStack: formData.techStack?.map((tech: any) => tech.label || tech) || [],
      };

      console.log('Submitting form data:', data);
      
      try {
        const result = await createCandidateMutation.mutateAsync(data);
        console.log('Mutation result:', result);
        onComplete();
      } catch (error) {
        console.error('Failed to create candidate. Full error:', error);
        if (error instanceof Error) {
          console.error('Error message:', error.message);
          console.error('Error stack:', error.stack);
        }
        console.error('Mutation state:', {
          isError: createCandidateMutation.isError,
          error: createCandidateMutation.error,
          isPending: createCandidateMutation.isPending,
        });
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const CurrentStepComponent = steps[activeStep].component;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: 4,
          p: 3,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary',
            },
          }}
        >
          <Close />
        </IconButton>

        {/* Left side - Stepper */}
        <Box
          sx={{
            width: '280px',
            borderRight: '1px solid',
            borderColor: 'divider',
            pr: 3,
          }}
        >
          <Stepper
            component="nav"
            orientation="vertical"
            sx={{
              '--Stepper-spacing': '1rem',
              '--StepIndicator-size': '2rem',
              '--Step-gap': '1rem',
              '--Step-connectorThickness': '2px',
              '--Step-connectorRadius': '1px',
              minHeight: 400,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={index < activeStep}
                indicator={
                  <StepIndicator
                    variant={activeStep === index ? 'solid' : 'soft'}
                    color={activeStep === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
              >
                <Box>
                  <Box sx={{ fontWeight: 'bold' }}>{step.label}</Box>
                  <Box sx={{ color: 'text.secondary', fontSize: 'sm' }}>
                    {step.description}
                  </Box>
                </Box>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* Right side - Content */}
        <Box sx={{ flex: 1, maxWidth: '800px' }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <CurrentStepComponent />

            {/* Navigation buttons */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 4,
                pt: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Button 
                variant="outlined" 
                color="neutral" 
                onClick={onCancel}
                sx={{ minWidth: 100 }}
              >
                Cancel
              </Button>
              {activeStep > 0 && (
                <Button 
                  variant="outlined" 
                  onClick={handleBack}
                  sx={{ minWidth: 100 }}
                >
                  Back
                </Button>
              )}
              <Button
                onClick={handleNext}
                sx={{ minWidth: 100 }}
              >
                {activeStep === steps.length - 1 ? 'Create Candidate' : 'Next'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
}

export default CreateCandidateWizard;

