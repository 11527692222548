import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { Firestore, serverTimestamp, WhereFilterOp } from 'firebase/firestore';

export class CandidateJobAssignmentRepository extends BaseRepository<CandidateJobAssignment> {
  constructor(db: Firestore) {
    console.log('🏗️ CandidateJobAssignmentRepository: Initializing');
    super(db, 'candidateJobAssignments');
  }

  async upsertAssignment(id: string, assignment: Partial<CandidateJobAssignment>, owner: string): Promise<void> {
    console.log('🔄 upsertAssignment: Starting update', { id, assignment, owner });

    const cleanAssignment = {
      ...assignment,
      owner,
      updatedAt: serverTimestamp(),
    };
    console.log('✨ upsertAssignment: Cleaned assignment data:', cleanAssignment);

    try {
      await this.update(id, cleanAssignment as any);
      console.log('✅ upsertAssignment: Successfully updated assignment:', id);
    } catch (error) {
      console.error('❌ upsertAssignment: Failed to update assignment:', { id, error });
      throw error;
    }
  }

  async getAssignmentsByJob(jobId: string): Promise<CandidateJobAssignment[]> {
    console.log('🔄 getAssignmentsByJob: Fetching assignments for job:', jobId);

    try {
      const constraints: { field: string; op: WhereFilterOp; value: any }[] = [
        { field: 'jobId', op: '==', value: jobId },
      ];
      console.log('🔍 getAssignmentsByJob: Using constraints:', constraints);

      const results = await this.getAllForExtension(constraints);
      console.log('✅ getAssignmentsByJob: Found assignments:', {
        jobId,
        count: results.length,
        assignments: results,
      });
      return results;
    } catch (error) {
      console.error('❌ getAssignmentsByJob: Error fetching assignments:', { jobId, error });
      throw this.handleError(error, 'Error fetching assignments by job');
    }
  }

  async getAssignmentsByOwner(ownerId: string): Promise<CandidateJobAssignment[]> {
    console.log('🔄 getAssignmentsByOwner: Fetching assignments for owner:', ownerId);

    try {
      const constraints: { field: string; op: WhereFilterOp; value: any }[] = [
        { field: 'owner', op: '==', value: ownerId },
      ];
      console.log('🔍 getAssignmentsByOwner: Using constraints:', constraints);

      const results = await this.getAllForExtension(constraints);
      console.log('✅ getAssignmentsByOwner: Found assignments:', {
        ownerId,
        count: results.length,
        assignments: results,
      });
      return results;
    } catch (error) {
      console.error('❌ getAssignmentsByOwner: Error fetching assignments:', { ownerId, error });
      throw this.handleError(error, 'Error fetching assignments by owner');
    }
  }
}
