import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface CandidateJobAssignmentState {
  assignments: CandidateJobAssignment[];
  setAssignments: (assignments: CandidateJobAssignment[]) => void;
  addAssignment: (assignment: CandidateJobAssignment) => void;
  updateAssignment: (id: string, assignment: Partial<CandidateJobAssignment>) => void;
  removeAssignment: (id: string) => void;
}

export const useCandidateJobAssignmentStore = create<CandidateJobAssignmentState>((set, get) => ({
  assignments: [],

  setAssignments: (assignments) => {
    console.log('🔄 Store: Setting all assignments:', {
      count: assignments.length,
      assignments,
    });
    set({ assignments });
    console.log('✅ Store: Assignments set successfully');
  },

  addAssignment: (assignment) => {
    console.log('🔄 Store: Adding new assignment:', assignment);
    set((state) => {
      const newState = { assignments: [...state.assignments, assignment] };
      console.log('✅ Store: Assignment added. New state:', {
        totalCount: newState.assignments.length,
        addedAssignment: assignment,
      });
      return newState;
    });
  },

  updateAssignment: (id, updatedAssignment) => {
    console.log('🔄 Store: Updating assignment:', { id, updates: updatedAssignment });
    set((state) => {
      const assignments = state.assignments.map((assignment) =>
        assignment.id === id ? { ...assignment, ...updatedAssignment } : assignment,
      );
      console.log('✅ Store: Assignment updated:', {
        id,
        updates: updatedAssignment,
        updatedAssignment: assignments.find((a) => a.id === id),
      });
      return { assignments };
    });
  },

  removeAssignment: (id) => {
    console.log('🔄 Store: Removing assignment:', id);
    set((state) => {
      const assignments = state.assignments.filter((assignment) => assignment.id !== id);
      console.log('✅ Store: Assignment removed:', {
        id,
        remainingCount: assignments.length,
      });
      return { assignments };
    });
  },
}));

// Add a subscriber to log all state changes
if (process.env.NODE_ENV === 'development') {
  useCandidateJobAssignmentStore.subscribe((state) => {
    console.log('📊 Store State Updated:', {
      assignmentsCount: state.assignments.length,
      assignments: state.assignments,
      timestamp: new Date().toISOString(),
    });
  });
}
