import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useUser } from '@bluebird-monorepo/users';
import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Typography } from '@mui/joy';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';

const UserSettingsPage: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { auth } = useFirebase();
  const { user, updateUser, isUpdating } = useUser(auth?.currentUser?.uid);

  const [localStateUser, setLocalStateUser] = useState<any | null>(user);

  useEffect(() => {
    console.log('user', user);
    setLocalStateUser(user);
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalStateUser({ ...localStateUser, [name]: value });
  };

  const handleSave = async () => {
    console.log(user);
    if (!user?.id) return;

    setLoading(true);
    try {
      console.log('handleSave', localStateUser);
      const data = {
        displayName: localStateUser.displayName || '',
        rcrmUserId: localStateUser.rcrmUserId || '',
        calendlyUrl: localStateUser.calendlyUrl || '',
        firefliesApiKey: localStateUser.firefliesApiKey || '',
        answerNumber: localStateUser.answerNumber || '',
        email: localStateUser.email || '',
      };
      await updateUser({
        id: user.id,
        data,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError('Failed to update user settings.');
      console.error(error);
    }
  };

  if (loading || isUpdating) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="danger">{error}</Typography>;

  return (
    <Layout title="My Settings">
      <Box sx={{ maxWidth: 600, mt: 4 }}>
        {user && (
          <form>
            <FormControl>
              <FormLabel>Display Name</FormLabel>
              <Input
                name="displayName"
                onChange={handleInputChange}
                placeholder="Enter your display name..."
                value={localStateUser?.displayName || ''}
              />
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Recruit CRM ID</FormLabel>
              <Input
                name="rcrmUserId"
                onChange={handleInputChange}
                placeholder="Enter your Recruit CRM ID..."
                value={localStateUser?.rcrmUserId || ''}
              />
              <FormHelperText>Make sure this is correct, if not - there is no syncing into RecruitCRM.</FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Calendly URL</FormLabel>
              <Input
                name="calendlyUrl"
                onChange={handleInputChange}
                placeholder="Enter your Calendly URL..."
                value={localStateUser?.calendlyUrl || ''}
              />
              <FormHelperText>Your Calendly URL for scheduling meetings</FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Answer Number</FormLabel>
              <Input
                name="answerNumber"
                onChange={handleInputChange}
                placeholder="Enter your [a][number]... example a1"
                value={localStateUser?.answerNumber || ''}
              />
              <FormHelperText>
                In Calendly, go to Event Types → Click on the event type → Click on booking page options → Your
                questions are numbered by Q[number]. You should use [a][number], so Q1 becomes a1 and so forth.
              </FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Fireflies API Key</FormLabel>
              <Input
                name="firefliesApiKey"
                type="password"
                onChange={handleInputChange}
                placeholder="Enter your Fireflies API key..."
                value={localStateUser?.firefliesApiKey || ''}
              />
              <FormHelperText>API key for Fireflies.ai integration</FormHelperText>
            </FormControl>

            <Button color="primary" onClick={handleSave} loading={isUpdating} disabled={isUpdating}>
              Save Changes
            </Button>
          </form>
        )}
      </Box>
    </Layout>
  );
};

export default UserSettingsPage;
