import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { Snackbar } from '@mui/joy';
import React, { createContext, useCallback, useContext, useState } from 'react';

type Severity = 'success' | 'warning' | 'error' | 'info';

interface SnackbarContextType {
  showSnackbar: (message: string, severity?: Severity) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    console.log('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

const severityIcons = {
  success: <CheckCircle />,
  warning: <Warning />,
  error: <Error />,
  info: <Info />,
};

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Severity>('success');

  const showSnackbar = useCallback((message: string, severity: Severity = 'success') => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }, []);

  const hideSnackbar = useCallback(() => {
    setOpen(false);
  }, []);

  const value = {
    showSnackbar,
    hideSnackbar,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={open}
        onClose={hideSnackbar}
        autoHideDuration={2000}
        variant="soft"
        size="lg"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        startDecorator={severityIcons[severity]}
        sx={{
          maxWidth: 360,
          minWidth: 300,
          bgcolor: `${severity}.softBg`,
          '--Icon-fontSize': 'var(--joy-fontSize-xl2)',
        }}
      >
        {message}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
