import { formatTimestamp, isPastTimestamp, useCalendarEvents } from '@bluebird-monorepo/calendar';
import { CalendarEvent, Job } from '@bluebird-monorepo/types';
import { Add } from '@mui/icons-material';
import { Box, Button, Modal, ModalClose, ModalDialog, Sheet, Table, Typography } from '@mui/joy';
import { FC, useState } from 'react';

interface AssignEventToJobProps {
  job: Job;
  onEventAssigned: () => void;
}

export const AssignEventToJob: FC<AssignEventToJobProps> = ({ job, onEventAssigned }) => {
  const [open, setOpen] = useState(false);
  const { events, updateEvent } = useCalendarEvents();
  const [isAssigning, setIsAssigning] = useState(false);

  // Filter out events that are already assigned to this job
  const availableEvents = events?.filter((event) => !event.jobId) || [];

  const handleAssignEvent = async (event: CalendarEvent) => {
    setIsAssigning(true);
    try {
      await updateEvent({
        id: event.id,
        data: {
          jobId: job.id,
          typeOfEvent: 'Candidate interview', // Default type for job events
        },
      });
      onEventAssigned();
      setOpen(false);
    } catch (error) {
      console.error('Failed to assign event:', error);
    } finally {
      setIsAssigning(false);
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} startDecorator={<Add />} variant="outlined" color="primary" sx={{ mr: 1 }}>
        Assign Calendar Event
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          sx={{
            maxWidth: '800px',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <ModalClose />
          <Typography level="h4" sx={{ mb: 2 }}>
            Assign Calendar Event to {job.jobTitle}
          </Typography>

          <Sheet variant="outlined" sx={{ borderRadius: 'md' }}>
            {availableEvents.length > 0 ? (
              <Table
                stickyHeader
                hoverRow
                sx={{
                  '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                  '--Table-headerUnderlineThickness': '1px',
                  '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Participants</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {availableEvents.map((event) => (
                    <tr
                      key={event.id}
                      style={{
                        opacity: isPastTimestamp(event.when.startTime) ? 0.7 : 1,
                      }}
                    >
                      <td>
                        <Typography level="body-sm">
                          {formatTimestamp(event.when.startTime, 'MMM D, YYYY HH:mm')}
                        </Typography>
                      </td>
                      <td>
                        <Typography level="body-sm" fontWeight="md">
                          {event.title}
                        </Typography>
                      </td>
                      <td>
                        <Typography level="body-sm">
                          {event.attendees?.length || 0} attendee{event.attendees?.length !== 1 ? 's' : ''}
                        </Typography>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="soft"
                          color="primary"
                          onClick={() => handleAssignEvent(event)}
                          loading={isAssigning}
                        >
                          Assign
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography level="body-sm">No unassigned calendar events found.</Typography>
              </Box>
            )}
          </Sheet>
        </ModalDialog>
      </Modal>
    </>
  );
};
