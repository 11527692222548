import { Company } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface CompanyState {
  companies: Company[];
  currentPage: number;
  totalCompanies: number;
  pageSize: number;
  setCompanies: (companies: Company[]) => void;
  setCurrentPage: (page: number) => void;
  setTotalCompanies: (total: number) => void;
  addCompany: (company: Company) => void;
  updateCompany: (id: string, company: Partial<Company>) => void;
  removeCompany: (id: string) => void;
  currentCompany: Company | null;
  setCurrentCompany: (company: Company | null) => void;
}

export const useCompanyStore = create<CompanyState>((set) => ({
  companies: [],
  currentPage: 1,
  totalCompanies: 0,
  pageSize: 100,
  currentCompany: null,
  setCompanies: (companies) => set({ companies }),
  setCurrentPage: (page) => set({ currentPage: page }),
  setTotalCompanies: (total) => set({ totalCompanies: total }),
  setCurrentCompany: (company) => set({ currentCompany: company }),
  addCompany: (company) =>
    set((state) => ({ companies: [...state.companies, company] })),
  updateCompany: (id, updatedCompany) =>
    set((state) => ({
      companies: state.companies.map((company) =>
        company.id === id ? { ...company, ...updatedCompany } : company
      ),
    })),
  removeCompany: (id) =>
    set((state) => ({
      companies: state.companies.filter((company) => company.id !== id),
    })),
}));
