import { languageCountryMap } from '@bluebird-monorepo/utils';
import {
    Box,
    Chip,
    ChipDelete,
    FormControl,
    FormHelperText,
    FormLabel,
    ListItemDecorator,
    Option,
    Select,
    SelectOption
} from '@mui/joy';
import { Control, Controller } from 'react-hook-form';

interface LanguageSelectProps {
  control: Control<any>;
  name?: string;
  label?: string;
  error?: { message?: string };
  required?: boolean;
}

export function LanguageSelect({
  control,
  name = 'preferredLanguages',
  label = 'Preferred Languages',
  error,
  required = false
}: LanguageSelectProps) {
  // Get unique languages to prevent duplicates
  const uniqueLanguages = Array.from(
    new Map(languageCountryMap.map((item) => [item.languageCode, item])).values()
  );

  return (
    <FormControl error={!!error} required={required}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            multiple
            value={field.value || []}
            onChange={(_, newValue: string[]) => {
              field.onChange(newValue);
            }}
            renderValue={(selected: SelectOption<string>[]) => (
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                {selected.map((option) => {
                  const lang = uniqueLanguages.find((l) => l.languageCode === option.value);
                  return (
                    <Chip
                      key={option.value}
                      variant="soft"
                      endDecorator={<ChipDelete onDelete={(event) => {
                        event.stopPropagation();
                        const newValue = field.value.filter((val: string) => val !== option.value);
                        field.onChange(newValue);
                      }} />}
                    >
                      {lang?.languageName}
                    </Chip>
                  );
                })}
              </Box>
            )}
            slotProps={{
              listbox: {
                sx: { maxHeight: 300, overflow: 'auto' },
              },
            }}
          >
            {uniqueLanguages.map((lang) => (
              <Option key={lang.languageCode} value={lang.languageCode}>
                <ListItemDecorator>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/${lang.countryCode.toLowerCase()}.svg`}
                    alt=""
                  />
                </ListItemDecorator>
                {lang.languageName}
              </Option>
            ))}
          </Select>
        )}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
} 