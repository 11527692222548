import { User } from '@bluebird-monorepo/types';
import { memo } from 'react';
import { UserSelect } from '../UserSelect/UserSelect';

export interface EditableUserAvatarStackProps {
  /**
   * The current users (User objects, emails, or names)
   */
  users: (User | string | null)[];
  /**
   * Callback when users change
   */
  onChange: (newValue: string[]) => void;
  /**
   * Optional size override
   */
  size?: number;
}

function EditableUserAvatarStackComponent({ users, onChange, size }: EditableUserAvatarStackProps) {
  return <UserSelect value={users} onChange={onChange} multiple size={size} />;
}

export const EditableUserAvatarStack = memo(EditableUserAvatarStackComponent);
