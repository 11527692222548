import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore } from 'firebase/firestore';

export interface PotentialRoleDocument {
  id: string;
  name: string;
  category?: string;
}

export class PotentialRolesRepository extends BaseRepository<PotentialRoleDocument> {
  constructor(db: Firestore) {
    super(db, 'potentialRoles');
  }
}
