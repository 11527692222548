import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdDrawer } from '@bluebird-monorepo/bluebird-ui';
import { useUser } from '@bluebird-monorepo/users';
import { Box, Button, Stack, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { useJobNotes } from '../../hooks/useJobNotes';

interface AddNoteDrawerProps {
  open: boolean;
  onClose: () => void;
  jobId: string;
}

export const AddNoteDrawer: FC<AddNoteDrawerProps> = ({ open, onClose, jobId }) => {
  const { user } = useUser();
  const [content, setContent] = useState('');
  const { addNote, isAddingNote } = useJobNotes(jobId);

  const handleSave = () => {
    if (!user || !content.trim()) return;

    addNote({
      jobId,
      content,
      author: {
        id: user.uid,
        email: user.email,
        name: user.displayName || null,
      },
      createdAt: new Date().toISOString(),
    });

    onClose();
  };

  return (
    <BluebirdDrawer open={open} onClose={onClose} title="Add Note" size="md">
      <Box sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography level="body-sm" color="neutral">
            Add your note below. It will be associated with your account ({user?.email}).
          </Typography>

          <RichTextEditor showMenuBar={false} content={content} onChange={setContent} />

          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button variant="plain" color="neutral" onClick={onClose} disabled={isAddingNote}>
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={!content.trim() || isAddingNote} loading={isAddingNote}>
              Save Note
            </Button>
          </Stack>
        </Stack>
      </Box>
    </BluebirdDrawer>
  );
};
